/* eslint-disable max-len */

export const EMAIL_ERROR = 'EMAIL_ERROR'
export const EMAIL_EXISTS_ERROR = 'EMAIL_EXISTS_ERROR'
export const EMAIL_NOT_EXISTS_ERROR = 'EMAIL_NOT_EXISTS_ERROR'
export const MISSING_ERROR = 'MISSING_ERROR'
export const MINLENGTH_ERROR = 'MINLENGTH_ERROR'
export const MAX_ERROR = 'MAX_ERROR'
export const URL_ERROR = 'URL_ERROR'
export const PASSWORD_ERROR = 'PASSWORD_ERROR'
export const CONFIRM_PASSWORD_ERROR = 'CONFIRM_PASSWORD_ERROR'
export const PHONE_NUMBER_ERROR = 'PHONE_NUMBER_ERROR'
export const WEBSITE_ERROR = 'WEBSITE_ERROR'
export const ZIP_CODE_ERROR = 'ZIP_CODE_ERROR'

export const messages = {
  [EMAIL_ERROR]: 'Please enter a valid email address.',
  [EMAIL_EXISTS_ERROR]: 'This email address is already registered.',
  [EMAIL_NOT_EXISTS_ERROR]: 'This email does not exist.',
  [MISSING_ERROR]: 'This is a required field.',
  [MINLENGTH_ERROR]: 'This field must be at least {0} characters.',
  [MAX_ERROR]: 'The number show be less than or equal to {0}',
  [URL_ERROR]: 'Please use a valid URL.',
  [PASSWORD_ERROR]: 'This field must be at least {0} characters.',
  [CONFIRM_PASSWORD_ERROR]: 'Passwords do not match.',
  [PHONE_NUMBER_ERROR]: 'Please use a valid phone number.',
  [WEBSITE_ERROR]: 'Please use a valid website address.',
  [ZIP_CODE_ERROR]: 'Only letters, numbers, dashes, and spaces allowed.'
}
