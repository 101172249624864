import React, { useEffect } from 'react'
import PropTypes from "prop-types"
import css from "./Filter.module.sass"
import StageOfbusinessDropdown from "../Form/StageOfbusinessDropdown"
import TeamSizeDropdown from "../Form/TeamSizeDropdown"
import IndustryDropdown from "../Form/IndustryDropdown"
import Text from "../Text"
import Button from '../Button'

const Filter = ({ industries, setIndustries, stageOfBusiness, setStageOfBusiness, teamSize, setTeamSize,
    filterCallBack,
    onSaveFilter,
    onCancelFilter,
    onClearFilter, noCancel
}
) => {
    let industry = industries ? industries : []
    const onUpdateStageOfBusiness = (option) => {
        if (option) {
            setStageOfBusiness(option.stageOfBusiness)
            if (filterCallBack)
                filterCallBack()
        }
    }

    const handleUpdateTeamSize = (option) => {
        if (option) {
            setTeamSize(option.teamSize)
            if (filterCallBack)
                filterCallBack()
        }
    }

    const onUpdateIndustries = (option) => {
        if (option) {
            setIndustries(option.industries)
            if (filterCallBack)
                filterCallBack()
        }
    }

    useEffect(() => {
        localStorage.setItem('feedIndustries', JSON.stringify(industry))
    }, [industry])

    useEffect(() => {
        localStorage.setItem('feedStageOfBusiness', JSON.stringify(stageOfBusiness))
    }, [stageOfBusiness])

    useEffect(() => {
        localStorage.setItem('feedTeamSize', JSON.stringify(teamSize))
    }, [teamSize])

    return (
        <div className={css.containerModal}>
            <div className={css.dropDownFilter}>
                <div className={css.containerIcon}>
                    <Text variant='large' weight='500'>
                        &nbsp;Stage of Business
                    </Text>
                </div>
                <StageOfbusinessDropdown
                    value={stageOfBusiness}
                    name='stageOfBusiness'
                    onUpdate={onUpdateStageOfBusiness}
                />
            </div>
            <div className={css.dropDownFilter}>
                <div className={css.containerIcon}>
                    <Text variant='large' weight='500'>
                        &nbsp;Team Size
                    </Text>
                </div>
                <TeamSizeDropdown
                    value={teamSize}
                    name='teamSize'
                    onUpdate={handleUpdateTeamSize}
                />
            </div>
            <div className={css.dropDownFilter}>
                <div className={css.containerIcon}>
                    <Text variant='large' weight='500'>
                        &nbsp;Industry
                    </Text>
                </div>
                <IndustryDropdown
                    value={industry}
                    name='industries'
                    onUpdate={onUpdateIndustries}
                    multiple />
            </div>

            <div className={css.conainerButtons}>
                <Button variant='tooltipWhite' decoration='underline' onClick={() => onClearFilter()}>
                    Clear all
                </Button>
                <div className={css.cancelSave}>
                    {!noCancel &&
                        <Button variant='outlineNarrow' onClick={() => onCancelFilter()}>
                            Cancel
                        </Button>
                    }
                    <Button variant='primaryNarrow' onClick={() => onSaveFilter()}>
                        Save
                    </Button>
                </div>
            </div>
        </div>
    )
}

Filter.propTypes = {
    industries: PropTypes.array,
    setIndustries: PropTypes.func,
    stageOfBusiness: PropTypes.number,
    setStageOfBusiness: PropTypes.func,
    teamSize: PropTypes.number,
    setTeamSize: PropTypes.func,
    filterCallBack: PropTypes.func,
    onSaveFilter: PropTypes.func,
    onCancelFilter: PropTypes.func,
    onClearFilter: PropTypes.func,
    noCancel: PropTypes.bool
}

export default Filter
