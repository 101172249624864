import React from 'react'
//import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import CongratsModal from '../components/CongratsModal'
import Layout from '../components/Layout'
//import MobileRecordingStub from '../components/MobileRecordingStub'
import PitchForm from '../components/PitchForm'
import WelcomeModal from '../components/WelcomeModal'

//import DevicesContext from '../components/DevicesContext'

const CreateScreen = ({ location }) => {
  // const { mobileUa } = useContext(DevicesContext)

  // if (mobileUa) {
  //   return <MobileRecordingStub />
  // }

  return (
    <Layout>
      <PitchForm />

      {location.state?.signedUp && <WelcomeModal />}
      {location.state?.initiatedFirstStitch && <CongratsModal />}
    </Layout>
  )
}

CreateScreen.propTypes = {
  location: PropTypes.object
}

export default CreateScreen
