import css from "./KnowledgeCenterBlock.module.sass"
import React from "react"

import Link from "../Link"
import List from "../List"
import Well from "../Well"

import {
  VIDEO_PITCH_TEMPLATE_URL,
  RECORDING_TIPS_URL,
  TECHNICAL_REQUIREMENTS_URL,
  TUTORIAL_URL,
} from "../../constants/urls"
import { PRACTICE_PATH } from "../../constants/routes"
import { SHOW_EXAMPLES } from "../../constants/enterpriseConfig"

const KnowledgeCenterBlock = () => {
  return (
    <Well title="Knowledge Center" highlight className={css.knowledge_center}>
      <List gap="15">
        {SHOW_EXAMPLES && (
          <List.Item>
            <Link variant="inherit" external href={VIDEO_PITCH_TEMPLATE_URL}>
              Video pitch script template doc
            </Link>
          </List.Item>
        )}
        <List.Item>
          <Link variant="inherit" external href={TUTORIAL_URL}>
            Watch tutorial video
          </Link>
        </List.Item>
        <List.Item>
          <Link variant="inherit" external href={TECHNICAL_REQUIREMENTS_URL}>
            FAQs & Technical Requirements
          </Link>
        </List.Item>

        <List.Item>
          <Link variant="inherit" external href={RECORDING_TIPS_URL}>
            Tips for self-recording a pitch
          </Link>
        </List.Item>

        <List.Item>
          <Link variant="inherit" to={PRACTICE_PATH}>
            Practice pitching on video
          </Link>
        </List.Item>
      </List>
    </Well>
  )
}

export default KnowledgeCenterBlock
