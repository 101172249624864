import css from './FileField.module.sass'

import React, { useRef, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import uuid from 'uuid/v1'

import Modal from '../Modal'

const BYTES_IN_MEGABYTE = 1024 * 1024

const FileField = ({ accept, children, onChange, maxSize }) => {
  const [error, setError] = useState()
  const [localId, setLocalId] = useState(uuid())

  const inputRef = useRef()

  const chooseFile = useCallback(() => {
    inputRef.current.click()
  }, [])

  return (
    <div className={css.container}>
      {error &&
        <Modal variant='error' onClosed={() => { setError(null) }}>{error}</Modal>
      }

      {children({ chooseFile })}

      <input
        key={localId}
        className={css.input}
        type='file'
        accept={accept}
        ref={inputRef}
        onChange={({ target: { files: [file] } }) => {
          if (!file) {
            return
          }

          if (file.size > maxSize * BYTES_IN_MEGABYTE) {
            return setError(`File exceeds ${maxSize}MB limit`)
          }

          setLocalId(uuid())

          onChange(file)
        }}
      />
    </div>
  )
}

FileField.propTypes = {
  accept: PropTypes.string,
  children: PropTypes.func,
  onChange: PropTypes.func,
  maxSize: PropTypes.number
}

export default FileField
