import css from './Form.module.sass'

import React, { useRef, useImperativeHandle, useMemo, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'


import CompanyDetails from './CompanyDetails'
import VideoSection from './VideoSection'
import VideoPreview from './VideoPreview'
import DeckDetails from './DeckDetails'
import HighlightsField from './HighlightsField'
import PitchTemplates from './PitchTemplates'
import TeamManager from './TeamManager'

import Alert from '../Alert'
import { Form } from '../Form'
import Text from '../Text'
import Well from '../Well'

import { formatGraphQLError } from '../../helpers/errors'
import { getBounds } from '../../helpers/ui'
import { HEADER_CLASSNAME } from '../../constants/classnames'
import { GTM_FORM_PITCHTAPE } from '../../constants/gtm'
import {
  DETAILS_SECTION, TEMPLATES_SECTION, VIDEO_SECTION, VIDEO_PREVIEW_SECTION,
  DECK_SECTION, HIGHLIGHTS_SECTION, TEAM_SECTION
} from '../../constants/pitchform'

const SCROLL_OFFSET = 25

let PitchForm = ({
  error, formData, saving, company, location, formRef,
  onUpdate, onInvalid, onTeamReorder
}) => {
  const detailsRef = useRef()
  const templatesRef = useRef()
  const videoRef = useRef()
  const videoPreviewRef = useRef()
  const deckRef = useRef()
  const highlightsRef = useRef()
  const teamRef = useRef()

  const headerHeight = useMemo(() => {
    const header = document.querySelector(`.${HEADER_CLASSNAME}`)

    return header ? header.clientHeight : 0
  }, [])

  const scrollToSection = useCallback((section) => {
    const sectionMap = {
      [DETAILS_SECTION]: detailsRef,
      [TEMPLATES_SECTION]: templatesRef,
      [VIDEO_SECTION]: videoRef,
      [VIDEO_PREVIEW_SECTION]: videoPreviewRef,
      [DECK_SECTION]: deckRef,
      [HIGHLIGHTS_SECTION]: highlightsRef,
      [TEAM_SECTION]: teamRef
    }

    const sectionRef = sectionMap[section]
    const { top } = getBounds(sectionRef.current)

    window.scrollTo({ left: 0, top: top - headerHeight - SCROLL_OFFSET, behavior: 'smooth' })
  }, [headerHeight])

  useImperativeHandle(formRef, () => ({
    scrollToSection
  }))

  useEffect(() => {
    if (location.state?.initiatedStitch) {
      scrollToSection(VIDEO_PREVIEW_SECTION)
    }
  }, [scrollToSection, location])

  return (
    <Form gtm={GTM_FORM_PITCHTAPE}>
      <div className={saving ? css.savingActive : css.saving}><Text variant='small'>saving...</Text></div>

      {error && <Alert variant='error'>{formatGraphQLError(error)}</Alert>}

      <Well ref={detailsRef}>
        <CompanyDetails
          name={formData.name}
          description={formData.description}
          website={formData.website}
          contactEmail={formData.contactEmail}
          stageOfBusiness={formData.stageOfBusiness}
          teamSize={formData.teamSize}
          solution={formData.solution}
          onUpdate={onUpdate}
          onInvalid={onInvalid}
          logoFile={company.logoFile}
          iconFile={company.iconFile}
          industries={formData.industries}
        />
      </Well>

      <div className={css.row}>
        <Well ref={templatesRef}>
          <PitchTemplates />
        </Well>

        <Well noMargin ref={videoRef}>
          <VideoSection company={company} />
        </Well>
      </div>

      <Well padding='0' ref={videoPreviewRef}>
        <VideoPreview company={company} />
      </Well>

      <Well ref={deckRef}>
        <DeckDetails slidesFile={company.slidesFile} />
      </Well>

      <Well ref={teamRef}>
        <TeamManager members={company.members} onReorder={onTeamReorder} />
      </Well>

      <Well ref={highlightsRef}>
        <HighlightsField
          {...formData.highlights}
          onUpdate={onUpdate}
          onInvalid={onInvalid}
        />
      </Well>
    </Form>
  )
}

PitchForm.propTypes = {
  error: PropTypes.object,
  formData: PropTypes.object,
  company: PropTypes.object,
  saving: PropTypes.bool,
  location: PropTypes.object,
  formRef: PropTypes.object,
  onTeamReorder: PropTypes.func,
  onUpdate: PropTypes.func,
  onInvalid: PropTypes.func
}

export default withRouter(PitchForm)
