import css from './index.module.sass'

import React, { useState, useRef, useEffect, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'

import Alert from '../../Alert'
import Ionicon from '../../Ionicon'
import { FormContextConsumer } from '../../Form/FormContext'
import Link from '../../Link'
import Text from '../../Text'
import SectionTitle from '../../SectionTitle'
import TeamList from './TeamList'
import TeamMemberFormLinked from './TeamMemberFormLinked'

import { HEADER_CLASSNAME, CREATION_STEPS_CLASSNAME } from '../../../constants/classnames'
import { getBounds } from '../../../helpers/ui'
import { isMobile } from '../../../helpers/devices'
import ExamplePitchtapeLink from "../../ExamplePitchVideo"
import { SHOW_EXAMPLES } from "../../../constants/enterpriseConfig"

const EXTRA_SCROLL_OFFSET = 20

const TeamManager = ({ members, onReorder, setValidity, unsetValidity }) => {
  const [adding, setAdding] = useState()
  const [errorVisible, setErrorVisible] = useState()
  const containerRef = useRef()
  const teamListRef = useRef()

  const showError = () => {
    setErrorVisible(true)
  }

  useLayoutEffect(() => {
    setValidity('members', Boolean(members.length), containerRef, showError)
  }, [members, setValidity])

  useEffect(() => {
    return () => {
      unsetValidity('members')
    }
  }, [unsetValidity])

  return (
    <section ref={containerRef}>
      <SectionTitle
        title='5. Team Bios'
        description={
          <>
            We want to get to know you! Tell us about you and any team key members of the business.
            {SHOW_EXAMPLES &&
              <Text offset="half-left">
                <ExamplePitchtapeLink
                  linkText="See example"
                  section="team"
                >
                </ExamplePitchtapeLink>
              </Text>
            }
          </>
        }
      />

      {errorVisible &&
        <Alert variant='error' noClose>Please add at least one team member.</Alert>
      }

      {members.length > 0 &&
        <div className={css.teamList}>
          <TeamList ref={teamListRef} members={members} onReorder={onReorder} />
        </div>
      }

      {!adding &&
        <>
          <Link
            variant='iconOnLeftLarger'
            color='deepBlue'
            icon={<Ionicon name='add' size='24' color='blue' />}
            onClick={() => {
              setErrorVisible(false)
              setAdding(true)
            }}
          >
            Add team member
          </Link>
        </>
      }

      {adding &&
        <TeamMemberFormLinked
          onCreateSuccess={() => {
            setErrorVisible(false)
            setAdding(false)

            if (isMobile()) {
              const { top: teamListTop } = getBounds(teamListRef.current)
              const { height: headerHeight } = getBounds(HEADER_CLASSNAME)
              const { height: creationStepsHeight } = getBounds(CREATION_STEPS_CLASSNAME)

              window.scrollTo(0, teamListTop - headerHeight - creationStepsHeight - EXTRA_SCROLL_OFFSET)
            }
          }}
          onCancel={() => setAdding(false)}
        />
      }
    </section>
  )
}

TeamManager.propTypes = {
  members: PropTypes.array,
  onReorder: PropTypes.func,

  setValidity: PropTypes.func,
  unsetValidity: PropTypes.func
}

export default function TeamManagerContainer(props) {
  return (
    <FormContextConsumer>
      {({ setValidity, unsetValidity }) =>
        <TeamManager {...props} setValidity={setValidity} unsetValidity={unsetValidity} />
      }
    </FormContextConsumer>
  )
}
