import css from './VideoPreview.module.sass'

import React from 'react'
import PropTypes from 'prop-types'

import Button from '../Button'
import Ionicon from '../Ionicon'
import VideoNotReady from '../VideoNotReady'
import VideoPlayer from '../VideoPlayer'

import { PITCHTAPE_PATH } from '../../constants/routes'
import { VIDEO_FINISHED_STATUS } from '../../constants/videos'
import { areCompanyDetailsComplete } from '../../helpers/companies'
import { useSegments } from '../../helpers/hooks'

const VideoPreview = ({ company }) => {
  const segments = useSegments(company)

  const { concatenatedVideo } = company

  return (
    <div className={css.container}>
      {concatenatedVideo?.status === VIDEO_FINISHED_STATUS
        ? (
          <VideoPlayer
            header={
              areCompanyDetailsComplete(company) &&
                <div className={css.btnPreview}>
                  <Button
                    variant='feedAction'
                    icon={
                      <Ionicon name='today' color='white' size='24' />
                    }
                    to={PITCHTAPE_PATH}
                  >
                    Preview
                  </Button>
                </div>
            }
            segments={segments}
            binary={concatenatedVideo.binary}
            hlsReady={ false }
          />
        )
        : (
          <VideoNotReady company={company} />
        )
      }
    </div>
  )
}

VideoPreview.propTypes = {
  company: PropTypes.object
}

export default VideoPreview
