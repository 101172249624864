import React, { useState } from 'react'
import css from './index.module.sass'

import { useQuery } from "react-apollo"
import { useLocalStorage } from "react-use"

import { GET_FAVORITE_COMPANIES } from "../../constants/queries"
import Loader from "../Loader"
import CompanyItem from "./CompanyItem"
import Layout from "../Layout"
import Alert from "../Alert"
import { formatGraphQLError } from "../../helpers/errors"
import Search from '../../components/Search'
import Tooltip from "../../components/Tooltip"
import Ionicon from '../../components/Ionicon'

import InfiniteScroll from 'react-infinite-scroll-component'

const SavedCompanies = () => {
  // const [offset, setOffset] = useState(0)
  const [cursor, setCursor] = useState(null)
  const [data, setData] = useState([])
  const [searchTerm, setSearchTerm, removeSearchTerm] = useLocalStorage('feed-search-term', '')

  const deleteConnectionCallBack = (connectionId) => {
    setData(data.filter(nodeItem => nodeItem.node.company.id !== connectionId))
  }

  const { data: savedData, loading, error, networkStatus, refetch } = useQuery(GET_FAVORITE_COMPANIES, {
    onCompleted: (responseData) => {
      setData(data.concat(responseData?.getFavoriteCompanies?.edges))
    },
    fetchPolicy: "network-only",
    variables: {
      first: 20,
      after: cursor,
      q: searchTerm
    }
  })

  const fetchMoreListItems = () => {
    setCursor(savedData?.getFavoriteCompanies?.pageInfo?.endCursor)
  }

  const clearForQuery = () => {
    setData([])
    setCursor(null)
    refetch()
  }

  const handleSearchTermChange = (value) => {
    setSearchTerm(value)
    clearForQuery()
  }
  const handleSearchTermClear = () => {
    removeSearchTerm()
    clearForQuery()
  }

  return <>
    <InfiniteScroll
      dataLength={data.length}
      next={fetchMoreListItems}
      hasMore={savedData?.getFavoriteCompanies?.pageInfo?.hasNextPage}
    >
      {error &&
        <Layout variant='dashboard'>
          <Alert variant='error'>{formatGraphQLError(error)}</Alert>
        </Layout>
      }

      <div className={css.paginationContainer}>
        <div />
        <div>
          <Search
            term={searchTerm}
            onChange={handleSearchTermChange}
            onClear={handleSearchTermClear}
          />
          &nbsp;
          <Tooltip
            hoverable
            content="Note: The search tool does not search ratings, comments or notes."
          >
            <Ionicon
              name='information_circle_outline'
              size='24'
            />
          </Tooltip>
        </div>
      </div>

      <table className={css.dataTable}>
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Elevator Pitch</th>
            <th>Stage of Business</th>
            <th>Team Size</th>
            <th>Industry</th>
            <th>Pitch</th>
            <th>Rated</th>
            <th>Total Score</th>
            <th>Comments</th>
            <th>Notes</th>
            <th>Saved At</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((nodeItem, index) => {
              const companyData = nodeItem.node.company
              return (
                <CompanyItem
                  key={index}
                  index={index}
                  connectionId={companyData.id}
                  company={companyData}
                  dateSaved={nodeItem.node.dateFavorited}
                  deleteCallBack={deleteConnectionCallBack} />
              )
            })
          }
        </tbody>
      </table>
      {(loading || networkStatus === 4) &&
        <div className={css.loading}>
          <Loader variant='centered' />
        </div>
      }
    </InfiniteScroll>
  </>
}

SavedCompanies.propTypes = {
}

export default SavedCompanies
