export const PRODUCT_NAME = 'Pitch'
export const PORTAL_NAME = 'M&S Ignite Virtual Pitch Portal'
export const FEED_NAME = 'Pitch Videos'
export const PRODUCT_NAME_SHORT = 'Pitch'
export const URL = 'https://www.pitchtape.com'
export const LOGO = 'Ignite_Logo.png'
export const CONTACT_EMAIL = 'hello@pitchtape.com'
export const VIDEO_CREATOR = 'Creator'
export const VIDEO_CREATOR_PLURAL = 'Creators'
export const VIDEO_REVIEWER = 'Reviewer'
export const VIDEO_REVIEWER_PLURAL = 'Reviewers'
export const SAVED_MODULE_ENABLED = true
export const VIDEO_REVIEWER_TOUR_ENABLED = true
export const SIGNUP_REVIEWER_ENABLED = false
export const SHOW_EXAMPLES = false
export const LANDING_PAGE_ENABLED = false
export const VIDEO_REVIEWER_PROFILE_ENABLED = false
export const MAP_RATING_QUESTIONS = {
  rating1: '1. Solution fit for M&S:',
  rating2: '2. Karma score:',
  rating3: '3. Stage of business:',
  rating4: '4. Team size:'
}
export const REQUIRE_RECORD_ALL_TOPICS = true
