import css from './PasswordField.module.sass'

import React from 'react'
import PropTypes from 'prop-types'
import omit from 'lodash.omit'

import TextField from './TextField'
import Link from '../Link'
import Text from '../Text'
import Icon from '../Icon'

import { CONFIRM_PASSWORD_ERROR } from '../../constants/errors'
import { PASSWORD_MIN_LENGTH } from '../../constants/forms'

// making sure accepted props won't get cleared by some optimizer
const PROP_TYPES = {
  name: PropTypes.string,
  value: PropTypes.string,
  compareValue: PropTypes.string,
  error: PropTypes.oneOfType([ PropTypes.array, PropTypes.string ]),
  onValid: PropTypes.func,
  onInvalid: PropTypes.func
}

class PasswordField extends React.PureComponent {
  static propTypes = PROP_TYPES

  state = {
    error: null,
    isShowingCharacters: false
  }

  componentDidUpdate (prevProps) {
    if (prevProps.compareValue !== this.props.compareValue && this.state.error) {
      // no need to show error when related field is edited
      // hiding the error is useful though
      this.validate()
    }
  }

  validate () {
    const { value, compareValue, name, onValid, onInvalid } = this.props

    if (compareValue && value !== compareValue) {
      this.setState({ error: CONFIRM_PASSWORD_ERROR })
      return onInvalid && onInvalid(name)
    }

    this.setState({ error: null })
    onValid && onValid()
  }

  handleShowClick = () => {
    this.setState({
      isShowingCharacters: !this.state.isShowingCharacters
    })
  }

  handleValid = () => {
    this.validate()
  }

  render () {
    const textFieldProps = omit(this.props, Object.keys(PROP_TYPES))
    const { isShowingCharacters } = this.state

    return (
      <div className={css.container}>
        <TextField
          {...textFieldProps}
          type={isShowingCharacters ? 'text' : 'password'}
          password
          minlength={PASSWORD_MIN_LENGTH}
          appendToInputContainer={
            <div className={css.btnShow}>
              <Link onClick={this.handleShowClick}>
                <Text variant='icon' opacity={isShowingCharacters ? void 0 : '50'}>
                  <div className={css.btnShowInner}>
                    <Icon name={isShowingCharacters ? 'eyeBlue' : 'eye'} />
                  </div>
                </Text>
              </Link>
            </div>
          }
          name={this.props.name}
          value={this.props.value}
          error={this.props.error || this.state.error}
          onValid={this.handleValid}
          onInvalid={this.props.onInvalid}
        />
      </div>
    )
  }
}

export default PasswordField
