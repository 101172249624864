import React, { useContext } from 'react'

import MenuMobile from './MenuMobile'
import MenuDesktop from './MenuDesktop'

import DevicesContext from '../../DevicesContext'

const AuthNav = () => {
  const { mobile } = useContext(DevicesContext)

  return mobile ? <MenuMobile /> : <MenuDesktop />
}

export default AuthNav
