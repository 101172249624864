import css from './Radio.module.sass'

import React from 'react'
import PropTypes from 'prop-types'
import omit from 'lodash.omit'

// making sure accepted props won't get cleared by some optimizer
const PROP_TYPES = {
  variant: PropTypes.string,
  name: PropTypes.string,
  checked: PropTypes.bool,
  label: PropTypes.node,
  value: PropTypes.string,
  onUpdate: PropTypes.func
}

class Radio extends React.Component {
  static propTypes = PROP_TYPES

  handleChange = () => {
    this.props.onUpdate({
      [this.props.name]: this.props.value
    })
  }

  render () {
    const { variant = 'base', name, label, checked, value } = this.props
    const inputProps = omit(this.props, Object.keys(PROP_TYPES))

    const id = `${name}-${value}`

    return (
      <div className={css[variant]}>
        <input
          id={id}
          name={name}
          className={css.input}
          type='radio'
          checked={checked}
          value={value}
          onChange={this.handleChange}
          {...inputProps}
        />
        <label htmlFor={id} className={css.label}>
          <span className={checked ? css.boxChecked : css.box}>
            <i className={css.dot} />
          </span>
          {label}
        </label>
      </div>
    )
  }
}

export default Radio
