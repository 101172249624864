import css from "./FeedListScreen.module.sass"

import PropTypes from "prop-types"
import React, { useState, useContext, useEffect } from "react"
import { useQuery } from "react-apollo"
import { useLocalStorage } from 'react-use'

import { GET_FEED_LIST } from "../constants/queries"
import Layout from "../components/Layout"
import Loader from "../components/Loader"
import Alert from "../components/Alert"
import { formatGraphQLError } from "../helpers/errors"
import FeedItem from "../components/FeedList/FeedItem"
import Button from "../components/Button"
import Filter from "../components/Feed/Filter"
import Text from '../components/Text'
import Ionicon from '../components/Ionicon'
import Modal from '../components/Modal'
import Search from '../components/Search';
import Tooltip from "../components/Tooltip";
import InfiniteScroll from 'react-infinite-scroll-component'

import DevicesContext from '../components/DevicesContext'

const FeedListScreen = () => {
  const [openFilterModal, setOpenFilterModal] = useState(false)
  const [total, setTotal] = useState(0)
  const [companies, setCompanies] = useState([])
  const [stageOfBusiness, setStageOfBusiness] = useState(JSON.parse(localStorage.getItem('feedStageOfBusiness')) || null)
  const [stageOfBusinessList, setStageOfBusinessList] = useState(JSON.parse(localStorage.getItem('feedStageOfBusiness')) || null)
  const [teamSize, setTeamSize] = useState(JSON.parse(localStorage.getItem('feedTeamSize')) || null)
  const [teamSizeData, setTeamSizeData] = useState(JSON.parse(localStorage.getItem('feedTeamSize')) || null)
  const [searchTerm, setSearchTerm, removeSearchTerm] = useLocalStorage('feed-search-term', '')
  const [industriesList, setIndustriesList] = useState(JSON.parse(localStorage.getItem('feedIndustries')) || [])
  const [industries, setIndustries] = useState(JSON.parse(localStorage.getItem('feedIndustries')) || [])
  const [cursor, setCursor] = useState(null)

  const { mobile } = useContext(DevicesContext)
  const { data: savedData, loading, error, networkStatus, refetch } = useQuery(GET_FEED_LIST, {
    variables: {
      first: 20,
      after: cursor,
      industries: industriesList.map(i => i.id),
      stageOfBusiness: stageOfBusinessList ? stageOfBusinessList : null,
      teamSize: teamSizeData ? teamSizeData : null,
      q: searchTerm,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (data?.getFeed?.total) {
        setTotal(data.getFeed.total)
      }
      if (data?.getFeed?.edges) {
        setCompanies(companies.concat(data.getFeed.edges.map(({ node }) => node)))
      }
    }
  })

  const fetchMoreListItems = () => {
    setCursor(savedData?.getFeed?.pageInfo?.endCursor)
  }

  useEffect(() => {
    localStorage.setItem('feedStageOfBusiness', JSON.stringify(stageOfBusiness))
  }, [stageOfBusiness])

  useEffect(() => {
    localStorage.setItem('feedTeamSize', JSON.stringify(teamSize))
  }, [teamSize])

  useEffect(() => {
    localStorage.setItem('feedIndustries', JSON.stringify(industries))
  }, [industries])

  const handleSaveFilter = () => {
    setIndustriesList(industries)
    setStageOfBusinessList(stageOfBusiness)
    setTeamSizeData(teamSize)
    setOpenFilterModal(false)
    clearForQuery()
  }

  const handleCancelFilter = () => {
    setIndustries(industries.slice(0, industriesList.length))
    setStageOfBusiness(stageOfBusinessList)
    setTeamSize(teamSizeData)
    setOpenFilterModal(false)
  }

  const handleClearFilter = () => {
    setStageOfBusinessList(null)
    setStageOfBusiness(null)
    setTeamSizeData(null)
    setTeamSize(null)
    setIndustriesList([])
    setIndustries([])
    clearForQuery()
  }

  const clearForQuery = () => {
    setCompanies([])
    setTotal(0)
    setCursor(null)
    refetch()
  }

  const handleSearchTermChange = (value) => {
    setSearchTerm(value)
    clearForQuery()
  }
  const handleSearchTermClear = () => {
    removeSearchTerm()
    clearForQuery()
  }

  const renderFilter = () => {
    return (
      <InfiniteScroll
        dataLength={companies.length}
        next={fetchMoreListItems}
        hasMore={savedData?.getFeed?.pageInfo?.hasNextPage}
      >
        <div className={css.paginationContainer}>
          <div>
            <Text variant='large' offset='half-right'>
              Total submissions {total}
            </Text>
            {
              (stageOfBusiness || teamSize || industries.length) ?
                <Button
                  variant='filterAdded'
                  icon={
                    <Ionicon
                      name='filter_add_blue'
                      strokeWidth='32'
                      size={!mobile ? '20' : '12'}
                    />
                  }
                  onClick={() => { setOpenFilterModal(true) }}
                >
                  &nbsp;Filter Added
                </Button>
                :
                <Button
                  variant='pagination'
                  icon={
                    <Ionicon
                      name='filter_add'
                      strokeWidth='32'
                      size={!mobile ? '20' : '12'}
                    />
                  }
                  onClick={() => { setOpenFilterModal(true) }}
                >
                  &nbsp;Add Filter
                </Button>
            }
          </div>
          <div>
            <Button
              variant='pagination'
              icon={<Ionicon name='refresh' size='20' />}
              onClick={() => { handleClearFilter() }}
            >
              &nbsp;Refresh
            </Button>

            <Search
              term={searchTerm}
              onChange={handleSearchTermChange}
              onClear={handleSearchTermClear}
            />
            <Tooltip
              hoverable
              content="Note: The search tool does not search ratings, comments or notes."
            >
              <Ionicon
                name='information_circle_outline'
                size='24'
              />
            </Tooltip>
          </div>
        </div>
      </InfiniteScroll>
    )
  }

  return (<>
    {error && <Layout variant='dashboard'>
      <Alert variant='error'>{formatGraphQLError(error)}</Alert>
    </Layout>
    }
    {!error &&
      <Layout background='white' noFooter>
        <>
          {renderFilter()}

          <table className={css.dataTable}>
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Elevator Pitch</th>
                <th>Stage of Business</th>
                <th>Team Size</th>
                <th>Industry</th>
                <th>Pitch</th>
                <th>Rated</th>
                <th>Total Score</th>
                <th>Comments</th>
                <th>Notes</th>
              </tr>
            </thead>
            <tbody>
              {companies &&
                companies.map((company, index) => {
                  return <FeedItem key={index} index={index} company={company} />
                })
              }
            </tbody>
          </table>

          {(loading || networkStatus === 4) &&
            <div className={css.loading}>
              <Loader variant='centered' />
            </div>
          }
        </>
      </Layout>
    }

    <Modal
      variant="filter"
      modalOpened={openFilterModal}
      noButtons
      fullFooter
      title={<>

        <Text tag="h1" variant="h2" color="white">Filters</Text>
        <Button variant="icon" className={css.btnClose} onClick={() => handleCancelFilter()}>
          <Ionicon name="close" size="32" color="white" />
        </Button>
      </>
      }
      content={
        <Filter
          stageOfBusiness={stageOfBusiness}
          setStageOfBusiness={setStageOfBusiness}
          teamSize={teamSize}
          setTeamSize={setTeamSize}
          industries={industries}
          setIndustries={setIndustries}
          onSaveFilter={handleSaveFilter}
          onCancelFilter={handleCancelFilter}
          onClearFilter={handleClearFilter}
        />
      }
    />
  </>)
}

FeedListScreen.propTypes = {
  location: PropTypes.object,
}

export default FeedListScreen