import css from './FieldCounter.module.sass'

import React from 'react'
import PropTypes from 'prop-types'

import Text from '../Text'

const FieldCounter = ({ variant = 'base', type, maxlength, value }) => {
  const getWords = () => {
    const parts = value.trim().split(/\s+/)
    return (parts[0] === '' ? 0 : parts.length) + ' words'
  }

  const getLength = () => value.length

  return (
    <div className={css[variant]}>
      {typeof type === 'function'
        ? type({ getWords, getLength })
        : (
          <Text tag='p'>
            {type === 'words' ? getWords() : getLength()}{maxlength && `/${maxlength}`}
          </Text>
        )
      }
    </div>
  )
}

FieldCounter.propTypes = {
  variant: PropTypes.string,
  type: PropTypes.oneOfType([ PropTypes.func, PropTypes.bool, PropTypes.string ]),
  maxlength: PropTypes.number,
  value: PropTypes.string
}

export default FieldCounter
