import React from 'react'
import PropTypes from 'prop-types'

import StyleButton from './StyleButton'

export const INLINE_STYLES = [
  { icon: 'bold', style: 'BOLD', title: 'Bold' },
  { icon: 'italic', style: 'ITALIC', title: 'Italic' }
]

const InlineStyleControls = ({ editorState, onToggle }) => {
  const currentStyle = editorState.getCurrentInlineStyle()

  return (
    <>
      {INLINE_STYLES.map((item) =>
        <StyleButton
          key={item.icon}
          active={currentStyle.has(item.style)}
          {...item}
          onToggle={onToggle}
        />
      )}
    </>
  )
}

InlineStyleControls.propTypes = {
  editorState: PropTypes.object,
  onToggle: PropTypes.func
}

export default InlineStyleControls
