import css from './DeckDetails.module.sass'

import React from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { Mutation } from 'react-apollo'

import Alert from '../Alert'
import Button from '../Button'
import DocViewer from '../DocViewer'
import { FileField } from '../Form'
import Ionicon from '../Ionicon'
import SectionTitle from '../SectionTitle'

import { GET_USER_COMPANY_MAIN } from '../../constants/queries'
import { formatGraphQLError } from '../../helpers/errors'

const UPLOAD_COMPANY_SLIDES = gql`
  mutation($slidesFile: Upload) {
    uploadCompanySlides(input: { slidesFile: $slidesFile }) {
      obj {
        slidesFile
      }
      errors {
        field
        messages
      }
    }
  }
`

const ACCEPTED_FILE_TYPES = ['.pdf', 'application/pdf'].join(',')

const DeckDetails = ({ slidesFile }) => {
  return (
    <Mutation
      mutation={UPLOAD_COMPANY_SLIDES}
      update={(cache, { data: { uploadCompanySlides: { obj, errors } } }) => {
        if (errors) {
          return
        }

        const { company } = cache.readQuery({ query: GET_USER_COMPANY_MAIN })

        cache.writeQuery({
          query: GET_USER_COMPANY_MAIN,
          data: {
            company: {
              ...company,
              slidesFile: obj.slidesFile
            }
          }
        })
      }}
    >
      {(uploadCompanySlides, { data, loading, error }) =>
        <section>
          <SectionTitle
            title='4. Pitch Deck'
            description='Upload a slide deck in PDF format. File size is up to 10 MB.'
          />

          {data && data.errors &&
            <Alert variant='error'>{data.errors[0].messages.join('')}</Alert>
          }

          {error &&
            <Alert variant='error'>{formatGraphQLError(error)}</Alert>
          }

          {slidesFile
            ? (
              <>
                <Button
                  variant='primary'
                  disabled={loading}
                  icon={<Ionicon name='trash' size='24' color='white' />}
                  onClick={() => {
                    uploadCompanySlides({
                      variables: {
                        slidesFile: null
                      }
                    })
                  }}
                >
                  Delete file
                </Button>

                <div className={css.viewer}>
                  <DocViewer src={slidesFile} />
                </div>
              </>
            )
            : (
              <>
                <FileField
                  accept={ACCEPTED_FILE_TYPES}
                  maxSize={10}
                  onChange={(file) => {
                    uploadCompanySlides({
                      variables: {
                        slidesFile: file
                      }
                    })
                  }}
                >
                  {({ chooseFile }) =>
                    <Button
                      disabled={loading}
                      variant='primary'
                      icon={<Ionicon name='cloudUpload' color='white' size='32' />}
                      onClick={chooseFile}
                    >
                      Upload file
                    </Button>
                  }
                </FileField>
              </>
            )
          }
        </section>
      }
    </Mutation>
  )
}

DeckDetails.propTypes = {
  slidesFile: PropTypes.string
}

export default DeckDetails
