import css from './FormErrors.module.sass'

import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import Text from '../Text'

const FormErrors = ({ errors }) =>
  <dl className={css.container}>
    {errors.map((error, index) =>
      <Fragment key={index}>
        <dt className={css.dt}>{error.field}:</dt>
        <dd className={css.dd}>
          {error.messages.map((message, index) =>
            <Text key={index} tag='p' variant='small' color='red'>{message}</Text>
          )}
        </dd>
      </Fragment>
    )}
  </dl>

FormErrors.propTypes = {
  errors: PropTypes.array
}

export default FormErrors
