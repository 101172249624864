import css from './DeleteSavedCompany.module.sass'

import React from 'react'
import PropTypes from 'prop-types'
import Button from "../Button"
import Ionicon from "../Ionicon"
import { useMutation } from "react-apollo";
import gql from "graphql-tag"

const DELETE_FAVORITE_COMPANY = gql`
  mutation unfavoriteCompany ($company: String!) {
    unfavoriteCompany(company: $company) {
      id
    }
  }
`

const DeleteSavedCompany = ({ connectionId, deleteCallBack }) => {
  const [unfavoriteCompany, { loading, error }] = useMutation(DELETE_FAVORITE_COMPANY, {
    onCompleted: () => {
      if (deleteCallBack) {
        deleteCallBack(connectionId)
      }
    },
    onError: () => {
      console.log(error)
    }
  })

  const handleClick = () => {
    unfavoriteCompany({ variables: { company: connectionId } })
  }

  return (
    <Button disabled={loading}
      variant='iconEditor'
      icon={
        <Ionicon name='trash' size='24' color='red' />}
      className={css.deleteButton} onClick={handleClick} />
  )
}

DeleteSavedCompany.propTypes = {
  connectionId: PropTypes.string,
  deleteCallBack: PropTypes.func
}

export default DeleteSavedCompany