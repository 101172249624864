import css from './TextArea.module.sass'

import React, { createRef } from 'react'
import PropTypes from 'prop-types'
import omit from 'lodash.omit'
import classNames from 'classnames'

import FieldLabel from './FieldLabel'
import FieldError from './FieldError'
import FieldCounter from './FieldCounter'

import { FormContextConsumer } from './FormContext'

// making sure accepted props won't get cleared by some optimizer
const PROP_TYPES = {
  label: PropTypes.string,
  sublabel: PropTypes.string,
  info: PropTypes.node,
  variant: PropTypes.oneOf([ 'base', 'transparent', 'teleprompt' ]),
  required: PropTypes.bool,
  error: PropTypes.oneOfType([ PropTypes.array, PropTypes.string ]),
  isErrorVisible: PropTypes.bool,
  value: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  minlength: PropTypes.number,
  maxlength: PropTypes.number,
  rows: PropTypes.string,
  focused: PropTypes.bool,
  placeholder: PropTypes.string,
  counter: PropTypes.oneOfType([ PropTypes.func, PropTypes.bool, PropTypes.string ]),
  autoHeight: PropTypes.bool,
  block: PropTypes.bool,
  innerRef: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onUpdate: PropTypes.func,
  onChange: PropTypes.func,
  onValid: PropTypes.func,
  onInvalid: PropTypes.func,

  setValidity: PropTypes.func,
  unsetValidity: PropTypes.func
}

class TextArea extends React.PureComponent {
  static propTypes = PROP_TYPES

  state = {
    error: void 0,
    isErrorVisible: false
  }

  containerRef = createRef()

  componentDidMount () {
    this.props.setValidity(this.props.name, !this.props.error, this.containerRef, this.showError)
    this.validate()

    if (this.props.focused) {
      this.textarea.focus()
    }

    this.setOptimalHeight()
  }

  componentDidUpdate (prevProps) {
    if ((prevProps.error !== this.props.error)) {
      this.props.setValidity(this.props.name, !(this.props.error || this.state.error))
    }

    if (prevProps.value !== this.props.value) {
      this.validate()
    }

    this.setOptimalHeight()
  }

  componentWillUnmount () {
    this.props.unsetValidity && this.props.unsetValidity(this.props.name)
  }

  validate () {
    const { value, minlength, maxlength, name, onValid, onInvalid } = this.props
    let error

    if (this.props.required) {
      if (minlength && value.length < minlength) {
        error = `This is a required field and must be at least ${minlength} characters`
      } else if (!value) {
        error = 'This is a required field'
      }
    } else if (maxlength && value && value.length > maxlength) {
      error = `This field must be at most ${maxlength} characters`
    }

    this.setState({ error })

    this.props.setValidity(this.props.name, !(this.props.error || error))

    if (!error) {
      onValid && onValid(name)
    } else {
      onInvalid && onInvalid(name)
    }
  }

  showError = () => {
    this.setState({
      isErrorVisible: true
    })
  }

  setOptimalHeight () {
    if (this.props.autoHeight) {
      this.textarea.style.cssText = 'height:auto'

      if (this.props.value) {
        this.textarea.style.height = this.textarea.scrollHeight + 'px'
      }
    }
  }

  handleBlur = (...args) => {
    this.setState({
      isErrorVisible: true
    })

    this.props.onBlur && this.props.onBlur(...args)
  }

  handleFocus = (...args) => {
    this.setState({
      isErrorVisible: false
    })

    this.props.onFocus && this.props.onFocus(...args)
  }

  handleChange = (event) => {
    this.props.onUpdate && this.props.onUpdate({
      [this.props.name]: event.target.value
    })
    this.props.onChange && this.props.onChange(event)
  }

  render () {
    const {
      variant = 'base', label, sublabel, info, innerRef, placeholder,
      rows, name, counter, maxlength, autoHeight, block
    } = this.props
    const value = this.props.value || ''
    const textareaProps = omit(this.props, Object.keys(PROP_TYPES))
    const isErrorVisible = this.props.isErrorVisible || this.state.isErrorVisible
    const error = this.props.error || this.state.error
    const id = this.props.id || this.props.name

    return (
      <div className={css.container} ref={this.containerRef}>
        <FieldLabel label={label} sublabel={sublabel} name={id} info={info} />

        <textarea
          className={classNames(css[variant], {
            [css.error]: error && isErrorVisible,
            [css.autoHeight]: autoHeight,
            [css.block]: block
          })}
          rows={rows}
          onBlur={this.handleBlur}
          onFocus={this.handleFocus}
          onChange={this.handleChange}
          value={value}
          name={name}
          id={id}
          placeholder={placeholder}
          maxLength={maxlength}
          ref={el => { this.textarea = el; innerRef && innerRef(el) }}
          // disable grammarly browser plugin as it breaks textarea styles
          data-gramm_editor='false'
          {...textareaProps}
        />

        <FieldError error={error} isErrorVisible={isErrorVisible} />
        {counter && <FieldCounter type={counter} maxlength={maxlength} value={value} />}
      </div>
    )
  }
}

export default function TextAreaContainer (props) {
  return (
    <FormContextConsumer>
      {({ setValidity, unsetValidity }) =>
        <TextArea {...props} setValidity={setValidity} unsetValidity={unsetValidity} />
      }
    </FormContextConsumer>
  )
}
