import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import Alert from '../components/Alert'
import Button from '../components/Button'
import Layout from '../components/Layout'
import Modal from '../components/Modal'
import Text from '../components/Text'
import VideosRecorder from '../components/VideosRecorder'

import { useUploadCompanyVideoSegment } from '../helpers/hooks'
import { formatGraphQLError } from '../helpers/errors'
import { TOPICS } from '../constants/videos'
import { CREATE_PATH, CREATE_VIDEOS_PATH } from '../constants/routes'

const PracticeScreen = ({ history }) => {
  const [
    uploadCompanyVideoSegment, { error: uploadingError, loading: uploading }
  ] = useUploadCompanyVideoSegment({
    onCompleted: () => {
      history.push(CREATE_VIDEOS_PATH)
    }
  })

  const handleBeforeSave = useCallback(() => {
    history.push(CREATE_VIDEOS_PATH)
  }, [ history ])

  const handleSave = useCallback((payload) => {
    return uploadCompanyVideoSegment({
      variables: payload
    })
  }, [ uploadCompanyVideoSegment ])

  const topics = [
    {
      ...TOPICS[0],
      segments: [
        TOPICS[0].segments[0]
      ]
    }
  ]

  return (
    <Layout noHeader noFooter>
      {uploadingError &&
        <Alert variant='error'>{formatGraphQLError(uploadingError)}</Alert>
      }

      <VideosRecorder
        topics={topics}
        topic={topics[0].id}
        segment={topics[0].segments[0].id}
        practice
        saving={uploading}
        exitUrl={CREATE_PATH}
        renderPromptModal={({ nextLocation, saving, onSave }) =>
          <Modal
            sizeByFooter
            buttons={<>
              <Button variant='outline' to={nextLocation} disabled={saving}>
                No, exit practice
              </Button>

              <Button variant='primary' onClick={onSave} disabled={saving}>
                {saving ? 'Saving...' : 'Yes! Add to my pitch'}
              </Button>
            </>}
          >
            <Text tag='p' variant='h3'>
              Would you like to add the intro video to your pitch?
            </Text>
          </Modal>
        }
        onBeforeSave={handleBeforeSave}
        onSave={handleSave}
      />
    </Layout>
  )
}

PracticeScreen.propTypes = {
  history: PropTypes.object
}

export default PracticeScreen
