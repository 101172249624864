import React from 'react'
import PropTypes from 'prop-types'

import Link from '../Link'

import {
  FormSection, FormRow, FormCol, TextField, WebsiteField, LocationField,
  OrganizationSizeDropdown, InvestorTypeDropdown, NumberOfInvestmentsDropdown, YesNoDropdown
} from '../Form'

const InvestorInfo = ({
  title, profileUrl, name, organizationSize, locations, website,
  investorTypes, accreditedInvestor, numberOfInvestments, onUpdate, onInvalid
}) =>
  <FormSection
    heading='1. Investor Info'
    description='Tell us about you and your organization'
  >
    <FormRow>
      <FormCol>
        <TextField
          label='Title/Role'
          name='title'
          {...title}
          onUpdate={onUpdate}
          onInvalid={onInvalid}
        />
      </FormCol>

      <FormCol>
        <WebsiteField
          label='Profile URL (LinkedIn, AngelList, etc.)'
          name='profileUrl'
          {...profileUrl}
          onUpdate={onUpdate}
          onInvalid={onInvalid}
        />
      </FormCol>
    </FormRow>

    <FormRow offset='25'>
      <FormCol>
        <TextField
          label='Organization Name'
          name='name'
          {...name}
          onUpdate={onUpdate}
          onInvalid={onInvalid}
        />
      </FormCol>

      <FormCol>
        <OrganizationSizeDropdown
          label='Organization Size'
          name='organizationSize'
          {...organizationSize}
          onUpdate={onUpdate}
          onInvalid={onInvalid}
        />
      </FormCol>
    </FormRow>

    <FormRow offset='25'>
      <FormCol>
        <WebsiteField
          label='Website URL'
          name='website'
          {...website}
          onUpdate={onUpdate}
          onInvalid={onInvalid}
        />
      </FormCol>

      <FormCol>
        <LocationField
          label='Location *'
          name='locations'
          {...locations}
          onUpdate={onUpdate}
          onInvalid={onInvalid}
        />
      </FormCol>
    </FormRow>

    <FormRow offset='25'>
      <FormCol>
        <InvestorTypeDropdown
          label='Investor Type *'
          name='investorTypes'
          {...investorTypes}
          multiple
          onUpdate={onUpdate}
          onInvalid={onInvalid}
        />
      </FormCol>

      <FormCol>
        <YesNoDropdown
          label='Accredited Investor *'
          info={
            <>
              Visit
              our <Link variant='inherit' external href='https://www.pitchtape.com/faq'>FAQ page</Link> for
              more info about accredited investor status.
            </>
          }
          name='accreditedInvestor'
          {...accreditedInvestor}
          onUpdate={onUpdate}
          onInvalid={onInvalid}
        />
      </FormCol>
    </FormRow>

    <FormRow offset='25'>
      <FormCol size='half'>
        <NumberOfInvestmentsDropdown
          label='Number of Investments (Last 12 Months) *'
          name='numberOfInvestments'
          {...numberOfInvestments}
          onUpdate={onUpdate}
          onInvalid={onInvalid}
        />
      </FormCol>
    </FormRow>
  </FormSection>

InvestorInfo.propTypes = {
  title: PropTypes.object,
  profileUrl: PropTypes.object,
  name: PropTypes.object,
  organizationSize: PropTypes.object,
  locations: PropTypes.object,
  website: PropTypes.object,
  investorTypes: PropTypes.object,
  accreditedInvestor: PropTypes.object,
  numberOfInvestments: PropTypes.object,
  onUpdate: PropTypes.func,
  onInvalid: PropTypes.func
}

export default InvestorInfo
