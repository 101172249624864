import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { Form, FormRow, FormCol, PasswordField } from './Form'
import Button from './Button'
import Alert from './Alert'

import { formatGraphQLError } from '../helpers/errors'
import { GTM_FORM_RESET_PASSWORD } from '../constants/gtm'

class ResetPasswordForm extends PureComponent {
  static propTypes = {
    error: PropTypes.object,
    loading: PropTypes.bool,
    onReset: PropTypes.func
  }

  state = {
    formData: {
      newPassword: '',
      reNewPassword: ''
    },
    valid: false
  }

  handleFieldUpdate = (patch) => {
    this.setState({
      formData: {
        ...this.state.formData,
        ...patch
      }
    })
  }

  render () {
    const { error, loading } = this.props
    const { formData, valid } = this.state

    return (
      <Form
        gtm={GTM_FORM_RESET_PASSWORD}
        onSubmit={(event) => {
          event.preventDefault()

          if (valid && !loading) {
            this.props.onReset(this.state.formData)
          }
        }}
        onValid={() => this.setState({ valid: true })}
        onInvalid={() => this.setState({ valid: false })}
      >
        {error && <Alert variant='error'>{formatGraphQLError(error)}</Alert>}

        <FormRow>
          <FormCol>
            <PasswordField
              label='New Password'
              name='newPassword'
              value={formData.newPassword}
              compareValue={formData.reNewPassword}
              required
              onUpdate={this.handleFieldUpdate}
            />
          </FormCol>
        </FormRow>

        <FormRow offset='20'>
          <FormCol>
            <PasswordField
              label='Confirm New Password'
              name='reNewPassword'
              value={formData.reNewPassword}
              compareValue={formData.newPassword}
              required
              onUpdate={this.handleFieldUpdate}
            />
          </FormCol>
        </FormRow>

        <FormRow offset='35'>
          <FormCol>
            <Button variant='primary' type='submit' block disabled={loading}>
              Update
            </Button>
          </FormCol>
        </FormRow>
      </Form>
    )
  }
}

export default ResetPasswordForm
