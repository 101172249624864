import css from './CreateTeleprompterScriptScreen.module.sass'

import React from 'react'
import { useQuery } from 'react-apollo'

import Alert from '../components/Alert'
import Button from '../components/Button'
import Ionicon from '../components/Ionicon'
import Layout from '../components/Layout'
import Loader from '../components/Loader'
import TeleprompterScriptForm from '../components/TeleprompterScriptForm'
import Text from '../components/Text'
import Well from '../components/Well'

import { GET_USER_COMPANY } from '../constants/queries'
import { CREATE_PATH } from '../constants/routes'
import { formatGraphQLError } from '../helpers/errors'
import {PRODUCT_NAME} from "../constants/enterpriseConfig"

const CreateTeleprompterScriptScreen = () => {
  const { data, error, loading } = useQuery(GET_USER_COMPANY, {
    fetchPolicy: 'network-only'
  })

  const renderContent = () => {
    if (loading) {
      return <Loader variant='centered' />
    }

    if (error) {
      return <Alert variant='error'>{formatGraphQLError(error)}</Alert>
    }

    return (
      <div className={css.container}>
        <div className={css.btnBack}>
          <Button
            to={CREATE_PATH}
            icon={<Ionicon name='arrowDropleft' size='24' style={{ marginRight: -10 }} />}
          >
            <Text variant={null} weight='500'>Back to my {PRODUCT_NAME.toLowerCase()} profile</Text>
          </Button>
        </div>

        <Well padding='50'>
          <TeleprompterScriptForm company={data.company} />
        </Well>
      </div>
    )
  }

  return (
    <Layout>
      {renderContent()}
    </Layout>
  )
}

export default CreateTeleprompterScriptScreen
