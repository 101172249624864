import css from './index.module.sass'

import React, { useEffect, useLayoutEffect, useRef, useState, useCallback, useContext } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import Header from './Header'
import Pagination from './Pagination'
import Video from './Video'
import Deck from './Deck'
import Highlights from './Highlights'
import Team from './Team'
import CompanyDetails from './CompanyDetails'
import Footer from './Footer'
import VideoNotReady from '../VideoNotReady'
import Button from '../../components/Button'
import Ionicon from '../Ionicon'
import Text from '../Text'
import Modal from '../../components/Modal'
import OtherInfo from './OtherInfo'


import DevicesContext from '../DevicesContext'
import Filter from '../../components/Feed/Filter'

import { HEADER_CLASSNAME } from '../../constants/classnames'
import { VIDEO_FINISHED_STATUS } from '../../constants/videos'
import { getBounds } from '../../helpers/ui'
import SectionHeading from './SectionHeading'


const PAGE_POSSIBLE_EXTRA_WIDTH = 150

const PitchtapeReview = ({
  company, editable, shareable, submitButton, forInvestor, hasPrevious, hasNext,
  onInvestorAction, onPrevious, onNext, onRate, navigationButtons, location, totalVideos, offset,
  stageOfBusiness, setStageOfBusiness, teamSize, setTeamSize,
  onSaveFilter, onCancelFilter, onClearFilter, history, industries, setIndustries,
  searchCmp
}) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [transparentHeader, setTransparentHeader] = useState()
  const [sectionHeight, setSectionHeight] = useState()
  const [videoHeight, setVideoHeight] = useState()
  const [pageMaxWidth, setPageMaxWidth] = useState()
  const [openFilterModal, setOpenFilterModal] = useState(false)

  const { mobile, mobileUa } = useContext(DevicesContext)

  const { concatenatedVideo } = company
  const hasFinishedVideo = concatenatedVideo && concatenatedVideo.status === VIDEO_FINISHED_STATUS

  const videoRef = useRef()
  const deckRef = useRef()
  const highlightsRef = useRef()
  const teamRef = useRef()
  const otherInfoRef = useRef()
  const headerRef = useRef()
  const headerHeightRef = useRef()
  const footerRef = useRef()
  const footerHeightRef = useRef()
  const mainNavHeightRef = useRef()
  const sectionsRef = useRef([
    videoRef,
    deckRef,
    highlightsRef,
    teamRef,
    otherInfoRef
  ])

  useLayoutEffect(() => {
    if (mobile) {
      // setVideoHeight()
      setSectionHeight()
      setTransparentHeader()
      return
    }

    const handleResize = () => {
      const mainNavHeight = document.querySelector(`.${HEADER_CLASSNAME}`).clientHeight
      mainNavHeightRef.current = mainNavHeight

      if (!mobileUa) {
        const footerHeight = footerRef.current.clientHeight
        const headerHeight = headerRef.current.clientHeight

        footerHeightRef.current = footerHeight
        headerHeightRef.current = headerHeight

        const pageInnerHeight = window.innerHeight - mainNavHeight - footerHeight
        setSectionHeight(pageInnerHeight - headerHeightRef.current)

        setPageMaxWidth(Math.floor(pageInnerHeight / 9 * 16) + PAGE_POSSIBLE_EXTRA_WIDTH)
        setVideoHeight(pageInnerHeight)
      } else {
        setSectionHeight(320)
      }

      handleScroll()
    }

    const handleScroll = () => {
      const sections = sectionsRef.current
      const mainNavHeight = mainNavHeightRef.current
      const headerHeight = headerHeightRef.current
      const footerHeight = footerHeightRef.current

      const pageInnerHeight = window.innerHeight - mainNavHeight - footerHeight
      const sectionThreshold = Math.floor((pageInnerHeight - headerHeight) / 2)

      const windowScrollTop = window.scrollY + mainNavHeight + headerHeight

      const visibleSections = sections.map(s => s.current).filter(s => Boolean(s))

      const activeSection = visibleSections.find((section, index) => {
        if (index === visibleSections.length - 1) {
          return true
        }

        const top = getBounds(section).top - sectionThreshold
        const nextTop = getBounds(visibleSections[index + 1]).top - sectionThreshold

        return (index === 0 && windowScrollTop < top) ||
          (windowScrollTop >= top && windowScrollTop < nextTop)


      })

      const nextActiveIndex = sections.findIndex(s => s.current === activeSection)

      if (activeIndex !== nextActiveIndex) {
        setActiveIndex(nextActiveIndex)
      }

      const headerShouldBeTransparent = windowScrollTop < pageInnerHeight + mainNavHeight

      if (!transparentHeader && headerShouldBeTransparent) {
        setTransparentHeader(true)
      } else if (transparentHeader && !headerShouldBeTransparent) {
        setTransparentHeader(false)
      }
    }

    if (transparentHeader === void 0) {
      handleResize()
    }

    window.addEventListener('resize', handleResize)
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('resize', handleResize)
      window.removeEventListener('scroll', handleScroll)
    }
  }, [activeIndex, transparentHeader, mobile, mobileUa])

  const handleSectionSelect = useCallback((index) => {
    const sections = sectionsRef.current
    const section = sections[index].current

    const { top } = getBounds(section)

    window.scrollTo(0, top - mainNavHeightRef.current - headerHeightRef.current)
  }, [])

  useEffect(() => {
    const idToIndex = {
      video: 0,
      deck: 1,
      highlights: 2,
      team: 3,
      otherInfo: 4
    }

    if (location.hash) {
      const index = idToIndex[location.hash.slice(1)]

      if (index != null) {
        setTimeout(function () {
          handleSectionSelect(index)
        }, 0)
      }
    }
  }, [handleSectionSelect, location])

  const renderHeader = (variant = 'base', ref) => {
    return (
      <Header
        variant={variant}
        company={company}
        shareable={shareable}
        editable={editable}
        submitButton={submitButton}
        sticky
        forInvestor={forInvestor}
        pageMaxWidth={pageMaxWidth}
        hasPrevious={hasPrevious}
        hasNext={hasNext}
        onInvestorAction={onInvestorAction}
        onRate={onRate}
        onPrevious={onPrevious}
        onNext={onNext}
        ref={ref}
        navigationButtons={navigationButtons}
        history={history}
      />
    )
  }

  const renderPagination = () => {
    return (
      <div className={css.paginationContainer}>
        {navigationButtons &&
          <div className={css.viewingContainer} >
            <div className={css.viewing}>
              <Text variant='large' offset='half-right'>
                Viewing {offset} of {totalVideos}
              </Text>
            </div>
            {
              (stageOfBusiness || teamSize || industries.length)
                ?
                <Button
                  variant='filterAdded'
                  icon={
                    <Ionicon
                      name='filter_add_blue'
                      strokeWidth='32'
                      size={!mobile ? '20' : '14'} />}
                  onClick={() => { setOpenFilterModal(true) }}
                >
                  &nbsp;Filter Added
                </Button>
                :
                <Button
                  variant='pagination'
                  icon={
                    <Ionicon
                      name='filter_add'
                      strokeWidth='32'
                      size={!mobile ? '20' : '14'} />
                  }
                  onClick={() => { setOpenFilterModal(true) }}
                >
                  <span>
                    &nbsp;Add Filter
                  </span>
                </Button>
            }
          </div>
        }

        {searchCmp}
        <div className={css.spacer} />

        <Pagination
          forInvestor={forInvestor}
          hasPrevious={hasPrevious}
          hasNext={hasNext}
          onPrevious={onPrevious}
          onNext={onNext}
          navigationButtons={navigationButtons}
          isMobile={mobile}
        />
      </div>
    )
  }

  const handleCancelFilter = () => {
    onCancelFilter()
    setOpenFilterModal(false)
  }

  const isHighlights = company.highlights === '<p><br></p>' ? false : true
  return (
    <>
      <article className={css.container} style={{ maxWidth: pageMaxWidth }}>
        {renderPagination()}
        {(mobile || !transparentHeader) && renderHeader()}

        <div ref={videoRef}>
          {hasFinishedVideo
            ?
            <Video
              header={!mobile && renderHeader('transparent', headerRef)}
              company={company}
              height={videoHeight}
            />
            : (
              <div className={css.noVideo} style={{ height: videoHeight }}>
                {!mobile &&
                  <div className={css.noVideoHeader}>
                    {renderHeader('transparent', headerRef)}
                  </div>
                }

                <VideoNotReady company={company} />
              </div>
            )
          }
        </div>

        {(mobile || mobileUa) &&
          <div className={css.mobileCompanyDetails}>
            <CompanyDetails
              company={company}
              iconColor='black'
            />
          </div>
        }

        {company.slidesFile && (mobile || sectionHeight) &&
          <div className={css.containerDeck}>
            <section className={css.contentDeck}>
              <SectionHeading icon='deck_black'>
                DECK
              </SectionHeading>
              <div ref={deckRef}>
                <Deck company={company} height={sectionHeight} />
              </div>
            </section>
          </div>
        }



        {isHighlights &&
          <div ref={highlightsRef}>
            <Highlights company={company} />
          </div>
        }

        {Boolean(company.members && company.members.length) &&
          <div ref={teamRef}>
            <Team company={company} />
          </div>
        }

        {company.website &&
          <div ref={otherInfoRef}>
            <OtherInfo company={company} />
          </div>
        }

        {!mobile && !mobileUa &&
          <Footer
            noDeck={!company.slidesFile}
            noHighlights={!isHighlights}
            noTeam={!company.members || !company.members.length}
            noOtherInfo={!company.website}
            activeIndex={activeIndex}
            onSectionSelect={handleSectionSelect}
            ref={footerRef}
          />
        }
      </article>

      <Modal
        variant="filter"
        modalOpened={openFilterModal}
        noButtons
        fullFooter
        title={<>

          <Text tag="h1" variant="h2" color="white">Filters</Text>
          <Button variant="icon" className={css.btnClose} onClick={() => handleCancelFilter()}>
            <Ionicon name="close" size="32" color="white" />
          </Button>
        </>
        }
        content={
          <Filter
            stageOfBusiness={stageOfBusiness}
            setStageOfBusiness={setStageOfBusiness}
            teamSize={teamSize}
            setTeamSize={setTeamSize}
            industries={industries}
            setIndustries={setIndustries}
            customerCategory={null}
            setCustomerCategory={null}
            onSaveFilter={onSaveFilter}
            onCancelFilter={handleCancelFilter}
            onClearFilter={onClearFilter}
          />
        }
      />
    </>
  )
}

PitchtapeReview.propTypes = {
  company: PropTypes.object,
  editable: PropTypes.bool,
  shareable: PropTypes.bool,
  submitButton: PropTypes.node,
  forInvestor: PropTypes.bool,
  hasPrevious: PropTypes.bool,
  hasNext: PropTypes.bool,
  onInvestorAction: PropTypes.func,
  onRate: PropTypes.func,
  onPrevious: PropTypes.func,
  onNext: PropTypes.func,
  navigationButtons: PropTypes.bool,
  location: PropTypes.object,
  totalVideos: PropTypes.number,
  offset: PropTypes.number,
  industries: PropTypes.array,
  setIndustries: PropTypes.func,
  stageOfBusiness: PropTypes.number,
  setStageOfBusiness: PropTypes.func,
  teamSize: PropTypes.number,
  setTeamSize: PropTypes.func,
  pagination: PropTypes.bool,
  onSaveFilter: PropTypes.func,
  onCancelFilter: PropTypes.func,
  onClearFilter: PropTypes.func,
  history: PropTypes.object,
  searchCmp: PropTypes.object,
}

export default withRouter(PitchtapeReview)
