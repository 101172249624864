import React from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { Query, Mutation } from 'react-apollo'

import { addProtocol } from '../../../helpers/strings'
import { GET_USER_COMPANY_MEMBERS } from '../../../constants/queries'
import { COMPANY_MEMBER_FRAGMENT, ERRORS_FOR_FIELDS_FRAGMENT } from '../../../constants/fragments'

import TeamMemberForm from './TeamMemberForm'

const CREATE_COMPANY_MEMBER = gql`
  mutation createCompanyMember ($input: CompanyMemberCreateMutationInput!) {
    createCompanyMember(input: $input) {
      obj {
        ...companyMember
      }
      errors {
        ...errorsForFields
      }
    }
  }
  ${COMPANY_MEMBER_FRAGMENT}
  ${ERRORS_FOR_FIELDS_FRAGMENT}
`

const UPDATE_COMPANY_MEMBER = gql`
  mutation updateCompanyMember ($input: CompanyMemberUpdateMutationInput!) {
    updateCompanyMember(input: $input) {
      obj {
        ...companyMember
      }
      errors {
        ...errorsForFields
      }
    }
  }
  ${COMPANY_MEMBER_FRAGMENT}
  ${ERRORS_FOR_FIELDS_FRAGMENT}
`

const handleUpdate = (cache, errors, updateCompany) => {
  if (!errors) {
    const { company } = cache.readQuery({ query: GET_USER_COMPANY_MEMBERS })

    cache.writeQuery({
      query: GET_USER_COMPANY_MEMBERS,
      data: {
        company: updateCompany(company)
      }
    })
  }
}

const TeamMemberFormLinked = ({ onCreateSuccess, onUpdateSuccess, ...teamMemberFormProps }) =>
  <Mutation
    mutation={CREATE_COMPANY_MEMBER}
    update={(cache, { data: { createCompanyMember: { obj, errors } } }) => {
      handleUpdate(cache, errors, company => ({
        ...company,
        members: [ ...company.members, obj ]
      }))
    }}
    onCompleted={(data) => {
      if (onCreateSuccess && !data.errors) {
        onCreateSuccess()
      }
    }}
  >
    {(createCompanyMember, { loading: creating, error: creatingError }) =>
      <Mutation
        mutation={UPDATE_COMPANY_MEMBER}
        update={(cache, { data: { updateCompanyMember: { obj, errors } } }) => {
          handleUpdate(cache, errors, company => ({
            ...company,
            members: company.members.map(m => m.id === obj.id ? obj : m)
          }))
        }}
        onCompleted={(data) => {
          if (onUpdateSuccess && !data.errors) {
            onUpdateSuccess()
          }
        }}
      >
        {(updateCompanyMember, { loading: updating, error: updatingError }) =>
          <Query query={GET_USER_COMPANY_MEMBERS}>
            {({ data }) => {
              const getPayload = (member) => ({
                variables: {
                  input: {
                    ...member,
                    websiteUrl: addProtocol(member.websiteUrl),
                    company: data.company.id
                  }
                }
              })

              return (
                <TeamMemberForm
                  {...teamMemberFormProps}
                  loading={creating || updating}
                  error={creatingError || updatingError}
                  onCreate={(member) => {
                    createCompanyMember(getPayload(member))
                  }}
                  onUpdate={(member) => {
                    updateCompanyMember(getPayload(member))
                  }}
                />
              )
            }}
          </Query>
        }
      </Mutation>
    }
  </Mutation>

TeamMemberFormLinked.propTypes = {
  onCreateSuccess: PropTypes.func,
  onUpdateSuccess: PropTypes.func
}

export default TeamMemberFormLinked
