import intersection from 'lodash.intersection'

import { REQUIRED_PITCHTAPE_FIELDS } from '../constants/forms'
import { VIDEO_FINISHED_STATUS, TOPICS } from '../constants/videos'
import { isAllPresent } from '../helpers/objects'

export const isCompanyShareable = (company) => {
  return isCompanyComplete(company) && Boolean(company.concatenatedVideo.status === VIDEO_FINISHED_STATUS)
}

export const isCompanyComplete = (company) => {
  return areCompanyDetailsComplete(company) && Boolean(company.concatenatedVideo)
}

export const areCompanyDetailsComplete = (company) => {
  return isAllPresent(company, REQUIRED_PITCHTAPE_FIELDS)
}

export const hasRequiredVideos = (company) => {
  const recordedSegments = company.videoSegments.map(v => v.subject)
  let hasRequiredVideos = false
  for (const topic of TOPICS) {
    const segments = topic.segments.map(s => s.id)

    if (intersection(recordedSegments, segments).length !== topic.segments.length) {
      hasRequiredVideos = true
    }
  }
  return hasRequiredVideos
}

export const companyHasVideoInAnyTopic = (company) => {

  if (!company.videoSegments.length > 0) {
    return false
  } else {
    for (let segment of company.videoSegments) {
      if (segment.hlsUrl) {
        return true
      }
    }
    return false
  }
}

export const companyRequiresStitchVideos = (company) => {
  if (company.concatenatedVideo) {
    return !(company.concatenatedVideo.status !== VIDEO_FINISHED_STATUS || company.concatenatedVideo.videoIsUpToDate)
  } else {
    return companyHasVideoInAnyTopic(company)
  }
}
