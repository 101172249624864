import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react'
import PropTypes from 'prop-types'

import AnimatedEllipsis from '../AnimatedEllipsis'
import Button from '../Button'
import { TextArea } from '../Form'
import Link from '../Link'
import Text from '../Text'

import { EDIT_TELEPROMPT_CLASSNAME } from '../../constants/classnames'
import { useSaveCompanyTelepromptMutation } from '../../helpers/hooks'

const Teleteleprompt = ({ subject, defaultTeleprompt, example, editable }) => {
  const [ teleprompt, setTeleprompt ] = useState(defaultTeleprompt || '')
  const [ editing, setEditing ] = useState()

  const inputRef = useRef()

  const [ savePrompt, { loading: saving } ] = useSaveCompanyTelepromptMutation({
    onCompleted: (data) => {
      if (!data.errors) {
        setEditing(false)
      }
    }
  })

  useEffect(() => {
    if (editing) {
      inputRef.current.focus()
      inputRef.current.selectionStart = 100000
    }
  }, [ editing ])

  const words = useMemo(() => {
    const parts = teleprompt.trim().split(/\s+/)
    return parts[0] === '' ? 0 : parts.length
  }, [ teleprompt ])

  const handleUpdate = useCallback((obj) => {
    setTeleprompt(obj.teleprompt)
  }, [])

  const handleEditClick = useCallback(() => {
    setEditing(true)
  }, [])

  const handleSaveClick = useCallback(() => {
    if (teleprompt.trim() === defaultTeleprompt) {
      setEditing(false)
      return
    }

    if (!saving) {
      savePrompt({
        variables: {
          input: {
            subject,
            script: teleprompt
          }
        }
      })
    }
  }, [ defaultTeleprompt, teleprompt, subject, saving, savePrompt ])

  return (
    <Text tag='div' variant='standardLarger' style={{ lineHeight: '27px' }}>
      {editing && editable
        ? (
          <>
            <TextArea
              variant='teleprompt'
              name='teleprompt'
              value={teleprompt}
              rows='1'
              autoHeight
              innerRef={el => inputRef.current = el}
              onUpdate={handleUpdate}
            />

            <Text variant={null}>{words} word{words === 1 ? '' : 's'}</Text>
            {' '}
            <Button variant='primaryNarrow' offset='half-left' disabled={saving} onClick={handleSaveClick}>
              {saving ? <>Saving<AnimatedEllipsis /></> : 'Save script'}
            </Button>
          </>
        )
        : (editable || defaultTeleprompt) && (
          <>
            <Text tag='p' variant={null} inline>
              { !teleprompt ? `${ example }` : teleprompt }
            </Text>

            {' '}

            {editable &&
              <Link
                variant='inherit'
                className={EDIT_TELEPROMPT_CLASSNAME}
                offset='half-left'
                color='green'
                onClick={handleEditClick}
              >
                Edit Script
              </Link>
            }
          </>
        )
      }
    </Text>
  )
}

Teleteleprompt.propTypes = {
  subject: PropTypes.string,
  defaultTeleprompt: PropTypes.string,
  example: PropTypes.string,
  editable: PropTypes.bool
}

export default Teleteleprompt
