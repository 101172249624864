export const STAGEOFBUSINESS = [
  {
    value: 1,
    label: "Ideation (pre-incorporation yet identifiable solution)"
  },
  {
    value: 2,
    label: "Early stage (eg MVP/pre-funding/pre-revenue)"
  },
  {
    value: 3,
    label: "Startup (eg 1-5 years trading, seeking/secured seed funding/customers, pilot in process)"
  },
  {
    value: 4,
    label: "Scaleup (eg 20% YoY growth in either employment or turnover for past 2 years)"
  },
  {
    value: 5,
    label: "Mature (eg pre-IPO, looking to exit)"
  }
]


export const TEAMSIZE = [
  {
    value: 1,
    label: "1 to 2 employees"
  },
  {
    value: 2,
    label: "3 to 5 employees (mixture of full and part-time)"
  },
  {
    value: 3,
    label: "6 to 10 employees (mixture of full and part-time)"
  },
  {
    value: 4,
    label: "10+ employees (mixture of full and part-time)"
  },
  {
    value: 5,
    label: "10+ employees with strong technical capability"
  }
]