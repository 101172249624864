import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'

import Button from './Button'
import Icon from './Icon'
import Modal from './Modal'
import Popup from './Popup'
import Text from './Text'

import {
  EDIT_TELEPROMPT_CLASSNAME, TAPE2_CLASSNAME
} from '../constants/classnames'
import {
  EDIT_TELEPROMPT_TOOLTIP, PRACTICE_TAPE2_TOOLTIP, TAPE2_TOOLTIP, TRY_AGAIN_TOOLTIP,
} from '../constants/tooltips'
import { safeJsonParse } from '../helpers/json'

const TourTooltipContext = React.createContext()

const POPUPS = {
  [EDIT_TELEPROMPT_TOOLTIP]: {
    className: EDIT_TELEPROMPT_CLASSNAME,
    title: 'You can edit and save your teleprompter script',
    text: 'The text that you enter will display on your screen as a teleprompter while you are recording. Just don\'t forget to save your work!'
  },
  [PRACTICE_TAPE2_TOOLTIP]: {
    className: TAPE2_CLASSNAME,
    direction: 'up',
    title: 'Want to save your practice take and try again for comparison?',
    text: 'If you want to temporarily save your practice take and try again, click on Tape 2 and we’ll save your current take in Tape 1. You can keep practicing unlimited times in either Tape, but only the last take will be saved in each.'
  },
  [TAPE2_TOOLTIP]: {
    className: TAPE2_CLASSNAME,
    direction: 'up',
    title: 'Want to temporarily save your take and try again for comparison?',
    text: 'If you want to temporarily save your current take and try again, click on Tape 2 and we’ll save your current take in Tape 1. You can keep trying unlimited times in either Tape, but only the last take will be saved in each.'
  },
  [TRY_AGAIN_TOOLTIP]: {
    type: 'modal',
    title: 'You got this!',
    content: <div style={{ textAlign: 'center' }}>
      <div style={{ marginBottom: 25 }}>
        <Icon name='cheerleader' />
      </div>

      <Text tag='p' variant='large' weight='400' style={{ lineHeight: '32px' }}>
        <Icon name='film' style={{ verticalAlign: '-4px', marginRight: 10 }} />
        Pro Tip: Everyone gets nervous on camera.
        The best thing you can do is to be yourself.
        Show your personality and convey your passion.
        Have fun with it! Practice a line out loud a few times,
        then look at the camera to make eye contact, take a deep breath, and hit record.
      </Text>
    </div>
  }
}

export const Consumer = TourTooltipContext.Consumer

export const Provider = ({ children }) => {
  const [ popupKey, setPopupKey ] = useState()

  const handleGotItClick = useCallback(() => {
    const seenTooltips = safeJsonParse(localStorage.getItem('seenTooltips')) || []
    seenTooltips.push(popupKey)
    localStorage.setItem('seenTooltips', JSON.stringify(seenTooltips))

    setPopupKey(void 0)
  }, [ popupKey ])

  const showTourTooltip = useCallback((key) => {
    const seenTooltips = safeJsonParse(localStorage.getItem('seenTooltips')) || []

    if (!seenTooltips.includes(key)) {
      setPopupKey(key)
    }
  }, [])

  const hideTourTooltip = useCallback(() => {
    setPopupKey(void 0)
  }, [])

  const popup = POPUPS[popupKey]

  return (
    <TourTooltipContext.Provider value={{
      showTourTooltip,
      hideTourTooltip
    }}>
      {popup && (
        popup.type !== 'modal'
          ? (
            <Popup
              key={popupKey}
              variant='tourTooltip'
              visible
              anchorSelector={'.' + popup.className}
              direction={popup.direction}
              header={popup.title &&
                <Text tag='h1' variant='standardLarger' weight='500' offset='half-bottom'>{popup.title}</Text>
              }
              content={<Text tag='p'>{popup.text}</Text>}
              maxWidth={350}
              footer={
                <div>
                  <Button variant='tooltipWhite' onClick={handleGotItClick}>
                    Got it!
                  </Button>
                </div>
              }
            />
          )
          : (
            <Modal
              key={popupKey}
              variant='welcome'
              title={<Text tag='h1' variant='h2' color='white'>{popup.title}</Text>}
              buttons={<>
                <Button variant='primary' onClick={handleGotItClick}>
                  Got it!
                </Button>
              </>}
            >
              <div>
                {popup.content}
              </div>
            </Modal>
          )
      )}

      {children}
    </TourTooltipContext.Provider>
  )
}

Provider.propTypes = {
  children: PropTypes.node
}

export default TourTooltipContext
