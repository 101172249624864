import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Switch, Route } from 'react-router-dom'

import LandingScreen from './LandingScreen'
import SignInScreen from './SignInScreen'
import SignUpScreen from './SignUpScreen'
import SignUpInvestorProfileScreen from './SignUpInvestorProfileScreen'
import ResetPasswordScreen from './ResetPasswordScreen'
import ForgotPasswordScreen from './ForgotPasswordScreen'
import PitchtapeScreen from './PitchtapeScreen'
import CreateScreen from './CreateScreen'
import CreateVideosScreen from './CreateVideosScreen'
import CreateTeleprompterScriptScreen from './CreateTeleprompterScriptScreen'
import PracticeScreen from './PracticeScreen'
import SettingsScreen from './SettingsScreen'
import ProfileScreen from './ProfileScreen'
import SignOutScreen from './SignOutScreen'
import ConfirmScreen from './ConfirmScreen'
import FeedScreen from './FeedScreen'
import StartupScreen from './StartupScreen'
import UnsubscribeScreen from './UnsubscribeScreen'

import NotFoundScreen from './NotFoundScreen'

import ProtectedRoute from '../components/ProtectedRoute'
import * as routes from '../constants/routes'
import FeedListScreen from "./FeedListScreen"
import SavedCompaniesScreen from "./SavedCompaniesScreen"

const RootScreen = ({ location, history }) => {

  useEffect(() => {
    const handleDocumentRedirect = (event) => {
      history.push(event.detail)
    }

    document.addEventListener('redirect', handleDocumentRedirect)

    return () => {
      document.removeEventListener('redirect', handleDocumentRedirect)
    }
  }, [history])

  useEffect(() => {
    if (!location.state?.noScrollToTop) {
      window.scrollTo(0, 0)
    }
  }, [location])

  return (
    <Switch>
      <Route path={routes.STARTUP_PATH} component={StartupScreen} />
      <Route path={routes.UNSUSCRIBE_PATH} component={UnsubscribeScreen} />

      <ProtectedRoute path={routes.SIGNIN_PATH} component={SignInScreen} />
      <ProtectedRoute forSignedOut exact path={routes.SIGNUP_PATH} component={SignUpScreen} />
      <ProtectedRoute
        forSignedOut
        exact
        path={routes.PASSWORD_PATH}
        render={(props) => {
          const query = new URLSearchParams(props.location.search)
          const token = query.get('token')
          if (token) {
            return <ResetPasswordScreen {...props} token={token} />
          } else {
            return <ForgotPasswordScreen {...props} />
          }
        }}
      />

      <ProtectedRoute forInvestor path={routes.FEED_PATH} component={FeedScreen} />
      <ProtectedRoute
        forInvestor
        path={routes.SIGNUP_INVESTOR_PROFILE_PATH}
        component={SignUpInvestorProfileScreen}
      />
      <ProtectedRoute forInvestor path={routes.PROFILE_PATH} component={ProfileScreen} />

      <ProtectedRoute
        forSignedOut
        exact
        path={routes.HOME_PATH}
        component={LandingScreen}
      />
      <ProtectedRoute forFounder path={routes.PITCHTAPE_PATH} component={PitchtapeScreen} />
      <ProtectedRoute
        forFounder
        exact
        path={routes.CREATE_PATH}
        component={CreateScreen}
      />
      <ProtectedRoute
        forFounder
        exact
        path={routes.CREATE_VIDEOS_PATH}
        component={CreateVideosScreen}
      />
      <ProtectedRoute
        forFounder
        exact
        path={routes.CREATE_TELEPROMPTER_SCRIPT}
        component={CreateTeleprompterScriptScreen}
      />
      <ProtectedRoute
        forFounder
        exact
        path={routes.PRACTICE_PATH}
        component={PracticeScreen}
      />

      <ProtectedRoute
        forInvestor
        path={routes.SAVED_PATH}
        component={SavedCompaniesScreen}
      />
      {/* <ProtectedRoute forFounder path={routes.BILLING_PATH} component={BillingScreen} />
      <ProtectedRoute
        forFounder
        path={routes.SUBSCRIPTION_UPGRADED_PATH}
        component={SubscriptionUpgradedScreen}
      />
      <ProtectedRoute
        forFounder
        path={routes.SUBSCRIPTION_CANCELED_PATH}
        component={SubscriptionCanceledScreen}
      /> */}

      <ProtectedRoute forSignedIn path={routes.SETTINGS_PATH} component={SettingsScreen} />
      <ProtectedRoute forSignedIn path={routes.SIGNOUT_PATH} component={SignOutScreen} />
      <ProtectedRoute forSignedIn path={routes.FEED_LIST_PATH} component={FeedListScreen} />


      <Route path={routes.CONFIRM_PATH} component={ConfirmScreen} />

      <Route component={NotFoundScreen} />
    </Switch>
  )
}

RootScreen.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object
}

export default RootScreen
