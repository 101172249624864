import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { graphql } from 'react-apollo'

import Dropdown from './Dropdown'

const GET_STAGES = gql`
  query getStages {
    stages: getStages {
      id
      name
    }
  }
`

const StageDropdown = ({ data, ...dropdownProps }) => {
  const options = useMemo(() => {
    return data.stages && data.stages.map(x => ({ value: x, label: x.name }))
  }, [data.stages])

  return (
    <Dropdown
      options={options}
      valueIdKey='id'
      renderLabel={value => value.name}
      {...dropdownProps}
    />
  )
}

StageDropdown.propTypes = {
  data: PropTypes.object
}

export default graphql(GET_STAGES)(StageDropdown)
