import { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

import { MILLISECONDS_IN_A_DAY } from '../../constants/dates'

const RefetchCurrentOnSubscriptionExpire = ({ subscription, onRefetch }) => {
  const subscriptionTimeoutRef = useRef()

  useEffect(() => {
    if (subscription) {
      clearTimeout(subscriptionTimeoutRef.current)

      const { currentPeriodEnd } = subscription

      if (currentPeriodEnd) {
        const timeDiff = new Date(currentPeriodEnd) - new Date()

        if (timeDiff > 0 && timeDiff < MILLISECONDS_IN_A_DAY) {
          subscriptionTimeoutRef.current = setTimeout(() => {
            onRefetch()
          }, timeDiff)
        }
      }
    }

    return () => {
      clearTimeout(subscriptionTimeoutRef.current)
    }
  }, [ subscription, onRefetch ])

  return null
}

RefetchCurrentOnSubscriptionExpire.propTypes = {
  subscription: PropTypes.object,
  onRefetch: PropTypes.func
}

export default RefetchCurrentOnSubscriptionExpire
