import css from './OptionsList.module.sass'

import React, { useEffect, useRef, useState, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'

import Icon from '../../Icon'
import Text from '../../Text'
import Button from '../../Button'
import Link from '../../Link'

import { isMobile } from '../../../helpers/devices'
import { arrayIncludes } from '../../../helpers/arrays'

const OptionsList = React.forwardRef(({
  position, options, hoveredIndex, scrollToHovered, selected, showSelectAll, valueIdKey,
  onMouseDown, onOptionSelect, onOptionHover, onSelectAll, onFetchMore
}, ref) => {
  const [ selectedOnly, setSelectedOnly ] = useState()

  const liHoveredRef = useRef()
  const ulRef = useRef()

  const isSelected = useCallback((option) => {
    return Array.isArray(selected)
      ? arrayIncludes(selected, option.value, valueIdKey)
      : valueIdKey
        ? selected && (selected[valueIdKey] === option.value[valueIdKey])
        : selected === option.value
  }, [ selected, valueIdKey ])

  useEffect(() => {
    // scroll on keyboard navigation

    if (!scrollToHovered || !liHoveredRef.current || isMobile()) {
      return
    }

    const visibleTop = ulRef.current.scrollTop
    const visibleBottom = visibleTop + ulRef.current.clientHeight
    const liTop = liHoveredRef.current.offsetTop
    const liBottom = liTop + liHoveredRef.current.clientHeight

    if (liBottom > visibleBottom) {
      ulRef.current.scrollTop = visibleTop + liBottom - visibleBottom
    } else if (liTop < visibleTop) {
      ulRef.current.scrollTop = liTop
    }
  }, [ hoveredIndex, scrollToHovered ])

  const visibleOptions = useMemo(() => {
    return selectedOnly ? options.filter(o => isSelected(o)) : options
  }, [ isSelected, options, selectedOnly ])

  useEffect(() => {
    if (selectedOnly && !visibleOptions.length) {
      setSelectedOnly(false)
    }
  }, [ selectedOnly, visibleOptions ])

  useEffect(() => {
    const ul = ulRef.current

    const handleScroll = () => {
      if (ul.scrollHeight - ul.scrollTop <= ul.clientHeight) {
        onFetchMore()
      }
    }

    if (ul && onFetchMore) {
      ul.addEventListener('scroll', handleScroll)

      return () => {
        ul.removeEventListener('scroll', handleScroll)
      }
    }
  }, [ onFetchMore ])

  if (!visibleOptions) {
    return null
  }

  return (
    <div
      ref={ref}
      className={css.container}
      style={position}
      onMouseDown={onMouseDown}
    >
      {showSelectAll &&
        <div className={css.actions}>
          <Link variant='expander' onClick={onSelectAll}>
            {selected.length === options.length ? 'Deselect All' : 'Select All'}
          </Link>

          {Boolean(selected.length) && selected.length < options.length &&
            <Link variant='expander' onClick={() => setSelectedOnly(!selectedOnly)}>
              {selectedOnly ? 'Show All' : 'Show Selected'}
            </Link>
          }
        </div>
      }

      <ul className={css.ul} ref={ulRef}>
        {visibleOptions.map((option, index) => {
          const isHovered = hoveredIndex === index

          return (
            <li
              key={valueIdKey ? option.value[valueIdKey] : option.value}
              className={(!isMobile() && isHovered) ? css.liHovered : css.li}
              onMouseOver={isMobile() ? void 0 : () => onOptionHover(index)}
              ref={isHovered ? liHoveredRef : null}
            >
              <Button
                variant='transparent'
                block
                onClick={() => onOptionSelect(option)}
              >
                <span className={css.option}>
                  <Text color='black'>{option.label}</Text>

                  {isSelected(option) &&
                    <Icon name='tick' />
                  }
                </span>
              </Button>
            </li>
          )
        })}
      </ul>
    </div>
  )
})

OptionsList.displayName = 'OptionsList'

OptionsList.propTypes = {
  position: PropTypes.object,
  options: PropTypes.array,
  hoveredIndex: PropTypes.number,
  scrollToHovered: PropTypes.bool,
  selected: PropTypes.any,
  showSelectAll: PropTypes.bool,
  valueIdKey: PropTypes.string,
  onMouseDown: PropTypes.func,
  onOptionSelect: PropTypes.func,
  onOptionHover: PropTypes.func,
  onSelectAll: PropTypes.func,
  onFetchMore: PropTypes.func
}

export default OptionsList
