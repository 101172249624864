export const VIDEO_PITCH_TEMPLATE_URL = "https://docs.google.com/document/d/1aEN0i5_Z5w1VyofxC9swlMlUqCTMfI3hmYfVjB6Hg_Q/edit"
export const RECORDING_TIPS_URL = "https://docs.google.com/document/d/1cUZrG3NYlIKzy4TWtU4OIlkq-nIXEhiQ0eyPurpfUcA/edit"
export const TECHNICAL_REQUIREMENTS_URL = "https://docs.google.com/document/d/1GCWoiY0rXVvY-Zfy8dJdG7Lfl4Kvd_knKSJvS7otcZU/edit"
export const TUTORIAL_URL = "https://www.youtube.com/watch?v=0S0dZ217hVY"

export const SURVEY_URL = "https://forms.gle/7XYHT6MYSK1ZVYwM6"
export const SCHEDULE_1_ON_1_URL = "https://calendly.com/pitchtape/60min"

export const HLS_JS_URL = "https://cdn.jsdelivr.net/npm/hls.js@latest/dist/hls.min.js"
export const STRIPE_JS_URL = "https://checkout.stripe.com/checkout.js"
export const PDF_JS_URL = "https://cdn.jsdelivr.net/npm/pdfjs-dist@2.2.228/build/pdf.min.js"
