import css from './CompanyDetails.module.sass'

import React from 'react'
import PropTypes from 'prop-types'

import Text from '../Text'

export const formatProperty = (property) => {
  if (Array.isArray(property)) {
    return property.map(x => x.name).join(', ')
  } else if (property !== null && typeof property === 'object') {
    return property.name
  } else if (typeof property === 'boolean') {
    return property ? 'Yes' : 'No'
  }

  return property
}

const CompanyDetails = ({ company }) => {

  return (
    <>
      <div className={css.containerIcon}>
        {
          company.iconFile &&
          <img src={company.iconFile} alt="icon" />
        }
        <Text variant='h2' tag='h1' weight='400' truncated>
          {company.name}
        </Text>
      </div>

      <Text variant='h3' tag='p' offset='half-bottom'>{company.description}</Text>

    </>
  )
}

CompanyDetails.propTypes = {
  company: PropTypes.object,
  iconColor: PropTypes.string
}

export default CompanyDetails
