import css from './Deck.module.sass'

import React from 'react'
import PropTypes from 'prop-types'

import DocViewer from '../DocViewer'

const Deck = ({ company, height }) => {
  return (
    <div className={css.container} style={{ height }}>
      <DocViewer
        variant={height ? 'fillHeight' : 'base'}
        src={company.slidesFile}
        height={height}
      />
    </div>
  )
}

Deck.propTypes = {
  company: PropTypes.object,
  height: PropTypes.number
}

export default Deck
