import css from './FeedScreen.module.sass'
import React, { useEffect, useState, useContext, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from 'react-apollo'
import get from 'lodash.get'
import { useLocalStorage } from 'react-use'

import Layout from '../components/Layout'

import Alert from '../components/Alert'
import Button from '../components/Button'
import InvestorTour from '../components/InvestorTour'
import List from '../components/List'
import Loader from '../components/Loader'
import Modal from '../components/Modal'
import PitchtapeReview from '../components/PitchtapeReview'
import Text from '../components/Text'

import CurrentUserContext from '../components/CurrentUserContext'
import DevicesContext from '../components/DevicesContext'

import { formatGraphQLError } from '../helpers/errors'
import { usePitchtapeTracking } from '../helpers/hooks'
import { PROFILE_PATH } from '../constants/routes'
import { GET_FEED } from '../constants/queries'
import {
  PORTAL_NAME,
  VIDEO_REVIEWER_TOUR_ENABLED, VIDEO_REVIEWER_PROFILE_ENABLED
} from '../constants/enterpriseConfig'
import { TOUR_INVESTOR_FILTER_CLASS } from '../constants/tour'
import Filter from "../components/Feed/Filter"
import Search from "../components/Search";

const FeedScreen = ({ location, match }) => {
  const videoIdParam = match?.params?.videoId === ':videoId' ? 0 : match?.params?.videoId
  const [userFirstRegister, setUserFirstRegister] = useState(!!location?.state?.investorProfileCreated)
  const [canShowTour, setCanShowTour] = useState(false)
  const feedOffsetLS = Number(localStorage.getItem('feedOffset'))
  const [actionResult, setActionResult] = useState()
  const [offset, setOffset] = useState(videoIdParam ? videoIdParam - 1 : feedOffsetLS || 0)

  const [stageOfBusiness, setStageOfBusiness] = useState(JSON.parse(localStorage.getItem('feedStageOfBusiness')) || null)
  const [teamSize, setTeamSize] = useState(JSON.parse(localStorage.getItem('feedTeamSize')) || null)
  const [teamSizeData, setTeamSizeData] = useState(JSON.parse(localStorage.getItem('feedTeamSize')) || null)
  const [stageOfBusinessList, setStageOfBusinessList] = useState(JSON.parse(localStorage.getItem('feedStageOfBusiness')) || null)
  const [searchTerm, setSearchTerm, removeSearchTerm] = useLocalStorage('feed-search-term', '')
  const [industries, setIndustries] = useState(JSON.parse(localStorage.getItem('feedIndustries')) || [])
  const [industriesList, setIndustriesList] = useState(JSON.parse(localStorage.getItem('feedIndustries')) || [])

  const [total, setTotal] = useState(0)
  const [openJumpToModal, setOpenJumpToModal] = useState(false)
  const [positionToJump, setPositionToJump] = useState(offset + 1)
  const [videosExist, setVideosExist] = useState(false)

  const { currentUser: { completedTourGuide, firstName } } = useContext(CurrentUserContext)
  const { mobile } = useContext(DevicesContext)

  const { data, loading, error, networkStatus, client } = useQuery(GET_FEED, {
    variables: {
      first: 1,
      offset,
      industries: industriesList.map(i => i.id),
      stageOfBusiness: stageOfBusinessList ? stageOfBusinessList : null,
      teamSize: teamSizeData ? teamSizeData : null,
      q: searchTerm,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (data?.getFeed?.total) {
        setTotal(data.getFeed.total + offset)
        setVideosExist(true)
      }
      if (offset > 0 && !data.getFeed.edges?.length) {
        setOffset(0)
      }
    }
  })

  const edges = get(data, 'getFeed.edges')
  const hasPreviousPage = offset > 0
  const hasNextPage = get(data, 'getFeed.pageInfo.hasNextPage')
  const company = edges && edges.length && edges[0].node


  usePitchtapeTracking(company)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [company])

  const handleRate = useCallback((rating) => {
    client.writeQuery({
      query: GET_FEED,
      variables: { first: 1 },
      data: {
        getFeed: {
          ...data.getFeed,
          edges: [
            {
              ...data.getFeed.edges[0],
              node: {
                ...data.getFeed.edges[0].node,
                givenRating1: rating
              }
            }
          ]
        }
      }
    })
  }, [data, client])

  useEffect(() => {
    localStorage.setItem('feedOffset', offset)
    setPositionToJump(offset + 1)
  }, [offset])

  const handleInvestorAction = useCallback((status) => {
    setActionResult({ company, status })
  }, [company])

  const handlePrevious = useCallback(() => {
    setOffset(o => o - 1)
  }, [])

  const handleNext = useCallback(() => {
    setOffset(o => o + 1)
  }, [])

  useEffect(() => {
    localStorage.setItem('feedStageOfBusiness', JSON.stringify(stageOfBusiness))
  }, [stageOfBusiness])

  useEffect(() => {
    localStorage.setItem('feedTeamSize', JSON.stringify(teamSize))
  }, [teamSize])

  useEffect(() => {
    localStorage.setItem('feedIndustries', JSON.stringify(industries))
  }, [industries])


  const handleFilterChangeCallBack = () => {
    setOffset(0)
  }

  const handleSaveFilter = () => {
    setStageOfBusinessList(stageOfBusiness)
    setTeamSizeData(teamSize)
    setIndustriesList(industries)
  }

  const handleCancelFilter = () => {
    setStageOfBusiness(stageOfBusinessList)
    setTeamSize(teamSizeData)
    setIndustries(industries.slice(0, industriesList.length))
  }

  const handleClearFilter = () => {
    setStageOfBusinessList(null)
    setTeamSizeData(null)
    setStageOfBusiness(null)
    setTeamSize(null)
    setIndustriesList([])
    setIndustries([])
  }

  const renderFilter = () => {
    return <div className={`${css.filter} ${TOUR_INVESTOR_FILTER_CLASS}`}>
      <Filter
        stageOfBusiness={stageOfBusiness}
        setStageOfBusiness={setStageOfBusiness}
        teamSize={teamSize}
        setTeamSize={setTeamSize}
        industries={industries}
        setIndustries={setIndustries}
        filterCallBack={handleFilterChangeCallBack}
        onSaveFilter={handleSaveFilter}
        onCancelFilter={handleCancelFilter}
        onClearFilter={handleClearFilter}
        noCancel
      />

    </div>
  }

  const renderEmptyFeed = () => {
    return <>
      <Text variant="h1" tag="h1" offset="half-bottom">
        The {PORTAL_NAME} is now live!
      </Text>

      <Text variant="large" tag="p" offset="double-bottom">
        Submissions are in progress. Check back soon for updates.
      </Text>
      {VIDEO_REVIEWER_PROFILE_ENABLED &&
        <List variant="centeredActions">
          <List.Item style={{ maxWidth: 160 }}>
            <Button
              variant="iconOnTop"
              external
              href="https://pitchtape.com/blog"
              icon="blogBlue"
              iconMaxHeight={24}
            >
              Read Pitchtape&apos;s blog for tips on evaluating startups
            </Button>
          </List.Item>

          <List.Item style={{ maxWidth: 110 }}>
            <Button variant='iconOnTop' to={PROFILE_PATH} icon='pencilBlue' iconMaxHeight={24}>
              Update your investor profile
            </Button>
          </List.Item>
        </List>}
    </>
  }

  const onEditClick = () => {
    window.history.back();
  };

  const renderNoSearchResults = () => {
    return <div className="feedScreen no-results">
      <Search
        term={searchTerm}
        readOnly={true}
        editable
        onChange={setSearchTerm}
        onClear={removeSearchTerm}
      />

      <div className="actions">
        <span onClick={onEditClick}>
          Edit search
        </span>
        <span onClick={removeSearchTerm}>
          Clear search
        </span>
      </div>
      <Text centered variant="large" tag="p" offset="double-bottom">
        No results were found for this criteria
      </Text>

      <Text centered variant="large" tag="p" offset="double-bottom">
        Please edit or clear your search
      </Text>
    </div>
  }


  if (!company && !loading) {
    return (
      <Layout variant="dashboard">
        {(videosExist || teamSize || industries.length > 0 || stageOfBusiness) && renderFilter()}
        {(teamSize || stageOfBusiness || industries.length > 0) ?
          <div className={css.containerNoResults}>
            <Text variant="large" tag="p" offset="double-bottom">
              No results were found for this criteria
            </Text>
          </div>
          : ((!videosExist && searchTerm && searchTerm !== '') ? renderNoSearchResults() : (!videosExist && renderEmptyFeed()))
        }
      </Layout>
    )
  }


  const renderContent = () => {
    if (loading || networkStatus === 4) {
      return (
        <Layout noFooter variant='fullHeight'>
          <Loader variant='centered' />
        </Layout>
      )
    }

    if (error) {
      return (
        <Layout variant='dashboard'>
          <Alert variant='error'>{formatGraphQLError(error)}</Alert>
        </Layout>
      )
    }

    if (!company) {
      return (
        <Layout variant="dashboard">
          {renderFilter()}
          renderEmptyFeed()
        </Layout>
      )
    }

    const searchCmp = (
      <div className='feedScreen'>
        <Search
          term={searchTerm}
          readOnly={true}
          editable
          onChange={setSearchTerm}
          onClear={removeSearchTerm}
        />
      </div>
    )


    return (
      <Layout background='white' noFooter>
        <PitchtapeReview
          key={company.id}
          shareable={true}
          company={company}
          forInvestor
          onRate={handleRate}
          hasPrevious={hasPreviousPage}
          hasNext={hasNextPage}
          onInvestorAction={handleInvestorAction}
          onPrevious={handlePrevious}
          onNext={handleNext}
          totalVideos={total}
          offset={offset + 1}
          industries={industries}
          setIndustries={setIndustries}
          stageOfBusiness={stageOfBusiness}
          setStageOfBusiness={setStageOfBusiness}
          teamSize={teamSize}
          setTeamSize={setTeamSize}
          onSaveFilter={handleSaveFilter}
          onCancelFilter={handleCancelFilter}
          onClearFilter={handleClearFilter}
          navigationButtons
          searchCmp={searchTerm && searchTerm !== '' ? searchCmp : null}
        />

        {(canShowTour || (!canShowTour && !userFirstRegister)) &&
          !completedTourGuide &&
          !mobile &&
          VIDEO_REVIEWER_TOUR_ENABLED &&
          <InvestorTour />
        }
      </Layout>
    )
  }

  return (
    <>
      {actionResult &&
        <Alert
          key={actionResult.company.id}
          variant="success"
          ttl={2000}
          noClose
          sticky
          centered
          onClose={() => setActionResult(null)}
        >
          You have{' '}
          {actionResult.status === 'saved'
            ? 'saved'
            : actionResult.status === 'connected'
              ? 'connected to'
              : 'passed on'
          }
          {' '}{actionResult.company.name}.
        </Alert>
      }
      {userFirstRegister &&
        <Modal
          variant="welcome"
          title={<Text tag="h1" variant="h2" color="white">Hi {firstName}, welcome!</Text>}
          buttons={
            <Button onClick={() => {
              setUserFirstRegister(false)
              setCanShowTour(true)
            }} variant="primary">
              View pitches
            </Button>
          }
        >
          <div style={{ textAlign: 'center' }}>
            <Text tag="p" variant="larger" weight="400" offset="single-bottom">
              Thank you for signing up to the {PORTAL_NAME}.
              You can now review and rate pitches.
            </Text>
          </div>
        </Modal>
      }
      {openJumpToModal &&
        <Modal title={<Text variant='standardLarger' tag='h2'>You&apos;re currently viewing pitch #{offset + 1}</Text>}
          onClosed={() => setOpenJumpToModal(false)}
          content={<div className={css.jumpToContainer}>
            <div className={css.jumpToForm}>
              <Text variant='standardLarger' tag='p'>Jump to any pitch you&apos;d like to view</Text>
              <Text variant='standardLarger' tag='p'>Enter a number in the box below (1 - {total}):</Text>
              <input type="number" id="jumpTo" name="jumpTo" min="1" value={positionToJump} onChange={(event) => {
                if (event.target.value > 0 && event.target.value <= total) {
                  setPositionToJump(event.target.value)
                }

              }} max={total} />
            </div>
          </div>} buttons={
            <>
              <Button variant='outlineNarrow' onClick={() => setOpenJumpToModal(false)}>Cancel</Button>
              <Button variant='primaryNarrow' onClick={() => {
                setOffset(positionToJump - 1)
                setPositionToJump(offset + 1)
                setOpenJumpToModal(false)
              }
              }>Go!</Button>
            </>
          } />
      }
      {renderContent()}
    </>
  )
}

FeedScreen.propTypes = {
  location: PropTypes.object,
  match: PropTypes.object
}

export default FeedScreen
