import { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'

const scripts = {}

const AsyncScript = ({ src, children }) => {
  const [ loading, setLoading ] = useState(true)
  const [ error, setError ] = useState(null)

  const handleLoad = useCallback(() => {
    scripts[src] = true

    setLoading(false)
    setError(null)
  }, [ src ])

  const handleError = (err) => {
    setLoading(false)
    setError(err)
  }

  const doesScriptExist = useCallback(() => {
    return Boolean(document.querySelector(`script[src="${src}"]`))
  }, [ src ])

  const createScript = useCallback(() => {
    scripts[src] = false

    const script = document.createElement('script')

    script.async = true
    script.type = 'text/javascript'
    script.src = src

    document.head.appendChild(script)

    return script
  }, [ src ])

  useEffect(() => {
    if (scripts[src]) {
      return handleLoad()
    }
    
    let script

    if (!Object.keys(scripts).includes(src)) {
      script = createScript()
    } else {
      script = document.querySelector(`script[src="${src}"]`)
    }

    script.addEventListener('load', handleLoad)
    script.addEventListener('error', handleError)

    return () => {
      script.removeEventListener('load', handleLoad)
      script.removeEventListener('error', handleError)
    }
  }, [ doesScriptExist, createScript, handleLoad, src ])

  return children({ loading, error })
}

AsyncScript.propTypes = {
  children: PropTypes.func
}

export default AsyncScript
