export function moveItemInArray (array, from, to) {
  const nextArray = [ ...array ]
  const [ reorderedItem ] = nextArray.splice(from, 1)
  nextArray.splice(to, 0, reorderedItem)
  return nextArray
}

export function arrayIncludes (array, value, key) {
  if (key) {
    return array.some(x => x[key] === value[key])
  }

  return array.includes(value)
}
