import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { Form, FormRow, FormCol, EmailField, PasswordField, TextField } from './Form'
import Button from './Button'
import Text from './Text'
import Link from './Link'

import { MISSING_ERROR } from '../constants/errors'
import { GTM_FORM_SINGUP_FOUNDER, GTM_FORM_SINGUP_INVESTOR } from '../constants/gtm'

const TERMS_OF_USE_URL = 'https://www.pitchtape.com/terms-of-service'
const PRIVACY_POLICY_URL = 'https://www.pitchtape.com/privacy-policy'

const firstNameErrorsOverride = {
  [MISSING_ERROR]: 'Please enter your first name.'
}

const lastNameErrorsOverride = {
  [MISSING_ERROR]: 'Please enter your last name.'
}

const emailErrorsOverride = {
  [MISSING_ERROR]: 'Please enter your email address.'
}

const passwordErrorsOverride = {
  [MISSING_ERROR]: 'Please enter your password.'
}

class SignUpForm extends PureComponent {
  static propTypes = {
    accountType: PropTypes.string,
    loading: PropTypes.bool,
    onUserCreate: PropTypes.func
  }

  state = {
    formData: {
      firstName: '',
      lastName: '',
      email: '',
      password: ''
    },
    valid: false
  }

  handleFieldUpdate = (patch) => {
    this.setState({
      formData: {
        ...this.state.formData,
        ...patch
      }
    })
  }

  render() {
    const { loading, accountType } = this.props
    const { formData, valid } = this.state
    const createAccountCopy = 'Create account'

    return (
      <Form
        gtm={accountType === 'founder' ? GTM_FORM_SINGUP_FOUNDER : GTM_FORM_SINGUP_INVESTOR}
        onSubmit={(event) => {
          event.preventDefault()

          if (valid && !loading) {
            this.props.onUserCreate({ ...formData, accountType })
          }
        }}
        onValid={() => this.setState({ valid: true })}
        onInvalid={() => this.setState({ valid: false })}
      >
        <FormRow>
          <FormCol>
            <TextField
              label='First Name'
              name='firstName'
              value={formData.firstName}
              required
              errorsOverride={firstNameErrorsOverride}
              onUpdate={this.handleFieldUpdate}
            />
          </FormCol>
        </FormRow>

        <FormRow offset='20'>
          <FormCol>
            <TextField
              label='Last Name'
              name='lastName'
              value={formData.lastName}
              required
              errorsOverride={lastNameErrorsOverride}
              onUpdate={this.handleFieldUpdate}
            />
          </FormCol>
        </FormRow>

        <FormRow offset='20'>
          <FormCol>
            <EmailField
              label='Email'
              name='email'
              value={formData.email}
              required
              shouldNotExist
              errorsOverride={emailErrorsOverride}
              onUpdate={this.handleFieldUpdate}
            />
          </FormCol>
        </FormRow>

        <FormRow offset='20'>
          <FormCol>
            <PasswordField
              label='Password'
              name='password'
              value={formData.password}
              required
              errorsOverride={passwordErrorsOverride}
              onUpdate={this.handleFieldUpdate}
            />
          </FormCol>
        </FormRow>

        <FormRow offset='35'>
          <FormCol>
            <Button variant='primary' type='submit' block disabled={loading}>
              {createAccountCopy}
            </Button>
          </FormCol>
        </FormRow>

        <FormRow offset="20">
          <Text tag="p" variant="small">
            By creating an account, I agree to Pitchtape&apos;s
            {' '}<Link variant="inherit" external href={TERMS_OF_USE_URL}>Terms&nbsp;of&nbsp;Service</Link> and
            {' '}<Link variant="inherit" external href={PRIVACY_POLICY_URL}>Privacy&nbsp;Policy</Link>.
          </Text>
        </FormRow>
      </Form>
    )
  }
}

export default SignUpForm
