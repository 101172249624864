import css from './Image.module.sass'

import React from 'react'
import PropTypes from 'prop-types'

const Image = ({ fileName, alt = '', media, block, ...rest }) => {
  if (/\.webp$/.test(fileName)) {
    return (
      <picture>
        <source srcSet={require('../images/' + fileName)} type='image/webp' media={media} />
        <source srcSet={require('../images/' + fileName.replace('webp', 'png'))} media={media} />
        <img alt='phone' />
      </picture>
    )
  }

  return (
    <img
      className={block ? css.block : void 0}
      src={require('../images/' + fileName)}
      alt={alt}
      {...rest}
    />
  )
}

Image.propTypes = {
  fileName: PropTypes.string,
  alt: PropTypes.string,
  media: PropTypes.string,
  block: PropTypes.bool
}

export default Image
