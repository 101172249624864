import PropTypes from "prop-types"
import React from "react"

import Ionicon from "../Ionicon"
import { formatProperty } from "../PitchtapeReview/CompanyDetails"
import cssFeed from "../../screens/FeedListScreen.module.sass"
import Link from "../Link"
import { toWithParams } from "../../helpers/routes"
import { FEED_PATH } from "../../constants/routes"
import Tooltip from "../Tooltip"
import { STAGEOFBUSINESS, TEAMSIZE } from '../../constants/otherInfo'

const FeedItem = ({ index, company }) => {
    const videoId = index + 1

    const calculateGivenRate = () => {
        if (company.givenRating1 === 0)
            return 0.0
        else if (company.givenRating2 === 0)
            return 0.0
        else if (company.givenRating3 === 0)
            return 0.0
        else if (company.givenRating4 === 0)
            return 0.0
        else {
            return (
                (company.givenRating1 +
                    company.givenRating2 +
                    company.givenRating3 +
                    company.givenRating4
                ) / 4
            ).toFixed(2)
        }
    }
    const renderRated = () => {
        if (company.givenRating1) {
            return (<Tooltip hoverable content={'yes'} ><Ionicon name='checkmark' color='green' size='24' /></Tooltip>)
        } else {
            return (<Tooltip hoverable content={'no'}><Ionicon name='close' color='nba' size='24' /></Tooltip>)
        }
    }

    const stageOfBusiness = STAGEOFBUSINESS.find(el => el.value === company.stageOfBusiness)
    const teamSize = TEAMSIZE.find(el => el.value === company.teamSize)
    const industries = company?.industries.map((elem) => elem.name).join(', ')

    return (
        <tr className={index % 2 ? cssFeed.odd : cssFeed.even}>
            <td>
                {videoId}
            </td>
            <td>
                {company.name}
            </td>
            <td>
                {company.description}
            </td>
            <td>
                {stageOfBusiness.label}
            </td>
            <td>
                {teamSize.label}
            </td>
            <td>
                {industries}
            </td>
            <td className={cssFeed.buttonAction}>
                <Link to={toWithParams(FEED_PATH, { videoId: videoId })}>
                    <Ionicon name='pitchtapeIcon' size='24' />
                </Link>
            </td>
            <td className={cssFeed.buttonAction}>
                {renderRated()}
            </td>
            <td>
                {calculateGivenRate()}
            </td>
            <td>
                {formatProperty(company.givenRatingText)}
            </td>
            <td>
                <div
                    dangerouslySetInnerHTML={{
                        __html: company.givenNote
                    }}
                />
            </td>
        </tr>
    )
}

FeedItem.propTypes = {
    index: PropTypes.number,
    company: PropTypes.object
}

export default FeedItem