import css from './SharingBlock.module.sass'

import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import Button from '../Button'
import CompanyUrlCopier from '../CompanyUrlCopier'
import InfoTooltip from '../InfoTooltip'
import Ionicon from '../Ionicon'
import Link from '../Link'
import Tooltip from '../Tooltip'
import Well from '../Well'
import Wrap from '../Wrap'

import CurrentUserContext from '../CurrentUserContext'

import { BILLING_PATH } from '../../constants/routes'
import { useDownloadVideoUrl } from '../../helpers/hooks'
import { isActivePaidPlan } from '../../helpers/subscription'
import { PRODUCT_NAME } from "../../constants/enterpriseConfig";

const SharingBlock = ({ company }) => {
  const { currentUser: { subscription } } = useContext(CurrentUserContext)

  const downloadVideoUrl = useDownloadVideoUrl(company)

  return (
    <Well
      title={`Share ${PRODUCT_NAME}`}
      nextToTitle={
        <InfoTooltip
          content={
            `To share your ${PRODUCT_NAME} URL, you must complete the Basic Info and Pitch Video sections.`
          }
          style={{ marginTop: -15 }}
        />
      }
      description={`Click on the sharing icon to copy your unique ${PRODUCT_NAME} URL`}
    >
      <div className={css.buttons}>
        <CompanyUrlCopier>
          <Button
            variant='iconOnTopRoundHighlight'
            icon={<Ionicon name='share' color='white' size='24' style={{ left: -1 }} />}
          >
            Share
          </Button>
        </CompanyUrlCopier>

        {downloadVideoUrl &&
          <Wrap
            in={(children) =>
              <Tooltip
                hoverable
                content={<>
                  <Link variant='inherit' to={BILLING_PATH}>Upgrade</Link> to download your video
                </>}
              >
                {children}
              </Tooltip>
            }
            when={!isActivePaidPlan(subscription)}
          >
            <Button
              variant='iconOnTopRound'
              icon={<Ionicon name='download' color='white' size='24' />}
              href={downloadVideoUrl}
              external
              disabled={!isActivePaidPlan(subscription)}
            >
              Download .mp4
            </Button>
          </Wrap>
        }
      </div>
    </Well>
  )
}

SharingBlock.propTypes = {
  company: PropTypes.object
}

export default SharingBlock
