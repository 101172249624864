import css from './Modal.module.sass'

import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'

import Button from './Button'
import Popup from './Popup'

const Modal = ({ modalOpened, variant = 'white', loading, title, buttons, noButtons, onClosed, children, ...rest }) => {
  const [closed, setClosed] = useState()

  const toggleFixeBody = (fixed) => {
    if (typeof modalOpened !== 'undefined') {
      const overflow = fixed ? "hidden" : "initial"
      document.body.style.overflow = overflow
    }
  }

  useEffect(() => {
    toggleFixeBody(modalOpened)
    return () => {
      toggleFixeBody(modalOpened)
    }
    // eslint-disable-next-line
  }, [modalOpened])

  const handleCloseClick = useCallback(() => {
    setClosed(true)
    onClosed && onClosed()
  }, [onClosed])

  if (closed) {
    return null
  }

  const defaultButtons = (
    <Button
      variant='primary'
      onClick={handleCloseClick}
    >
      OK
    </Button>
  )

  return (
    <Popup
      visible={typeof modalOpened === 'undefined' ? true : modalOpened}
      variant={variant}
      overlay
      loading={loading}
      header={title &&
        <div className={css[`${variant}-header`]}>
          {title}
        </div>
      }
      content={
        <div className={css[`${variant}-content`]}>
          {children}
        </div>
      }
      footer={
        !noButtons &&
        <div className={css[`${variant}-footer`]}>
          {buttons || defaultButtons}
        </div>
      }
      {...rest}
    />
  )
}

Modal.propTypes = {
  variant: PropTypes.string,
  loading: PropTypes.bool,
  modalOpened: PropTypes.bool,
  title: PropTypes.node,
  buttons: PropTypes.node,
  noButtons: PropTypes.bool,
  onClosed: PropTypes.func,
  children: PropTypes.node
}

export default Modal
