export const START_PLAN_NAME = 'start'
export const GROW_PLAN_NAME = 'grow'
export const SCALE_PLAN_NAME = 'scale'

export const PLAN_NAMES = {
  [START_PLAN_NAME]: 'Start',
  [GROW_PLAN_NAME]: 'Grow',
  [SCALE_PLAN_NAME]: 'Scale'
}
export const PLAN_ORDER = [ START_PLAN_NAME, GROW_PLAN_NAME, SCALE_PLAN_NAME ]

export const INCOMPLETE_STATUS = 'incomplete'
export const INCOMPLETE_EXPIRED_STATUS = 'incomplete_expired'
export const ACTIVE_STATUS = 'active'
export const PAST_DUE_STATUS = 'past_due'
export const CANCELED_STATUS = 'canceled'
export const UNPAID_STATUS = 'unpaid'

export const START_PLAN_PRICE = 0
export const GROW_PLAN_PRICE = 4900
export const SCALE_PLAN_PRICE = null

export const PLAN_PRICES = {
  [START_PLAN_NAME]: START_PLAN_PRICE,
  [GROW_PLAN_NAME]: GROW_PLAN_PRICE,
  [SCALE_PLAN_NAME]: SCALE_PLAN_PRICE
}
