import css from './Logo.module.sass'
import React from 'react'

import Button from './Button'
import Image from './Image'
import { LOGO } from "../constants/enterpriseConfig"

const Logo = () =>
  <Button variant={null} href='#' block>
    <Image fileName={LOGO} alt='Ignite' block className={css.logo} />
  </Button>

export default Logo
