export const VIDEOS_AMOUNT = 3
export const VIDEO_DURATION = 60

export const VIDEO_CREATED_STATUS = 'created'
export const VIDEO_STARTED_STATUS = 'started'
export const VIDEO_FINISHED_STATUS = 'finished'

export const WHAT_WE_ARE_DOING = 'what_we_are_doing'
export const WHO_WE_ARE = 'who_we_are'
export const WHY_WE_ARE_DOING_IT = 'why_we_are_doing_it'

export const VIDEO_TITLES = {
  [WHAT_WE_ARE_DOING]: "What We're Doing",
  [WHO_WE_ARE]: 'Who We Are',
  [WHY_WE_ARE_DOING_IT]: "Why We're Doing It"
}

export const VIDEO_ORDER = [WHO_WE_ARE, WHAT_WE_ARE_DOING, WHY_WE_ARE_DOING_IT]

export const PLAYING_STATE = 'playing'
export const PAUSED_STATE = 'paused'
export const STOPPED_STATE = 'stopped'

export const TOPICS = [
  {
    id: WHO_WE_ARE,
    title: VIDEO_TITLES[WHO_WE_ARE],
    segments: [
      {
        id: 'intro',
        title: 'Intro',
        question: 'What is your name, title, company, and background/relevant experience?',
        teleprompt: 'Enter your teleprompter script here...'
      },
      {
        id: 'team',
        title: 'Team',
        question: 'Who else is on your team and what stands out about them?',
        teleprompt: 'Enter your teleprompter script here...'
      },
      {
        id: 'expertise',
        title: 'Expertise',
        question: 'Why are you and your team the right people for this project?',
        teleprompt: 'Enter your teleprompter script here...'
      }
    ]
  },
  {
    id: WHAT_WE_ARE_DOING,
    title: VIDEO_TITLES[WHAT_WE_ARE_DOING],
    segments: [
      {
        id: 'problem',
        title: 'Problem',
        question: 'What is the problem or void in the market faced by your customers?',
        teleprompt: 'Enter your teleprompter script here...'
      },
      {
        id: 'solution',
        title: 'Solution',
        question: 'What is your solution to the problem or void in the market faced by your customers, and what sets your startup apart from the competition?',
        teleprompt: 'Enter your teleprompter script here...'
      }
    ]
  }
  , {
    id: WHY_WE_ARE_DOING_IT,
    title: VIDEO_TITLES[WHY_WE_ARE_DOING_IT],
    segments: [
      {
        id: 'ideation',
        title: 'Highlights',
        question: 'What are some other key points that you’d like to highlight (traction, market potential, etc.)?',
        teleprompt: 'Enter your teleprompter script here...'
      }
    ]
  }
]
