export const INVALID_TOKEN_CODE = 2
export const USER_UNAUTHORIZED_CODE = 3
export const INVALID_CREDENTIALS_CODE = 4
export const EMAIL_ALREADY_CONFIRMED_CODE = 22
export const EMAIL_CONFIRMED_CODE = 26
export const TOUR_ALREADY_COMPLETED_CODE = 125
export const COUPON_DOES_NOT_EXIST_CODE = 265

export const DOWN_KEY_CODE = 40
export const UP_KEY_CODE = 38
export const RIGHT_KEY_CODE = 39
export const LEFT_KEY_CODE = 37
export const ENTER_KEY_CODE = 13
export const SPACE_KEY_CODE = 32
export const ESC_KEY_CODE = 27
