import css from './FavoritesList.module.sass'

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { useMutation } from 'react-apollo'

import Alert from '../Alert'
import Ionicon from '../Ionicon'
import Link from '../Link'

import { formatGraphQLError } from '../../helpers/errors'
import { SAVED_MODULE_ENABLED } from '../../constants/enterpriseConfig'
import { TOUR_INVESTOR_SAVE_CLASS } from '../../constants/tour'

const FAVORITE = gql`
mutation favoriteCompany ($company: String!) {
  favoriteCompany(company: $company) {
    id
  }
}
`

const FavoritesList = ({ company }) => {
  const [isFavorite, setIsFavorite] = useState(false)

  const [favoriteCompany, { error: saveError }] = useMutation(FAVORITE, {
    variables: {
      company: company.id,
    }
  })

  if (saveError) {
    return <Alert variant="error">{formatGraphQLError(saveError)}</Alert>
  }

  const { favorite } = company

  if (!SAVED_MODULE_ENABLED) {
    return (<></>)
  }

  const handleSaveCompany = () => {
    favoriteCompany({ variables: { company: company.id } })
    setIsFavorite(true)
  }

  return (
    <Link
      variant='feedActions'
      className={TOUR_INVESTOR_SAVE_CLASS}
      color='white'
      disabled={favorite || isFavorite}
      icon={
        favorite || isFavorite
          ?
          <Ionicon name='archive_tick' color='white' size='16' />
          :
          <Ionicon name='favorites_add_white' color='white' size='16' />
      }
      onClick={() => handleSaveCompany()}
    >
      <span className={css.text}>
        &nbsp;Add to Favorites
      </span>
    </Link>
  )
}

FavoritesList.propTypes = {
  company: PropTypes.object
}

export default FavoritesList
