import css from './index.module.sass'

import React from 'react'
import PropTypes from 'prop-types'

import Asterisk from '../../Asterisk'
import Text from '../../Text'
import Well from '../../Well'
import Item from './Item'

import {
  DETAILS_SECTION, TEMPLATES_SECTION, VIDEO_SECTION,
  DECK_SECTION, HIGHLIGHTS_SECTION, TEAM_SECTION, SUBMIT_SECTION
} from '../../../constants/pitchform'
import { areCompanyDetailsComplete } from '../../../helpers/companies'
import { removeHtmlTags } from '../../../helpers/strings'

const ProgressBlock = ({ company, onSelect }) => {
  const ITEMS = [
    {
      section: DETAILS_SECTION,
      title: 'Basic Info',
      required: true,
      complete: areCompanyDetailsComplete(company)
    },
    {
      section: TEMPLATES_SECTION,
      title: 'Teleprompter Script',
      complete: Boolean(company.teleprompts?.length)
    },
    {
      section: VIDEO_SECTION,
      title: 'Pitch Video',
      required: true,
      complete: Boolean(company.concatenatedVideo)
    },
    {
      section: DECK_SECTION,
      title: 'Pitch Deck',
      complete: Boolean(company.slidesFile)
    },
    {
      section: TEAM_SECTION,
      title: 'Team Bios',
      complete: Boolean(company.members?.length)
    },
    {
      section: HIGHLIGHTS_SECTION,
      title: 'Key Highlights',
      complete: Boolean(removeHtmlTags(company.highlights))
    }
    ,
    {
      section: SUBMIT_SECTION,
      title: 'Submit Pitch',
      required: true,
      complete: Boolean(company.publishedAt)
    }
  ]

  return (
    <Well title={`Your Progress`}>
      <div className={css.container}>
        <Text tag='p'>
          <Asterisk /> <Text variant='standard' italic>Required</Text>
        </Text>

        <ul className={css.list}>
          {ITEMS.map((item, index) =>
            <Item
              key={index}
              index={index}
              {...item}
              onSelect={onSelect}
            />
          )}
        </ul>
      </div>
    </Well>
  )
}

ProgressBlock.propTypes = {
  company: PropTypes.object,
  onAdd: PropTypes.func,
  onSelect: PropTypes.func
}

export default ProgressBlock
