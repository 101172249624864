import css from './Asterisk.module.sass'

import React from 'react'

const Asterisk = () => {
  return (
    <span className={css.base}>*</span>
  )
}

export default Asterisk
