import css from './MenuMobile.module.sass'

import React, { useState, useCallback, useContext } from 'react'
import PropTypes from 'prop-types'

import { CSSTransition } from 'react-transition-group'

import Button from '../../Button'
import Link from '../../Link'

import CurrentUserContext from '../../CurrentUserContext'

import {
  PITCHTAPE_PATH, SETTINGS_PATH, SIGNOUT_PATH,
  FEED_PATH, PROFILE_PATH, FEED_LIST_PATH, CREATE_PATH, SAVED_PATH
} from '../../../constants/routes'
import {
  PRODUCT_NAME,
  VIDEO_REVIEWER_PROFILE_ENABLED,
  SAVED_MODULE_ENABLED
} from "../../../constants/enterpriseConfig"
import { matchPath, withRouter } from "react-router-dom"



const MenuMobile = ({ location }) => {
  const [opened, setOpened] = useState()
  const inSavedList = !(matchPath(location.pathname, { path: SAVED_PATH }) === null)

  const { currentUser: { accountType } } = useContext(CurrentUserContext)

  const handleHamburgerClick = useCallback(() => {
    setOpened(x => !x)
  }, [])

  const handleLinkClick = useCallback(() => {
    setOpened(false)
  }, [])

  return (
    <div className={css.container}>
      <Button
        className={opened ? css.btnHamburgerOpened : css.btnHamburger}
        variant='icon'
        onClick={handleHamburgerClick}
      >
        <i className={css.line1} />
        <i className={css.line2} />
        <i className={css.line3} />

      </Button>

      <div className={css.menuContainer}>
        <CSSTransition
          in={opened}
          timeout={200}
          unmountOnExit
          classNames={{
            appear: css[`menu-appear`],
            appearActive: css[`menu-appear-active`],
            appearDone: css[`menu-done-appear`],
            enter: css[`menu-enter`],
            enterActive: css[`menu-enter-active`],
            enterDone: css[`menu-done-enter`],
            exit: css[`menu-exit`],
            exitActive: css[`menu-exit-active`],
            exitDone: css[`menu-done-exit`]
          }}
        >
          <ul className={css.menu}>
            {accountType === 'founder' &&
              <>
                <li className={css.menuItem}>
                  <Link className={css.menuLink} to={PITCHTAPE_PATH} onClick={handleLinkClick}>
                    My {PRODUCT_NAME} Profile
                  </Link>
                </li>
                <li className={css.menuItem}>
                  <Link className={css.menuLink} to={CREATE_PATH} onClick={handleLinkClick}>
                    Edit {PRODUCT_NAME} Profile
                  </Link>
                </li>

                <li className={css.menuItem}>
                  <Link className={css.menuLink} to={SETTINGS_PATH} onClick={handleLinkClick}>Settings</Link>
                </li>

                <li className={css.menuItem}>
                  <Link className={css.menuLink} to={SIGNOUT_PATH} onClick={handleLinkClick}>Log Out</Link>
                </li>
              </>
            }

            {accountType === 'investor' &&
              <>
                <li className={css.menuItem}>
                  <Link className={css.menuLink} to={FEED_PATH} onClick={handleLinkClick}>
                    Feed
                  </Link>
                </li>
                <li className={css.menuItem}>
                  <Link className={css.menuLink} to={FEED_LIST_PATH} onClick={handleLinkClick}>
                    List
                  </Link>
                </li>

                {SAVED_MODULE_ENABLED &&
                  <li className={css.menuItem}>
                    <Link
                      className={css.menuLink}
                      variant="mainnav"
                      nav
                      to={SAVED_PATH}
                      active={inSavedList}
                      activeClassName={inSavedList ? css.NavActive : ''}
                    >
                      Favorites{' '}
                    </Link>
                  </li>
                }
                {VIDEO_REVIEWER_PROFILE_ENABLED &&
                  <li className={css.menuItem}>
                    <Link className={css.menuLink} to={PROFILE_PATH} onClick={handleLinkClick}>
                      My Profile
                    </Link>
                  </li>
                }

                <li className={css.menuItem}>
                  <Link className={css.menuLink} to={SETTINGS_PATH} onClick={handleLinkClick}>
                    Settings
                  </Link>
                </li>
                <li className={css.menuItem}>
                  <Link className={css.menuLink} to={SIGNOUT_PATH} onClick={handleLinkClick}>Log Out</Link>
                </li>
              </>
            }
          </ul>
        </CSSTransition>
      </div>
    </div>
  )
}

MenuMobile.propTypes = {
  location: PropTypes.object
}

export default withRouter(MenuMobile)

