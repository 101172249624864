import css from './Highlights.module.sass'

import React from 'react'
import PropTypes from 'prop-types'

import SectionHeading from './SectionHeading'

const Highlights = ({ company }) => {
  return (
    <div className={css.container}>
      <section className={css.content}>
        <SectionHeading icon='highlights_black'>
          Highlights
        </SectionHeading>

        <article dangerouslySetInnerHTML={{ __html: company.highlights }} />
      </section>
    </div>
  )
}

Highlights.propTypes = {
  company: PropTypes.object
}

export default Highlights
