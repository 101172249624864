
import css from './Rating.module.sass'

import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'

import RatingModal from '../RatingModal'
import Ionicon from '../Ionicon'
import Link from '../Link'

import { TOUR_INVESTOR_RATE_CLASS } from '../../constants/tour'

const Rating = ({ company, onRate }) => {
  const [temporaryRating, setTemporaryRating] = useState(0)
  const { givenRating1 } = company

  const handleRatingModalClose = useCallback(() => {
    setTemporaryRating(0)
  }, [])

  const handleRatingClick = useCallback(() => {
    const giveRating = givenRating1 ? givenRating1 : 1
    setTemporaryRating(giveRating)
  }, [givenRating1])

  const textRating = givenRating1 ? 'Edit Rating' : 'Submit Rating'

  return (
    <>
      {
        <Link
          variant={givenRating1 ? 'feedActionsFilled' : 'feedActions'}
          color='white'
          className={TOUR_INVESTOR_RATE_CLASS}
          icon={
            givenRating1 ?
              <Ionicon name='star' color='green' size='22' />
              :
              <Ionicon name='star_white' color='white' size='16' />
          }
          onClick={handleRatingClick}
        >
          <span className={css.text}>
            &nbsp;{textRating}
          </span>
        </Link>
      }

      {temporaryRating > 0 &&
        <RatingModal
          company={company}
          defaultOverall={givenRating1 && temporaryRating}
          onRate={onRate}
          onClose={handleRatingModalClose}
        />
      }
    </>
  )
}

Rating.propTypes = {
  company: PropTypes.object,
  onRate: PropTypes.func
}

export default Rating
