import css from './NotFoundScreen.module.sass'

import React from 'react'

import Layout from '../components/Layout'
import Button from '../components/Button'
import Text from '../components/Text'

import * as routes from '../constants/routes'

const NotFoundScreen = () => {
  return (
    <Layout variant='centered'>
      <div className={css.container}>
        <Text tag='p' variant='h3'>Oops! Page not found.</Text>

        <div className={css.button}>
          <Button to={routes.HOME_PATH} variant='primary'>Go To Home page</Button>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundScreen
