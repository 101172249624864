import React from 'react'
import Text from "../Text"
import Link from "../Link"

const CameraError = () => {
    return (
        <>
            <Text tag='p' variant='standardLarger' offset='single-top'>
                There was an issue initializing your camera.
                To initiate the recorder, allow Pitchtape to access your camera and microphone.
            </Text>
            <Text tag='p' variant='standardLarger' offset='single-top'>
                Ensure your camera and microphone are WebRTC compatible
                by visiting <Link external href='https://www.webrtc-experiment.com/DetectRTC/ '>https://www.webrtc-experiment.com/DetectRTC/ </Link>
            </Text>
            <Text tag='p' variant='standardLarger' offset='single-top'>
                For additional help, please visit our <Link external href='https://www.pitchtape.com/faq'>FAQ</Link>
                &nbsp;page
            </Text>
        </>
    )
}

CameraError.propTypes = {}

export default CameraError