import { isProdEnv } from './env'
import { MILLISECONDS_IN_A_YEAR } from '../constants/dates'

export function gtmTrack (event, obj) {
  window.dataLayer = window.dataLayer || []

  window.dataLayer.push({
    event,
    ...obj
  })
}

let gtmUserId

export function gtmIdentify (userId) {
  if (gtmUserId !== userId) {
    gtmUserId = userId

    const expires = new Date()
    expires.setTime(expires.getTime() + MILLISECONDS_IN_A_YEAR)

    document.cookie = `userId=${userId}; expires=${expires}; path=/`
  }
}

export function gtmUnidentify () {
  gtmUserId = void 0
  document.cookie = `userId=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`
}

export let fsIdentify = () => {}
export let fsUnidentify = () => {}

if (isProdEnv()) {
  let fsUserId

  fsIdentify = ({ id, firstName, lastName, email, accountType, subscription }) => {
    if (fsUserId !== id) {
      fsUserId = id

      window.FS && window.FS.identify(id, {
        displayName: `${firstName} ${lastName}`,
        email,
        accountType,
        ...(subscription && { plan: subscription.plan })
      })
    }
  }

  fsUnidentify = () => {
    fsUserId = void 0
  }
}
