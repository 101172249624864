
import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import Mobile from './Mobile'
import Desktop from './Desktop'

import DevicesContext from '../DevicesContext'

const VideosRecorder = ({
  topics, topic, segment, previousSegmentUrl, nextSegmentUrl, practice, saving, exitUrl,
  renderPromptModal, onBeforeSave, onSave, onStitch,
  history
}) => {
  const { mobileUa } = useContext(DevicesContext)
  const Component = mobileUa ? Mobile : Desktop

  return (
    <>
      <Component
        topics={topics}
        topic={topic}
        segment={segment}
        previousSegmentUrl={previousSegmentUrl}
        nextSegmentUrl={nextSegmentUrl}
        practice={practice}
        saving={saving}
        exitUrl={exitUrl}
        renderPromptModal={renderPromptModal}
        onBeforeSave={onBeforeSave}
        onSave={onSave}
        onStitch={onStitch}
        history={history} />
    </>)
}

VideosRecorder.propTypes = {
  topics: PropTypes.array,
  topic: PropTypes.string,
  segment: PropTypes.string,
  previousSegmentUrl: PropTypes.string,
  nextSegmentUrl: PropTypes.string,
  practice: PropTypes.bool,
  saving: PropTypes.bool,
  exitUrl: PropTypes.string,
  renderPromptModal: PropTypes.func,
  onBeforeSave: PropTypes.func,
  onSave: PropTypes.func,
  onStitch: PropTypes.func,

  history: PropTypes.object
}

export default withRouter(VideosRecorder)
