export const DETAILS_SECTION = 'DETAILS_SECTION'
export const TEMPLATES_SECTION = 'TEMPLATES_SECTION'
export const VIDEO_SECTION = 'VIDEO_SECTION'
export const VIDEO_PREVIEW_SECTION = 'VIDEO_PREVIEW_SECTION'
export const DECK_SECTION = 'DECK_SECTION'
export const HIGHLIGHTS_SECTION = 'HIGHLIGHTS_SECTION'
export const TEAM_SECTION = 'TEAM_SECTION'
export const SUBMIT_SECTION = 'SUBMIT_SECTION'

export const EXAMPLE_ID = '2d9989e5-fe5c-44de-af1a-c3c420a1b2b2'
