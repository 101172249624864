import css from './Segments.module.sass'

import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Button from '../Button'
import Ionicon from '../Ionicon'
import Text from '../Text'

import { formatSecondsAsTime } from '../../helpers/dates'

const Segments = ({ segments, currentIndex, onSelect }) => {
  const [ collapsed, setCollapsed ] = useState(false)

  return (
    <div className={css.container}>
      <Button variant='segmentCollapse' active={collapsed} onClick={() => setCollapsed(!collapsed)}>
        {collapsed
          ? <>
            <Text variant='standardLarger' color='white'>Video Bookmarks</Text>
            <Ionicon name='arrowDropup' color='white' size='24' />
          </>
          : <Ionicon name='arrowDropdown' color='white' size='24' />
        }
      </Button>

      {!collapsed &&
        <ul className={css.list}>
          {segments.map((segment, index) =>
            <li key={segment.id} className={css.listItem}>
              <Button
                variant='segment'
                active={currentIndex === index}
                onClick={() => onSelect(index)}
              >
                <span>
                  {segment.title}
                </span>

                {Boolean(segment.duration) &&
                  <Text opacity='50' className={css.duration}>
                    {formatSecondsAsTime(segment.duration)}
                  </Text>
                }
              </Button>
            </li>
          )}
        </ul>
      }
    </div>
  )
}

Segments.propTypes = {
  segments: PropTypes.array,
  currentIndex: PropTypes.number,
  onSelect: PropTypes.func
}

export default Segments
