import PropTypes from "prop-types"
import React from "react"

import { formatProperty } from "../PitchtapeReview/CompanyDetails"
import cssFeed from "../../screens/FeedListScreen.module.sass"
import Link from "../Link"
import { toWithParams } from "../../helpers/routes"
import { STARTUP_PATH } from "../../constants/routes"
import Ionicon from "../Ionicon"
import Moment from 'react-moment'
import DeleteSavedCompany from "../ConnectionList/DeleteSavedCompany"
import Tooltip from "../Tooltip"
import { STAGEOFBUSINESS, TEAMSIZE } from '../../constants/otherInfo'

export const renderRated = (company) => {
  if (company.givenRating !== -1) {
    return (<Tooltip hoverable content={'yes'} ><Ionicon name='checkmark' color='green' size='24' /></Tooltip>)
  } else {
    return (<Tooltip hoverable content={'no'}><Ionicon name='close' color='nba' size='24' /></Tooltip>)
  }
}

const CompanyItem = ({ index, connectionId, company, dateSaved, deleteCallBack }) => {

  const calculateGivenRate = () => {
    if (company.givenRating1 === 0)
      return 0.0
    else if (company.givenRating3 === 0)
      return 0.0
    else if (company.givenRating2 === 0)
      return 0.0
    else if (company.givenRating4 === 0)
      return 0.0
    else {
      return (
        (company.givenRating1 +
          company.givenRating3 +
          company.givenRating2 +
          company.givenRating4
        ) / 4
      ).toFixed(2)
    }
  }

  const renderRated = () => {
    if (company.givenRating1) {
      return (<Tooltip hoverable content={'yes'} ><Ionicon name='checkmark' color='green' size='24' /></Tooltip>)
    } else {
      return (<Tooltip hoverable content={'no'}><Ionicon name='close' color='nba' size='24' /></Tooltip>)
    }
  }

  const itemId = index + 1

  const stageOfBusiness = STAGEOFBUSINESS.find(el => el.value === company.stageOfBusiness)
  const teamSize = TEAMSIZE.find(el => el.value === company.teamSize)
  const industries = company?.industries.map((elem) => elem.name).join(', ')

  return (
    <tr className={index % 2 ? cssFeed.odd : cssFeed.even}>
      <td>
        {itemId}
      </td>
      <td>
        {company.name}
      </td>
      <td>
        {company.description}
      </td>
      <td>
        {stageOfBusiness.label}
      </td>
      <td>
        {teamSize.label}
      </td>
      <td>
        {industries}
      </td>
      <td className={cssFeed.buttonAction}>
        <Link to={toWithParams(STARTUP_PATH, { companyId: company.id })}>
          <Ionicon name='pitchtapeIcon' size='24' />
        </Link>
      </td>

      <td className={cssFeed.buttonAction}>
        {renderRated()}
      </td>
      <td>
        {calculateGivenRate()}
      </td>
      <td>
        {formatProperty(company.givenRatingText)}
      </td>
      <td>
        <div
          dangerouslySetInnerHTML={{
            __html: company.givenNote
          }}
        />
      </td>
      <td>
        {/* Using international standard for datetime format */}
        <Moment format="YYYY-MM-DD HH:mm:ss ">
          {dateSaved}
        </Moment>
      </td>
      <td>
        <DeleteSavedCompany connectionId={connectionId} deleteCallBack={deleteCallBack} />
      </td>
    </tr>
  )
}

CompanyItem.propTypes = {
  index: PropTypes.number,
  company: PropTypes.object,
  connectionId: PropTypes.string,
  dateSaved: PropTypes.string,
  deleteCallBack: PropTypes.func
}

export default CompanyItem
