import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { Form, FormRow, FormCol, EmailField } from './Form'
import Button from './Button'
import Alert from './Alert'

import { formatGraphQLError } from '../helpers/errors'
import { GTM_FORM_FORGOT_PASSWORD } from '../constants/gtm'

class ForgotPasswordForm extends PureComponent {
  static propTypes = {
    error: PropTypes.object,
    loading: PropTypes.bool,
    onSent: PropTypes.func
  }

  state = {
    formData: {
      email: ''
    },
    valid: false
  }

  handleFieldUpdate = (patch) => {
    this.setState({
      formData: {
        ...this.state.formData,
        ...patch
      }
    })
  }

  render () {
    const { error, loading } = this.props
    const { formData, valid } = this.state

    return (
      <Form
        gtm={GTM_FORM_FORGOT_PASSWORD}
        onSubmit={(event) => {
          event.preventDefault()

          if (valid && !loading) {
            this.props.onSent(this.state.formData.email)
          }
        }}
        onValid={() => this.setState({ valid: true })}
        onInvalid={() => this.setState({ valid: false })}
      >
        {error && <Alert variant='error'>{formatGraphQLError(error)}</Alert>}

        <FormRow>
          <FormCol>
            <EmailField
              label='Email Address'
              name='email'
              value={formData.email}
              required
              onUpdate={this.handleFieldUpdate}
            />
          </FormCol>
        </FormRow>

        <FormRow offset='35'>
          <FormCol>
            <Button variant='primary' type='submit' block disabled={loading}>
              Reset Password
            </Button>
          </FormCol>
        </FormRow>
      </Form>
    )
  }
}

export default ForgotPasswordForm
