import PropTypes from 'prop-types'

const Wrap = ({ inOneOf, in: inRender, when, children }) => {
  if (inOneOf) {
    for (const { in: inRender, when } of inOneOf) {
      if (when) {
        return inRender(children)
      }
    }
  }

  if (when) {
    return inRender(children)
  }

  return children
}

Wrap.propTypes = {
  inOneOf: PropTypes.array,
  in: PropTypes.func,
  when: PropTypes.bool,
  children: PropTypes.node
}

export default Wrap
