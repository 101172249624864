import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Button from '../Button'
import Ionicon from '../Ionicon'
import SectionTitle from '../SectionTitle'
import Text from '../Text'

import { CREATE_VIDEOS_PATH } from '../../constants/routes'
import ExamplePitchtapeLink from "../ExamplePitchVideo"
import { SHOW_EXAMPLES } from "../../constants/enterpriseConfig"

const VideoSection = ({ company }) => {
  const [supportedBrowser] = useState(Boolean(window.MediaRecorder && navigator.mediaDevices))

  const { concatenatedVideo } = company

  return (
    <section>
      <SectionTitle
        required
        title="3. Pitch Video"
        nextToTitle={
          (SHOW_EXAMPLES ? <Text>
            <ExamplePitchtapeLink
              linkText="See example"
              section="video"
            >
            </ExamplePitchtapeLink>
          </Text> : '')
        }
      />

      <div>
        {
          <Text tag='p' italic offset='triple-bottom' weight='500'>
            <Text tag='span' color='red' weight='500'>
              *&nbsp;
            </Text>
            Important: Please use Google Chrome or Microsoft Edge to record.
          </Text>
        }
        <Button
          to={CREATE_VIDEOS_PATH}
          variant='primary'
          icon={
            <Ionicon
              name={!concatenatedVideo ? 'videocam' : 'create'}
              size='24'
              color={supportedBrowser ? 'white' : 'gray'}
            />
          }
          disabled={!supportedBrowser}
        >
          {!concatenatedVideo ? 'Record' : 'Edit'} video
        </Button>
      </div>




    </section>
  )
}

VideoSection.propTypes = {
  company: PropTypes.object
}

export default VideoSection
