import React from 'react'
import PropTypes from 'prop-types'

import Layout from '../components/Layout'
import Text from '../components/Text'
import InvestorProfileFormLinked from '../components/InvestorProfileFormLinked'

import { FEED_PATH } from '../constants/routes'
import { GTM_INVESTOR_PROFILE_CREATED_EVENT } from '../constants/gtm'
import { gtmTrack } from '../helpers/tracking'

const SignUpInvestorProfileScreen = ({ history }) =>
  <Layout variant='form'>
    <Text variant='h3' tag='h1' offset='double-bottom' centered>Create Your Investor Profile</Text>

    <InvestorProfileFormLinked
      submitButtonText='Continue'
      onSuccess={() => {
        gtmTrack(GTM_INVESTOR_PROFILE_CREATED_EVENT)
        history.push(FEED_PATH, { investorProfileCreated: true })
      }}
    />
  </Layout>

SignUpInvestorProfileScreen.propTypes = {
  history: PropTypes.object
}

export default SignUpInvestorProfileScreen
