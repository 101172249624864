import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { graphql } from 'react-apollo'

import Dropdown from './Dropdown'

const GET_ORGANIZATION_SIZES = gql`
  query getOrganizationSizes {
    sizes: getOrganizationSizes {
      id
      name
    }
  }
`

const OrganizationSizeDropdown = ({ data, ...dropdownProps }) => {
  const options = useMemo(() => {
    return data.sizes && data.sizes.map(x => ({ value: x, label: x.name }))
  }, [ data.sizes ])

  return (
    <Dropdown
      options={options}
      valueIdKey='id'
      renderLabel={value => value.name}
      {...dropdownProps}
    />
  )
}

OrganizationSizeDropdown.propTypes = {
  data: PropTypes.object
}

export default graphql(GET_ORGANIZATION_SIZES)(OrganizationSizeDropdown)
