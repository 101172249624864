import css from './Pagination.module.sass'
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Button from '../Button'
import Ionicon from '../Ionicon'

import { TOUR_INVESTOR_PREV_NEXT_CLASS } from '../../constants/tour'


const Pagination = ({ hasPrevious, hasNext, onPrevious, onNext, forInvestor, navigationButtons = true, isMobile }) => {
  return (
    <div className={classNames(css.buttons, TOUR_INVESTOR_PREV_NEXT_CLASS)}>
      {forInvestor && navigationButtons &&
        <>
          <Button
            className={css.previous}
            variant='pagination'
            icon={
              <Ionicon
                name='chevronBack'
                stroke='deepBlue'
                strokeWidth='32'
                size={!isMobile ? '24' : '16'} />
            }
            disabled={!hasPrevious}
            onClick={onPrevious}
          >
            <span>
              Previous
            </span>
          </Button>
          <Button
            className={css.next}
            variant='pagination'
            disabled={!hasNext}
            onClick={onNext}
          >
            <span>
              Next
            </span>
            <Ionicon
              name='chevronForward'
              stroke='deepBlue'
              strokeWidth='32'
              size={!isMobile ? '24' : '16'} />
          </Button>
        </>
      }
    </div>
  )
}

Pagination.propTypes = {
  forInvestor: PropTypes.bool,
  hasPrevious: PropTypes.bool,
  hasNext: PropTypes.bool,
  onPrevious: PropTypes.func,
  onNext: PropTypes.func,
  navigationButtons: PropTypes.bool,
  isMobile: PropTypes.bool,
}

export default Pagination