import css from './Header.module.sass'

import React, { forwardRef, useState, useLayoutEffect, useRef, useContext, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import gql from 'graphql-tag'
import { useQuery, useMutation } from 'react-apollo'

import Button from '../Button'
import CompanyDetails from './CompanyDetails'
import CompanyUrlCopier from '../CompanyUrlCopier'
import Ionicon from '../Ionicon'

import Rating from './Rating'
import Link from '../Link'
import Modal from '../Modal'
import Text from '../Text'

import DevicesContext from '../DevicesContext'
import CurrentUserContext from '../CurrentUserContext'
import { EditorField, FormFooter } from '../Form'

import { CREATE_PATH } from '../../constants/routes'
import { isActivePaidPlan } from '../../helpers/subscription'
import { useDownloadVideoUrl } from '../../helpers/hooks'
import { TOUR_INVESTOR_PREV_NEXT_CLASS, TOUR_INVESTOR_SHARE_CLASS, TOUR_INVESTOR_MORE_CLASS } from '../../constants/tour'

import MoreActionFeed from './MoreActionFeed'
import FavoritesList from './FavoritesList'

const GET_INVESTOR_COMPANY_NOTE = gql`
  query getInvestorCompanyNote ($company: UUID) {
    getInvestorCompanyNote(company: $company) {
        id
        note
        createdAt
        updatedAt
    }
  }
`

const NOTES_COMPANY = gql`
  mutation saveInvestorCompanyNote ($company: String!, $note: String) {
    saveInvestorCompanyNote(input: {company: $company, note: $note}) {
      obj {
          id
          note
          createdAt
          updatedAt
      }
      errors {
          field
          messages
      }
    }
  }
`


const Header = forwardRef((
  {
    variant = 'base', company, editable, shareable, submitButton, sticky, forInvestor, pageMaxWidth, hasPrevious,
    hasNext, onPrevious, onNext, onRate, navigationButtons = true
  },
  ref
) => {
  const [showModalTakeNotes, setShowModalTakeNotes] = useState(false)
  const [showMoreActions, setShowMoreActions] = useState(false)
  const [floatingWidth, setFloatingWidth] = useState()
  const { mobileUa, mobile } = useContext(DevicesContext)
  const { currentUser, token } = useContext(CurrentUserContext)
  const isAnonymousUser = !token
  const { subscription } = currentUser


  const containerRef = useRef()

  const [note, setNote] = useState(null)
  const { data: notesData } = useQuery(GET_INVESTOR_COMPANY_NOTE, {
    variables: { company: company.id },
    skip: isAnonymousUser
  })


  //notes
  useEffect(() => {
    if (notesData?.getInvestorCompanyNote) {
      setNote(notesData.getInvestorCompanyNote.note)
    }
  }, [notesData])

  const updateNotes = (obj) => {
    setNote(obj.note)
  }

  const [saveInvestorCompanyNote, { savingNotes }] = useMutation(NOTES_COMPANY, {
    variables: {
      company: company.id,
      note
    },
    onCompleted: ({ saveInvestorCompanyNote }) => {
      if (notesData) {
        if (saveInvestorCompanyNote.errors) {
          return console.error(saveInvestorCompanyNote.errors)
        }
      }
      setShowModalTakeNotes(false)
    },
  })

  const handleSubmitNote = useCallback(() => {
    //event.preventDefault()
    saveInvestorCompanyNote()
  }, [saveInvestorCompanyNote])

  //end notes

  useLayoutEffect(() => {
    if (!sticky) {
      setFloatingWidth(null)
      return
    }

    const handleResize = () => {
      setFloatingWidth(containerRef.current.clientWidth)
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [sticky, pageMaxWidth])

  useEffect(() => {
    const handleClickOutsideBox = (event) => {
      const box = document.getElementById('more-action-feed');
      if (box && !box.contains(event.target) && showMoreActions) {
        setShowMoreActions(false)
      }
    }
    document.addEventListener('click', handleClickOutsideBox)
    return () => {
      document.removeEventListener('click', handleClickOutsideBox)
    }
  }, [showMoreActions])

  const downloadVideoUrl = useDownloadVideoUrl(company)

  const downloadable =
    downloadVideoUrl && (
      currentUser.isSuperuser ||
      (currentUser.id === Number(company.createdBy.id) && isActivePaidPlan(subscription))
    )

  const renderTakeNotes = () => {
    setShowModalTakeNotes(true)
  }


  const handleCloseModal = () => {
    setNote(notesData.getInvestorCompanyNote.note)
    setShowModalTakeNotes(false)
  }

  return (
    <div className={css[variant]} ref={containerRef}>
      <div
        className={sticky ? css.headerFloating : css.header}
        style={sticky ? { width: floatingWidth } : void 0}
        ref={ref}
      >
        <header className={classNames(css.headerInner, { [css.headerInnerCenter]: mobileUa })}>
          {!mobile &&
            <div className={css.details}>
              <CompanyDetails company={company} />
            </div>
          }
          <div className={css.containerButtonsAction}>
            <div className={css.ratingAndButtons}>
              {forInvestor && //!mobile && !mobileUa &&
                <>
                  <Rating company={company} onRate={onRate} />
                  <FavoritesList company={company} />
                  <Link
                    variant='feedActions'
                    className={TOUR_INVESTOR_MORE_CLASS}
                    color='white'
                    icon={
                      <Ionicon name='more_white' color='white' size='16' />
                    }
                    onClick={() => setShowMoreActions(!showMoreActions)}
                  >
                    <span className={css.textMore}>
                      &nbsp;More
                    </span>
                  </Link>

                </>
              }
            </div>
            {showMoreActions &&
              <MoreActionFeed
                shareable={shareable}
                company={company}
                renderTakeNotes={isAnonymousUser ? {} : renderTakeNotes}
              />
            }
          </div>
          {
            !forInvestor &&
            <div className={`${css.buttons} `} >
              {downloadable &&
                <Button
                  variant='feedAction'
                  icon={
                    <Ionicon name='download' color='white' size='24' />
                  }
                  href={downloadVideoUrl}
                  external
                >
                  Download
                </Button>
              }

              {editable &&
                <Button
                  variant='feedAction'
                  icon={
                    <Ionicon name='create' color='white' size='24' />
                  }
                  to={CREATE_PATH}
                >
                  Edit
                </Button>
              }

              {shareable &&
                <CompanyUrlCopier company={company}>
                  <Button
                    variant='feedActionPrimary'
                    icon={
                      <Ionicon name='share' color='white' size='24' style={{ left: -2 }} />
                    }
                  >
                    <span className={TOUR_INVESTOR_SHARE_CLASS}>
                      Share
                    </span>
                  </Button>
                </CompanyUrlCopier>
              }

              {submitButton &&
                submitButton
              }

              {forInvestor && navigationButtons &&
                <>
                  <div className={css.prevNext}>
                    <Button
                      variant='feedActionTransparent'
                      icon={
                        <Ionicon name='chevronBack' stroke='white' strokeWidth='32' size='48' />
                      }
                      disabled={!hasPrevious}
                      onClick={onPrevious}
                    >
                      <span>
                        Previous
                      </span>
                    </Button>
                    <span className={TOUR_INVESTOR_PREV_NEXT_CLASS}>
                    </span>
                    <Button
                      variant='feedActionTransparent'
                      className={css.btnNext}
                      icon={
                        <Ionicon name='chevronForward' stroke='white' strokeWidth='32' size='48' />
                      }
                      disabled={!hasNext}
                      onClick={onNext}
                    >
                      Next
                    </Button>
                  </div>
                </>
              }
            </div>
          }
        </header>
      </div>
      {
        showModalTakeNotes &&
        <Modal
          variant='welcome'
          noButtons
          title={<>
            <Text tag='h1' variant='h2' color='white'>Take Notes on {company.name}</Text>
            <Button variant="icon" className={css.btnClose} onClick={() => handleCloseModal()}>
              <Ionicon name="close" size="32" color="white" />
            </Button>
          </>
          }
        >
          <div className={css.content}>
            <Text offset='double-bottom' italic>
              Consider this your personal notepad where you can jot down notes about this pitch. Add headers, bullets, and more to organize your notes. These notes are private to you.
            </Text>
            <EditorField
              name='note'
              value={note}
              onUpdate={updateNotes}
            />
            <FormFooter >
              <Button type='submit' variant='outline' onClick={() => handleCloseModal()}>Cancel</Button>
              <Button type='submit' variant='primary' disabled={savingNotes} onClick={() => handleSubmitNote()}
              >Save</Button>
            </FormFooter>
          </div>
        </Modal>
      }
    </div>
  )
})

Header.displayName = 'Header'

Header.propTypes = {
  variant: PropTypes.string,
  company: PropTypes.object,
  editable: PropTypes.bool,
  shareable: PropTypes.bool,
  submitButton: PropTypes.node,
  sticky: PropTypes.bool,
  forInvestor: PropTypes.bool,
  pageMaxWidth: PropTypes.number,
  hasPrevious: PropTypes.bool,
  hasNext: PropTypes.bool,
  onInvestorAction: PropTypes.func,
  onPrevious: PropTypes.func,
  onNext: PropTypes.func,
  onRate: PropTypes.func,
  navigationButtons: PropTypes.bool,
  history: PropTypes.object,
}

export default Header
