import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { graphql } from 'react-apollo'

import Dropdown from './Dropdown'

const GET_NUMBER_OF_INVESTMENTS = gql`
  query getNumberOfInvestments {
    numbers: getNumberOfInvestments {
      id
      name
    }
  }
`

const NumberOfInvestmentsDropdown = ({ data, ...dropdownProps }) => {
  const options = useMemo(() => {
    return data.numbers && data.numbers.map(x => ({ value: x, label: x.name }))
  }, [ data.numbers ])

  return (
    <Dropdown
      options={options}
      valueIdKey='id'
      renderLabel={value => value.name}
      {...dropdownProps}
    />
  )
}

NumberOfInvestmentsDropdown.propTypes = {
  data: PropTypes.object
}

export default graphql(GET_NUMBER_OF_INVESTMENTS)(NumberOfInvestmentsDropdown)
