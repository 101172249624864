import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

const Thumbnail = ({ video }) => {
  const src = useMemo(() => {
    return video.binary || URL.createObjectURL(video)
  }, [ video ])

  return (
    <video width="80" src={src} />
  )
}

Thumbnail.propTypes = {
  video: PropTypes.object
}

export default Thumbnail
