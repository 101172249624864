import React, { forwardRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'

import DialogAnimated from './DialogAnimated'
import Dialog from './Dialog'

const Popup = forwardRef((props, ref) => {
  const [ portal, setPortal ] = useState()

  useEffect(() => {
    let portal = document.getElementById('popup-portal')

    if (!portal) {
      portal = document.createElement('div')
      portal.id = 'popup-portal'
      document.body.appendChild(portal)
    }

    setPortal(portal)
  }, [])

  if (portal) {
    return ReactDOM.createPortal(
      props.animated
        ? <DialogAnimated ref={ref} {...props} />
        : props.visible ? <Dialog ref={ref} {...props} /> : null
      ,
      portal
    )
  }

  return null
})

Popup.displayName = 'Popup'

Popup.propTypes = {
  animated: PropTypes.string,
  visible: PropTypes.bool
}

export default Popup
