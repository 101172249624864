import css from './FieldError.module.sass'

import React from 'react'
import PropTypes from 'prop-types'

import Text from '../Text'

import { messages } from '../../constants/errors'

const FieldError = ({ error, overrides, isErrorVisible }) => {
  if (!error || !isErrorVisible) {
    return null
  }

  const errors = { ...messages, ...overrides }

  const renderText = () => {
    if (typeof error === 'string') {
      return errors[error] || error
    }

    const [ type, ...params ] = error
    let text = errors[type] || type

    if (params.length) {
      for (let i = 0; i < params.length; i++) {
        text = text.replace(`{${i}}`, params[i])
      }
    }

    return text
  }

  return (
    <div className={css.error}>
      <Text tag='p' variant='small' color='red'>
        {renderText()}
      </Text>
    </div>
  )
}

FieldError.propTypes = {
  error: PropTypes.oneOfType([ PropTypes.array, PropTypes.string ]),
  overrides: PropTypes.object,
  isErrorVisible: PropTypes.bool
}

export default FieldError
