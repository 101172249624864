import css from './PoweredBy.module.sass'

import React from 'react'

import Image from './Image'
import Link from './Link'
import Text from './Text'

const PoweredBy = () => {
  return (
    <Link external href='https://pitchtape.com' className={css.container}>
      <Text tag='div' color='gray' offset='quarter-bottom'>Powered by</Text>
      <div><Image fileName='logo.svg' width='127' alt='Pitchtape' block /></div>
    </Link>
  )
}

export default PoweredBy
