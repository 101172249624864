export const notEmptyKeys = (obj, _keys) => {
  const keys = _keys || Object.keys(obj)

  return keys.filter(key => {
    const field = obj[key]
    if (Array.isArray(field)) {
      return field.length
    } else {
      return Boolean(field)
    }
  })
}

export const isAllPresent = (obj, keys) => {
  return notEmptyKeys(obj, keys).length === keys.length
}
