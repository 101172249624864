export function durationToSeconds (duration) {
  const match = duration && duration.match(/(\d+):(\d\d):(\d\d\.\d+)/)

  if (!match) {
    return 0
  }

  const hours = Number(match[1])
  const minutes = Number(match[2])
  const seconds = Number(match[3])

  return seconds + minutes * 60 + hours * 3600
}
