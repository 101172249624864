import css from "./Search.module.sass"
import React from "react"
import PropTypes from "prop-types"

import { TextField } from './Form'
import Ionicon from '../components/Ionicon'

const Search = ({ term = '', readOnly = false, onChange, onClear }) => {
  const onEditClick = () => {
    window.history.back();
  };

  return (
      <div className={css.main}>
        <div className="search-main-wrapper">
          <div className={`icon-wrapper ${css.searchIcon}`}>
            <Ionicon name='search_normal' size='16' />
          </div>
          <TextField
              placeholder='Search...'
              name='data'
              value={term}
              readOnly={readOnly}
              onUpdate={({ data }) => onChange(data)}
          />
          {(term !== '' && readOnly) &&
              <div className={`icon-wrapper ${css.editIcon}`} onClick={onEditClick}>
                <Ionicon name='create' size='18' />
              </div>
          }
          {term !== '' &&
              <div className={`icon-wrapper ${css.closeIcon}`} onClick={onClear}>
                <Ionicon name='close' size='18' />
              </div>
          }
        </div>
      </div>
  )
}

Search.propTypes = {
  term: PropTypes.string,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
}

export default Search
