import React from 'react'

import PoweredBy from './PoweredBy'

const Footer = () => {
  return (
    <footer>
      <PoweredBy />
    </footer>
  )
}

export default Footer
