import css from './HamburguerMenu.module.sass'

import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { CSSTransition } from 'react-transition-group'

import Button from '../Button'
import Link from '../Link'

const HamburguerMenu = ({ options }) => {
  const [opened, setOpened] = useState()
  const handleHamburgerClick = useCallback(() => {
    setOpened(x => !x)
  }, [])

  const handleLinkClick = useCallback(() => {
    setOpened(false)
  }, [])

  return (
    <div className={css.container}>
      <Button
        className={opened ? css.btnHamburgerOpened : css.btnHamburger}
        variant='icon'
        onClick={handleHamburgerClick}
      >
        <i className={css.line1} />
        <i className={css.line2} />
        <i className={css.line3} />
      </Button>

      <div className={css.menuContainer}>
        <CSSTransition
          in={opened}
          timeout={200}
          unmountOnExit
          classNames={{
            appear: css[`menu-appear`],
            appearActive: css[`menu-appear-active`],
            appearDone: css[`menu-done-appear`],
            enter: css[`menu-enter`],
            enterActive: css[`menu-enter-active`],
            enterDone: css[`menu-done-enter`],
            exit: css[`menu-exit`],
            exitActive: css[`menu-exit-active`],
            exitDone: css[`menu-done-exit`]
          }}
        >
          <ul className={css.menu}>
            {options.map((option, idx) => (
              <li key={idx} className={css.menuItem}>
                <Link className={css.menuLink} to={option.to} params={option.params} onClick={handleLinkClick}>
                  {option.text}
                </Link>
              </li>
            ))}
          </ul>
        </CSSTransition>
      </div>
    </div>
  )
}

HamburguerMenu.propTypes = {
  options: PropTypes.array,
}

export default HamburguerMenu
