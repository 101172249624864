import css from './OtherInfo.module.sass'

import React from 'react'
import PropTypes from 'prop-types'
import Text from '../Text'
import SectionHeading from './SectionHeading'
import Link from '../Link'
import { removeProtocol } from '../../helpers/strings'
import { STAGEOFBUSINESS, TEAMSIZE } from '../../constants/otherInfo'


const OtherInfo = ({ company }) => {

  const optionStageOfBusiness = STAGEOFBUSINESS.find((elem) => (elem.value === company.stageOfBusiness))
  const optionTeamSize = TEAMSIZE.find((elem) => (elem.value === company.teamSize))
  const industries = company?.industries.map((elem) => elem.name).join(', ')

  return (
    <section className={css.container}>
      <div className={css.content}>
        <SectionHeading icon="other_info_linear">
          Other Info
        </SectionHeading>

        <div className={css.otherInfoContainer}>
          <div className={css.otherInfoRow}>
            <Text variant='large' weight='500'>WEBSITE URL</Text>
            <Link color='black' external href={company.website}>
              {removeProtocol(company.website)}
            </Link>
          </div>
          <div className={css.otherInfoRow}>
            <Text variant='large' weight='500'>CONTACT EMAIL</Text>
            <Text>{company.contactEmail}</Text>
          </div>
          <div className={css.otherInfoRow}>
            <Text variant='large' weight='500'>STAGE OF BUSINESS</Text>
            <Text>{optionStageOfBusiness.label}</Text>
          </div>
          <div className={css.otherInfoRow}>
            <Text variant='large' weight='500'>TEAM SIZE</Text>
            <Text>{optionTeamSize.label}</Text>
          </div>
          {
            industries &&
            <div className={css.otherInfoRow}>
              <Text variant='large' weight='500'>INDUSTRY</Text>
              <Text>{industries}</Text>
            </div>
          }
          <div className={css.otherInfoRow}>
            <Text variant='large' weight='500'>HOW SOLUTION WILL BEST SUIT M&S</Text>
            <Text>{company.solution}</Text>
          </div>
        </div>
      </div>
    </section>
  )
}

OtherInfo.propTypes = {
  company: PropTypes.object
}

export default OtherInfo
