import css from './Item.module.sass'

import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Icon from '../Icon'

import { ListContextConsumer } from './index'

const Item = ({ icon, style, children }) =>
  <ListContextConsumer>
    {({ variant, gap, offset, iconProps }) =>
      <li className={classNames(css[variant], css[`gap-${gap}`], css[`offset-${offset}`])} style={style}>
        {iconProps &&
          <span className={css.icon}>
            <Icon {...iconProps} />
          </span>
        }

        {icon &&
          <span className={css.icon}>{icon}</span>
        }

        {children}
      </li>
    }
  </ListContextConsumer>

Item.propTypes = {
  icon: PropTypes.node,
  style: PropTypes.object,
  children: PropTypes.node
}

export default Item
