import React from 'react'

import AccountSettingsForms from '../components/AccountSettingsForms'
import Layout from '../components/Layout'

const SettingsScreen = () => {
  return (
    <Layout variant='dashboard'>
      <AccountSettingsForms />
    </Layout>
  )
}

export default SettingsScreen
