import css from './FieldLabel.module.sass'

import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Asterisk from '../Asterisk'
import Text from '../Text'
import InfoTooltip from '../InfoTooltip'

const FieldLabel = ({ variant = 'base', label, sublabel, info, name, className }) => {
  if (!label) {
    return null
  }

  const labelHasAsterisk = label.includes('*')

  return (
    <div className={css[variant]}>
      <span className={classNames(css.labelAndInfo, className)}>
        <label htmlFor={name}>
          <Text variant='standardLarger'>
            {label.replace(' *', '')} {labelHasAsterisk && <Asterisk />}
          </Text>
        </label>

        {info &&
          <span className={css.info}>
            <InfoTooltip content={info} />
          </span>
        }
      </span>
      {' '}

      {sublabel &&
        <>
          {typeof sublabel === 'string'
            ? (
              <Text variant='light' italic>
                ({sublabel})
              </Text>
            )
            : sublabel
          }
        </>
      }
    </div>
  )
}

FieldLabel.propTypes = {
  label: PropTypes.string,
  sublabel: PropTypes.node,
  info: PropTypes.node,
  name: PropTypes.string,
  variant: PropTypes.string,
  className: PropTypes.string
}

export default FieldLabel
