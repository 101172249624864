import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Prompt } from 'react-router-dom'

import omit from 'lodash.omit'
import pick from 'lodash.pick'

import Form from './Form'

import { gtmTrack } from '../../helpers/tracking'
import { areCompanyDetailsComplete } from '../../helpers/companies'
import { prepareFormData, formDataWithErrors } from '../../helpers/forms'
import { REQUIRED_PITCHTAPE_FIELDS, OPTIONAL_PITCHTAPE_FIELDS, UNLOAD_TEXT } from '../../constants/forms'
import { GTM_PITCHTAPE_PROFILE_COMPLETED } from '../../constants/gtm'

// making sure accepted props won't get cleared by some optimizer
const PROP_TYPES = {
  company: PropTypes.object,
  formErrors: PropTypes.array,
  formRef: PropTypes.object,
  onUpdate: PropTypes.func
}

class FormLinker extends PureComponent {
  static propTypes = PROP_TYPES

  state = {
    formData: null,
    savingQueued: false
  }

  dataToSave = {}
  saveTimeout = null

  static getDerivedStateFromProps (props, state) {
    if (props.company && !state.formData) {
      return {
        formData: prepareFormData(
          pick(props.company, [ ...REQUIRED_PITCHTAPE_FIELDS, ...OPTIONAL_PITCHTAPE_FIELDS]),
          REQUIRED_PITCHTAPE_FIELDS
        )
      }
    }

    if (props.formErrors && props.formErrors !== state.formErrors) {
      return {
        formErrors: props.formErrors,
        formData: formDataWithErrors(state.formData, props.formErrors)
      }
    }

    return null
  }

  componentDidUpdate (prevProps) {
    const prevCompany = prevProps.company
    const company = this.props.company

    if (!areCompanyDetailsComplete(prevCompany) && areCompanyDetailsComplete(company)) {
      gtmTrack(GTM_PITCHTAPE_PROFILE_COMPLETED, {
        pitchtape: { id: company.id }
      })
    }
  }

  handleBeforeUnload = (event) => {
    event.preventDefault()
    event.returnValue = UNLOAD_TEXT
  }

  bindExitConfirmationOnFirstUpdate = () => {
    if (!this.state.savingQueued) {
      this.setState({
        savingQueued: true
      })

      window.addEventListener('beforeunload', this.handleBeforeUnload)
    }
  }

  unbindExitConfirmationAfterAllUpdates = () => {
    if (!Object.keys(this.dataToSave).length) {
      this.setState({
        savingQueued: false
      })

      window.removeEventListener('beforeunload', this.handleBeforeUnload)
    }
  }

  queueUpdate = (patch, timeoutDuration) => {
    this.bindExitConfirmationOnFirstUpdate()

    Object.assign(this.dataToSave, patch)

    clearTimeout(this.saveTimeout)

    this.saveTimeout = setTimeout(() => {
      this.saveTimeout = null

      this.props.onUpdate(this.dataToSave)
        .then(this.unbindExitConfirmationAfterAllUpdates)
        .catch((error) => {
          console.error(error) // eslint-disable-line no-console
          this.unbindExitConfirmationAfterAllUpdates()
        })

      this.dataToSave = {}
    }, timeoutDuration == null ? 2000 : timeoutDuration)
  }

  handleInvalidField = (name) => {
    delete this.dataToSave[name]

    if (!Object.keys(this.dataToSave).length) {
      clearTimeout(this.saveTimeout)
      this.saveTimeout = null
      this.unbindExitConfirmationAfterAllUpdates()
    }
  }

  handleUpdate = (patch, timeoutDuration) => {
    this.setState({
      formData: {
        ...this.state.formData,
        ...prepareFormData(patch, REQUIRED_PITCHTAPE_FIELDS)
      }
    })

    this.queueUpdate(patch, timeoutDuration)
  }

  render () {
    const pitchFormProps = omit(this.props, Object.keys(PROP_TYPES))
    const { formData } = this.state

    if (!formData) {
      return null
    }

    return (
      <>
        <Prompt
          when={this.state.savingQueued}
          message={UNLOAD_TEXT}
        />

        <Form
          {...pitchFormProps}
          formData={formData}
          company={this.props.company}
          formRef={this.props.formRef}
          onUpdate={this.handleUpdate}
          onInvalid={this.handleInvalidField}
        />
      </>
    )
  }
}
export default FormLinker
