import css from './VideoNotReady.module.sass'

import React from 'react'
import PropTypes from 'prop-types'

import AnimatedEllipsis from './AnimatedEllipsis'
import Image from './Image'
import Ionicon from './Ionicon'
import Text from './Text'

const VideoNotReady = ({ company }) => {
  const { concatenatedVideo } = company

  return concatenatedVideo
    ? (
      <div className={css.processing}>
        <div className={css.processingInner}>
          <div className={css.stichingImage}>
            <Image fileName='stitching.svg' />
          </div>

          <Text variant='h2' tag='h2' offset='half-bottom'>
            Your video is processing<AnimatedEllipsis />
          </Text>

          <Text variant='standardLarger' tag='p' style={{ lineHeight: '24px' }}>
            This should only take a few minutes.
          </Text>
        </div>
      </div>
    )
    : (
      <div className={css.empty}>
        <div className={css.emptyInner}>
          <Ionicon name='videocam' size='48' style={{ opacity: 0.1 }} />
          <Text tag='p' variant='light' italic offset='quarter-top'>
            Your video preview will appear here
          </Text>
        </div>
      </div>
    )
}

VideoNotReady.propTypes = {
  company: PropTypes.object
}

export default VideoNotReady
