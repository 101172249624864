import css from './index.module.sass'

import React, { forwardRef, useState, useRef } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

import { getBounds } from '../../helpers/ui'
import { moveItemInArray } from '../../helpers/arrays'

let portal

const ReorderableTable = forwardRef(({ items, renderItem, onReorder, ...tableProps }, ref) => {
  const [ nextIndex, setNextIndex ] = useState()
  const [ draggedIndex, setDraggedIndex ] = useState(null)
  const [ reorderedItems, setReorderedItems ] = useState()
  const draggedRef = useRef()

  let trSet

  const initPortal = () => {
    if (!portal) {
      const div = document.createElement('div')
      div.className = 'reorderable-table-portal'
      document.body.appendChild(div)
      portal = div
    }
  }

  const handleDragStart = (event, _draggedIndex) => {
    event.preventDefault()

    initPortal()

    let _nextIndex

    trSet = ref.current.querySelectorAll('tr')
    const rowsBounds = [...trSet].map(tr => {
      const { top, bottom } = getBounds(tr)
      return { top, bottom }
    })

    const touchedRow = event.currentTarget.parentNode

    const { pageX: cursorStartX, pageY: cursorStartY } = event
    const bounds = getBounds(event.currentTarget)
    const diffY = event.pageY - bounds.top
    const diffX = event.pageX - bounds.left
    const halfRowHeight = bounds.height / 2

    const reposition = (cursorX, cursorY) => {
      const nextTop = cursorY - diffY
      const nextCenter = nextTop + halfRowHeight

      draggedRef.current.style.width = touchedRow.clientWidth + 'px'
      draggedRef.current.style.top = nextTop + 'px'
      draggedRef.current.style.left = (cursorX - diffX) + 'px'

      for (let i = 0; i < rowsBounds.length; i++) {
        const bounds = rowsBounds[i]
        if (bounds.top < nextCenter && bounds.bottom >= nextCenter) {
          _nextIndex = i
          setNextIndex(i)
          setReorderedItems(moveItemInArray(items, _draggedIndex, i))
          return
        }
      }
    }

    const copyCellWidths = () => {
      const clonedCells = draggedRef.current.querySelectorAll('td')
      const originalCells = touchedRow.querySelectorAll('td')

      for (let i = 0; i < clonedCells.length; i++) {
        clonedCells[i].style.width = originalCells[i].clientWidth + 'px'
      }
    }

    setTimeout(() => {
      reposition(cursorStartX, cursorStartY)
      copyCellWidths()
    }, 0)

    const handleMouseMove = (event) => {
      reposition(event.pageX, event.pageY)
    }

    const handleMouseUp = () => {
      document.removeEventListener('mousemove', handleMouseMove)
      document.removeEventListener('touchmove', handleMouseMove)
      document.removeEventListener('mouseup', handleMouseUp)
      document.removeEventListener('touchend', handleMouseUp)

      setReorderedItems(null)
      setNextIndex(null)
      setDraggedIndex(null)

      if (_nextIndex !== _draggedIndex) {
        onReorder(_draggedIndex, _nextIndex, items[_draggedIndex])
      }
    }

    document.addEventListener('mousemove', handleMouseMove)
    document.addEventListener('touchmove', handleMouseMove)
    document.addEventListener('mouseup', handleMouseUp)
    document.addEventListener('touchend', handleMouseUp)

    setDraggedIndex(_draggedIndex)
  }

  return (
    <>
      <table {...tableProps} ref={ref}>
        <tbody>
          {(reorderedItems || items).map((item, index) =>
            renderItem({
              item,
              dragged: nextIndex === index,
              onDragStart: (event) => { handleDragStart(event, index) }
            }))
          }
        </tbody>
      </table>

      {draggedIndex !== null &&
        ReactDOM.createPortal(
          (
            <div className={css.dragged} ref={draggedRef}>
              <table className={css.draggedTable}>
                <tbody>
                  {renderItem({ item: items[draggedIndex] })}
                </tbody>
              </table>
            </div>
          ),
          portal
        )
      }
    </>
  )
})

ReorderableTable.displayName = 'ReorderableTable'

ReorderableTable.propTypes = {
  items: PropTypes.array,
  renderItem: PropTypes.func,
  onReorder: PropTypes.func
}

export default ReorderableTable
