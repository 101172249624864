import css from './Tooltip.module.sass'

import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import { isMobile } from '../helpers/devices'

import Popup from './Popup'

const Tooltip = ({ visible: _visible, hoverable, children, ...popupProps }) => {
  const [ visible, setVisible ] = useState()

  const containerRef = useRef()
  const popupRef = useRef()
  const timeoutRef = useRef()
  const clickableRef = useRef()

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current)
    }
  }, [])

  useEffect(() => {
    const handleTouchStart = () => {
      clickableRef.current = !visible
    }

    document.addEventListener('touchstart', handleTouchStart)

    return () => {
      document.removeEventListener('touchstart', handleTouchStart)
    }
  }, [ visible ])

  useEffect(() => {
    setVisible(_visible)
  }, [ _visible ])

  const handleMouseEnter = () => {
    clearTimeout(timeoutRef.current)
    setVisible(true)
  }

  const handleMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setVisible(false)
    }, 70)
  }

  const handleOutsideTouch = (event) => {
    const { target } = event

    if (!popupRef.current || !popupRef.current.contains(target)) {
      setVisible(false)

      document.removeEventListener('touchstart', handleOutsideTouch)
    }
  }

  const handleClick = () => {
    if (!clickableRef.current) {
      // on mobile clickable area is larger than touchable so clicking right outside the icon container
      // triggers `touchstart` for the document and then `click` for the icon
      // so the tooltip disappears on `touchstart` and reappears on `click`
      return
    }

    setVisible(true)
    document.addEventListener('touchstart', handleOutsideTouch)
  }

  return (
    <div
      className={css.container}
      ref={containerRef}
      {...(
        hoverable && (
          isMobile()
            ? {
              onClick: handleClick
            }
            : {
              onMouseEnter: handleMouseEnter,
              onMouseLeave: handleMouseLeave
            }
        )
      )}
    >
      {children}

      <Popup
        {...popupProps}
        variant='tooltip'
        visible={visible}
        containerRef={containerRef}
        ref={popupRef}
      />
    </div>
  )
}

Tooltip.propTypes = {
  visible: PropTypes.bool,
  hoverable: PropTypes.bool,
  children: PropTypes.node
}

export default Tooltip
