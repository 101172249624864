import css from './Item.module.sass'

import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import Asterisk from '../../Asterisk'
import Ionicon from '../../Ionicon'
import Link from '../../Link'
import Text from '../../Text'

const Item = ({ index, section, title, required, complete, onSelect }) => {
  const handleSelect = useCallback(() => {
    onSelect(section)
  }, [ section, onSelect ])

  return (
    <li className={css.container}>
      <Link
        color='deepBlue'
        onClick={handleSelect}
      >
        {complete &&
          <div className={css.indexComplete}><Ionicon name='checkmark' color='white' /></div>
        }
        <div className={css.index}><Text color='gray'>{index + 1}</Text></div>
        <Text variant='standardLarger'>{title} {required && <Asterisk />}</Text>
      </Link>
    </li>
  )
}

Item.propTypes = {
  index: PropTypes.number,
  section: PropTypes.string,
  title: PropTypes.string,
  required: PropTypes.bool,
  complete: PropTypes.bool,
  onSelect: PropTypes.func
}

export default Item
