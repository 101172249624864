import css from './UploadCompanyImg.module.sass'

import React from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { Mutation } from 'react-apollo'

import Alert from '../Alert'
import Button from '../Button'
import { FileField } from '../Form'
import Ionicon from '../Ionicon'
import SectionTitle from '../SectionTitle'
import InfoTooltip from '../InfoTooltip'

import { GET_USER_COMPANY_MAIN } from '../../constants/queries'
import { formatGraphQLError } from '../../helpers/errors'

const UPLOAD_COMPANY_ICON = gql`
mutation($iconFile: Upload) {
  uploadCompanyIcon(input: {iconFile: $iconFile}) {
      obj {
          iconFile
      }
      errors {
          field
          messages
      }
  }
}
`

const ACCEPTED_FILE_TYPES = ['.PNG', '.JPEG', '.SVG'].join(',')

const UploadCompanyIcon = ({ iconFile }) => {

  return (
    <Mutation
      mutation={UPLOAD_COMPANY_ICON}
      update={(cache, { data: { uploadCompanyIcon: { obj, errors } } }) => {
        if (errors) {
          return
        }

        const { company } = cache.readQuery({ query: GET_USER_COMPANY_MAIN })

        cache.writeQuery({
          query: GET_USER_COMPANY_MAIN,
          data: {
            company: {
              ...company,
              iconFile: obj.iconFile
            }
          }
        })
      }}
    >
      {(uploadCompanyIcon, { data, loading, error }) =>
        <section>
          <SectionTitle
            className={css.title}
            subTitle='Company Logo Icon'
            nextToTitle={
              <InfoTooltip
                content={
                  'Upload your company’s logo icon or social media profile image. Square images with transparent background work best (at least 100x100 pixels). Upload a PNG, JPEG or SVG. File size up to 2 MB. The image will be displayed over your video next to your company name.'
                }
                style={{ marginTop: -15 }}
              />
            }
          />

          {data && data.errors &&
            <Alert variant='error'>{data.errors[0].messages.join('')}</Alert>
          }

          {error &&
            <Alert variant='error'>{formatGraphQLError(error)}</Alert>
          }

          {iconFile
            ? (
              <div className={css.imgAndButtonContianerIcon}>
                <div className={css.buttonContainer}>
                  <Button
                    variant='primary'
                    disabled={loading}
                    icon={<Ionicon name='trash' size='24' color='white' />}
                    onClick={() => {
                      uploadCompanyIcon({
                        variables: {
                          iconFile: null
                        }
                      })
                    }}
                  >
                    Delete file
                  </Button>
                </div>

                <div className={css.imgContainer}>
                  <img src={iconFile} alt="icon" />
                </div>
              </div>
            )
            : (
              <>
                <FileField
                  accept={ACCEPTED_FILE_TYPES}
                  maxSize={2}
                  onChange={(file) => {
                    uploadCompanyIcon({
                      variables: {
                        iconFile: file
                      }
                    })
                  }}
                >
                  {({ chooseFile }) =>
                    <Button
                      disabled={loading}
                      variant='primary'
                      icon={<Ionicon name='cloudUpload' color='white' size='32' />}
                      onClick={chooseFile}
                    >
                      Upload file
                    </Button>
                  }
                </FileField>
              </>
            )
          }
        </section>
      }
    </Mutation>
  )
}

UploadCompanyIcon.propTypes = {
  iconFile: PropTypes.string,
}

export default UploadCompanyIcon
