export const GTM_FORM_CHANGE_PASSWORD = 'changePassword'
export const GTM_FORM_CHANGE_USER_DETAILS = 'changeUserDetails'
export const GTM_FORM_FORGOT_PASSWORD = 'forgotPassword'
export const GTM_FORM_INVESTOR_PROFILE = 'investorProfile'
export const GTM_FORM_PITCHTAPE = 'pitchtape'
export const GTM_FORM_RESET_PASSWORD = 'resetPassword'
export const GTM_FORM_SIGN_IN = 'signIn'
export const GTM_FORM_SINGUP_FOUNDER = 'singupFounder'
export const GTM_FORM_SINGUP_INVESTOR = 'singupInvestor'

export const GTM_BUTTON_FOUNDER_CLICKED_CONTACT_EVENT = 'founderClickedContact'
export const GTM_INVESTOR_CLICKED_CONNECT_EVENT = 'investorClickedConnect'
export const GTM_INVESTOR_CLICKED_PASS_EVENT = 'investorClickedPass'
export const GTM_INVESTOR_CLICKED_SAVE_EVENT = 'investorClickedSave'
export const GTM_USER_REGISTERED_EVENT = 'userRegistered'
export const GTM_PITCHTAPE_PUBLISHED_EVENT = 'pitchtapePublished'
export const GTM_INVESTOR_PROFILE_CREATED_EVENT = 'investorProfileCreated'
export const GTM_PITCH_VIDEOS_RECORDED_EVENT = 'pitchVideosRecorded'

export const GTM_FOUNDER_CANCELED_PLAN = 'founderCanceledPlan'
export const GTM_FOUNDER_UNCANCELED_PLAN = 'founderUncanceledPlan'
export const GTM_FOUNDER_UPGRADED_PLAN = 'founderUpgradedPlan'
export const GTM_FOUNDER_DOWNGRADED_PLAN = 'founderDowngradedPlan'
export const GTM_FOUNDER_SUBSCRIBED_TO_PLAN = 'founderSubscribedToPlan'
export const GTM_PITCHTAPE_PROFILE_COMPLETED = 'pitchtapeProfileCompleted'
export const GTM_PITCHTAPE_URL_COPIED = 'pitchtapeUrlCopied'
export const GTM_PRODUCT_TOUR_COMPLETED = 'productTourCompleted'

export const GTM_CHAT_MESSAGE_SENT = 'chatMessageSent'

export const GTM_VIDEO_VIEWED = 'videoViewed'
export const GTM_VIDEO_VIEWING_STOP = 'videoViewingStop'
export const GTM_PITCHTAPE_VIEWED = 'pitchtapeViewed'
export const GTM_PITCHTAPE_VIEWING_STOP = 'pitchtapeViewingStop'
