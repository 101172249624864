import css from './Countdown.module.sass'

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

const Countdown = ({ start = 3, onEnd }) => {
  const [ current, setCurrent ] = useState(start)

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (current === 1) {
        return onEnd()
      }

      setCurrent(current - 1)
    }, 1000)

    return () => {
      clearTimeout(timeout)
    }
  }, [ current, onEnd ])

  return (
    <div className={css.container}>
      {current}
    </div>
  )
}

Countdown.propTypes = {
  start: PropTypes.number,
  onEnd: PropTypes.func
}

export default Countdown
