import React, { useMemo } from 'react'

import Dropdown from './Dropdown'
import { STAGEOFBUSINESS } from '../../constants/otherInfo'

const StageOfbusinessDropdown = ({ ...dropdownProps }) => {
  const options = useMemo(() => {
    return STAGEOFBUSINESS.map(x => ({ value: x.value, label: x.label }))
  }, [])

  return (
    <Dropdown
      options={options}
      renderLabel={value => value.label}
      {...dropdownProps}
    />
  )
}

export default StageOfbusinessDropdown
