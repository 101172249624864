import React from 'react'

import Tour from './Tour'

import {
  TOUR_INVESTOR_FEED_CLASS,
  TOUR_INVESTOR_PROFILE_CLASS,
  TOUR_INVESTOR_SAVE_CLASS,
  TOUR_INVESTOR_MORE_CLASS,
  TOUR_INVESTOR_FEED_LIST_CLASS,
  TOUR_INVESTOR_RATE_CLASS,
  TOUR_INVESTOR_PREV_NEXT_CLASS
} from '../constants/tour.js'

const InvestorTour = () =>
  <Tour
    steps={[
      {
        title: `Feed`,
        text: `Click on Feed to view one pitch at a time in an online feed.`,
        selector: '.' + TOUR_INVESTOR_FEED_CLASS
      },
      {
        title: 'List',
        text: 'Click on List to view a summary of all pitches on one page.',
        selector: '.' + TOUR_INVESTOR_FEED_LIST_CLASS
      },
      {
        title: 'Profile',
        text: 'Click on Profile to access your account settings.',
        selector: '.' + TOUR_INVESTOR_PROFILE_CLASS
      },
      {
        title: 'Rate',
        text: 'Click on Submit Rating to open the ratings tool.',
        selector: '.' + TOUR_INVESTOR_RATE_CLASS
      },
      {
        title: 'Favorites',
        text: 'Click on Add to Favorites to add the pitch to your Favorites list.',
        selector: '.' + TOUR_INVESTOR_SAVE_CLASS
      },
      {
        title: 'More',
        text: 'Click on More to take other actions, including the ability to take notes.',
        selector: '.' + TOUR_INVESTOR_MORE_CLASS
      },
      {
        title: 'Previous/Next',
        text: 'Click on the previous and next arrows to navigate back and forth through the Feed.',
        selector: '.' + TOUR_INVESTOR_PREV_NEXT_CLASS
      }
    ]}
  />

export default InvestorTour
