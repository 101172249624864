import css from './Loader.module.sass'

import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Image from './Image'

const Loader = ({ variant = 'base', icon, offset }) =>
  <div className={classNames(css[variant], css[`offset-${offset}`])}>
    {variant === 'blank'
      ? null
      : icon
        ? <i className={css.image}>{icon}</i>
        : <Image width='32' className={css.image} fileName='logoSquareBlue.svg' />
    }
  </div>

Loader.propTypes = {
  variant: PropTypes.string,
  icon: PropTypes.node,
  offset: PropTypes.string
}

export default Loader
