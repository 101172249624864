import css from './CompanyDetails.module.sass'

import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Button from '../Button'
import Ionicon from '../Ionicon'
import {
  FormRow,
  FormCol,
  TextField,
  WebsiteField,
  TeamSizeDropdown,
  StageOfbusinessDropdown,
  IndustryDropdown,
  TextArea
} from '../Form'
import SectionTitle from '../SectionTitle'
import UploadCompanyIcon from './UploadCompanyIcon'

const CompanyDetails = ({
  name, description, website, contactEmail, stageOfBusiness, teamSize, solution,
  onUpdate, onInvalid, iconFile, industries
}) => {
  const [collapsed, setCollapsed] = useState(localStorage.getItem('companyDetailsCollapsed') === 'true')

  const handleCollapseClick = () => {
    setCollapsed(!collapsed)
    localStorage.setItem('companyDetailsCollapsed', !collapsed)
  }

  return (
    <section>
      <div className={collapsed ? css.btnCollapseActive : css.btnCollapse} onClick={handleCollapseClick}>
        <Button variant='icon'>
          <Ionicon name={collapsed ? 'arrowDropdown' : 'arrowDropup'} color='deepBlue' size='24' />
        </Button>
      </div>

      <SectionTitle
        required
        title='1. Basic Info'
      />

      {!collapsed &&
        <>
          <FormRow>
            <FormCol size='half'>
              <TextField
                label='Company Name *'
                name='name'
                {...name}
                onUpdate={onUpdate}
                onInvalid={onInvalid}
              />
            </FormCol>
          </FormRow>

          <FormRow offset='25'>
            <FormCol>
              <TextField
                label='Elevator Pitch *'
                name='description'
                {...description}
                placeholder='Describe your company in one sentence'
                maxlength={100}
                counter
                onUpdate={onUpdate}
                onInvalid={onInvalid}
              />
            </FormCol>
          </FormRow>

          <FormRow offset='25'>
            <FormCol>
              <WebsiteField
                label='Website URL *'
                name='website'
                {...website}
                onUpdate={onUpdate}
              />
            </FormCol>

            <FormCol>
              <TextField
                label='Contact Email *'
                name='contactEmail'
                {...contactEmail}
                onUpdate={onUpdate}
                onInvalid={onInvalid}
              />
            </FormCol>
          </FormRow>

          <FormRow offset='25'>
            <FormCol>
              <StageOfbusinessDropdown
                label='Stage of Business *'
                name='stageOfBusiness'
                {...stageOfBusiness}
                onUpdate={onUpdate}
                onInvalid={onInvalid}
              />
            </FormCol>

            <FormCol>
              <TeamSizeDropdown
                label='Team Size *'
                name='teamSize'
                {...teamSize}
                onUpdate={onUpdate}
                onInvalid={onInvalid}
              />
            </FormCol>
          </FormRow>
          <FormRow offset='25'>
            <FormCol>
              <IndustryDropdown
                label='Industry'
                name='industries'
                {...industries}
                multiple
                maxlength={3}
                onUpdate={onUpdate}
                onInvalid={onInvalid}
              />
            </FormCol>
          </FormRow>
          <FormRow offset='25'>
            <FormCol>
              <TextArea
                label='How could your solution be implemented by Marks and Spencer?  *'
                name='solution'
                {...solution}
                maxlength={400}
                counter
                onUpdate={onUpdate}
                onInvalid={onInvalid}
                rows='5'
              />
            </FormCol>
          </FormRow>

          <FormRow offset='25'>
            <FormCol>
              <UploadCompanyIcon
                iconFile={iconFile}
              />
            </FormCol>
          </FormRow>
        </>
      }
    </section>
  )
}

CompanyDetails.propTypes = {
  name: PropTypes.object,
  description: PropTypes.object,
  website: PropTypes.object,
  contactEmail: PropTypes.object,
  stageOfBusiness: PropTypes.object,
  teamSize: PropTypes.object,
  solution: PropTypes.object,
  onUpdate: PropTypes.func,
  onInvalid: PropTypes.func,
  logoFile: PropTypes.string,
  iconFile: PropTypes.string,
  industries: PropTypes.object,
}

export default CompanyDetails
