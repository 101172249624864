import css from './WelcomeModal.module.sass'

import React from 'react'

import Button from './Button'
import Modal from './Modal'
import Text from './Text'


import { CREATE_PATH } from '../constants/routes'
import { PRODUCT_NAME } from "../constants/enterpriseConfig"

const WelcomeModal = () => {

  return (
    <Modal
      variant="welcome"
      title={<Text tag="h1" variant="h2" color="white">Welcome!</Text>}
      buttons={
        <Button to={CREATE_PATH} variant="primary">
          Create {PRODUCT_NAME.toLocaleLowerCase()}
        </Button>
      }
    >
      <div className={css.content}>
        <Text tag="p" variant="larger" weight="400" offset="single-bottom">
          Thank you for signing up to M&S Ignite’s Pitch Portal.
        </Text>

        <Text tag="p" variant="larger" weight="400" offset="single-bottom" className={css.p}>
          We&apos;ll walk you through the process of creating a self-recorded pitch video
          from start to finish with our user-friendly video templates and recording tools.
        </Text>

        <Text variant="larger" weight="400">
          Happy Pitching!
        </Text>
      </div>
    </Modal>
  )
}

export default WelcomeModal
