import css from "./UnsubscribeScreen.module.sass"

import PropTypes from "prop-types"
import React, { useRef, useEffect } from "react"
import { useMutation } from "react-apollo"
import gql from 'graphql-tag'

import Layout from "../components/Layout"
import Loader from "../components/Loader"
import Alert from "../components/Alert"
import { formatGraphQLError } from "../helpers/errors"
import Text from '../components/Text'
import { HOME_PATH } from "../constants/routes"


const UNSUSCRIBE_QUERY = gql`
  mutation($token: String!, $email: String!) {
    unsubscribeUser(token:$token, email:$email) {
      code
      message
    }
}
`

const UnsubscribeScreen = ({ location, history }) => {
  const { search } = location
  const params = new URLSearchParams(search)
  const token = useRef(params.get("unsubscribe_token"))
  const email = useRef(params.get("email"))

  const [unsuscribeUser, { loading, error, networkStatus },] = useMutation(UNSUSCRIBE_QUERY, {
    variables: {
      email: email.current,
      token: token.current
    },
    onCompleted: ({ data }) => {
      if (data?.errors) {
        return console.error("ERROR: ", data.errors)
      }
    },
  })

  useEffect(() => {
    if (!token.current || !email.current) {
      history.push(HOME_PATH)
    } else {
      unsuscribeUser()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (<>
    {error && <Layout background='white' noHeader>
      <Alert variant='error'>{formatGraphQLError(error)}</Alert>
    </Layout>
    }
    {!error &&
      <Layout background='white' noHeader>
        {!loading &&
          <div className={css.main}>
            <div className={css.container}>
              <Text className={css.title} variant='large' offset='single-bottom'>
                We have removed your email address from this list.
              </Text>
              <Text className={css.text} variant='large' offset='single-bottom'>
                You will no longer receive these notification emails.
              </Text>
              <Text className={css.text} variant='large' offset='single-bottom'>
                For questions or comments, please contact the M&S Ignite team.
              </Text>
            </div>
          </div>
        }
        {(loading || networkStatus === 4) &&
          <div className={css.loading}>
            <Loader variant='centered' />
          </div>
        }
      </Layout>
    }
  </>)
}

UnsubscribeScreen.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object
}

export default UnsubscribeScreen