import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { Consumer as CurrentUserConsumer } from '../components/CurrentUserContext'

import { SIGNIN_PATH } from '../constants/routes'

const SignOutScreen = ({ history, onTokenChange }) => {
  useEffect(() => {
    onTokenChange()
    history.replace(SIGNIN_PATH)
  }, [ history, onTokenChange ])

  return null
}

SignOutScreen.propTypes = {
  history: PropTypes.object,
  onTokenChange: PropTypes.func
}

export default function SignOutScreenContainer (props) {
  return (
    <CurrentUserConsumer>
      {({ onTokenChange }) =>
        <SignOutScreen {...props} onTokenChange={onTokenChange} />
      }
    </CurrentUserConsumer>
  )
}
