import css from './index.module.sass'

import React, { useCallback, useRef, useContext, useEffect } from 'react'
import DevicesContext from '../DevicesContext'
import gql from 'graphql-tag'
import { useQuery, useMutation } from 'react-apollo'

import Loader from '../Loader'
import Alert from '../Alert'
import ScheduleBar from '../ScheduleBar'

import FormLinker from './FormLinker'
import KnowledgeCenterBlock from './KnowledgeCenterBlock'
import PrivacyBlock from './PrivacyBlock'
import ProgressBlock from './ProgressBlock'
import SharingBlock from './SharingBlock'

import { ERRORS_FOR_FIELDS_FRAGMENT, USER_COMPANY_MAIN_FRAGMENT } from '../../constants/fragments'
import { GET_USER_COMPANY } from '../../constants/queries'
import { formatGraphQLError } from '../../helpers/errors'
import { formDataToServerData } from '../../helpers/forms'
import { moveItemInArray } from '../../helpers/arrays'
import { SUBMIT_SECTION } from '../../constants/pitchform'


const UPDATE_COMPANY = gql`
  mutation updateCompany ($input: CompanyUpdateMutationInput!) {
    updateCompany(input: $input) {
      obj {
        ...userCompanyMain
        website
				contactEmail
				stageOfBusiness
				teamSize
				solution
      }
      errors {
        ...errorsForFields
      }
    }
  }
  ${ERRORS_FOR_FIELDS_FRAGMENT}
  ${USER_COMPANY_MAIN_FRAGMENT}
`

const MOVE_COMPANY_MEMBER = gql`
  mutation moveCompanyMember ($id: Int!, $position: Int!) {
    moveCompanyMember(input: {id: $id, position: $position}) {
      order
    }
  }
`

const PitchForm = () => {
  const formRef = useRef()
  const blockRef = useRef()

  const { mobile } = useContext(DevicesContext)

  const [
    updateCompany,
    { data: updatingData, loading: updating, error: updatingError }
  ] = useMutation(UPDATE_COMPANY, {
    update: (cache, { data: { updateCompany } }) => {
      const { company } = cache.readQuery({ query: GET_USER_COMPANY })

      cache.writeQuery({
        query: GET_USER_COMPANY,
        data: {
          company: {
            ...company,
            ...updateCompany.obj
          }
        }
      })
    }
  })

  const [moveCompanyMember] = useMutation(MOVE_COMPANY_MEMBER)

  const { data: companyData, error, loading: getting, startPolling, stopPolling } = useQuery(GET_USER_COMPANY, {
    fetchPolicy: 'network-only'
  })

  const handleUpdate = useCallback((data) => {
    return updateCompany({
      variables: {
        input: {
          ...formDataToServerData(data, { urlKeys: ['website'] }),
          validate: false
        }
      }
    })
  }, [updateCompany])

  const handleSectionSelect = useCallback((section) => {
    section === SUBMIT_SECTION ? blockRef.current.scrollToSubmit() : formRef.current.scrollToSection(section)

  }, [])

  useEffect(() => {
    if(companyData && companyData.company?.concatenatedVideo?.status !== "finished"){
      startPolling(5000)
    }

    return () => {
      stopPolling()
    }
  }, [ startPolling, stopPolling, companyData ])

  const renderForm = () => {
    return (
      <div className={css.form}>
        <ScheduleBar variant='bottomOffset' />

        <FormLinker
          error={updatingError}
          formErrors={updatingData && updatingData.updateCompany.errors}
          company={companyData.company}
          saving={updating}
          formRef={formRef}
          onTeamReorder={(from, to, member) => {
            moveCompanyMember({
              variables: {
                id: member.id,
                position: to
              },
              update: (cache, { data: { moveCompanyMember: { order } } }) => {
                const { company } = cache.readQuery({ query: GET_USER_COMPANY })

                const nextMembers = order.map(id => company.members.find(m => m.id === id))

                cache.writeQuery({
                  query: GET_USER_COMPANY,
                  data: {
                    company: {
                      ...company,
                      members: nextMembers
                    }
                  }
                })
              },
              optimisticResponse: {
                __typename: 'Mutation',
                moveCompanyMember: {
                  __typename: '__CompanyMemberMoveMutationPayload',
                  order: moveItemInArray(companyData.company.members.map(m => m.id), from, to)
                }
              }
            })
          }}
          onUpdate={handleUpdate}
        />
      </div>
    )
  }

  if (getting) {
    return <Loader variant='centered' />
  }

  if (error) {
    return <Alert variant='error'>{formatGraphQLError(error)}</Alert>
  }

  return (
    <div className={css.container}>
      <div className={css.sidebar}>
        <ProgressBlock
          company={companyData.company}
          onSelect={handleSectionSelect}
        />

        <div className={css.formContainer}>
          {mobile && renderForm()}
        </div>

        <PrivacyBlock company={companyData.company} blockRef={blockRef} />
        <SharingBlock company={companyData.company} />
        <KnowledgeCenterBlock />

      </div>
      {!mobile && renderForm()}
    </div>
  )
}

export default PitchForm
