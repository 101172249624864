import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { graphql } from 'react-apollo'

import Dropdown from './Dropdown'

const GET_FUNDING_SOURCES = gql`
  query getFundingSources {
    sources: getFundingSources {
      id
      name
    }
  }
`

const FundingSourcesDropdown = ({ data, ...dropdownProps }) => {
  const options = useMemo(() => {
    return data.sources ? data.sources.map(x => ({ value: x.id, label: x.name })) : []
  }, [ data.sources ])

  return (
    <Dropdown
      options={options}
      {...dropdownProps}
    />
  )
}

FundingSourcesDropdown.propTypes = {
  data: PropTypes.object
}

export default graphql(GET_FUNDING_SOURCES)(FundingSourcesDropdown)
