import { useEffect } from 'react'
import PropTypes from 'prop-types'

const el = document.querySelector('meta[name="Description"]')

const MetaDescription = ({ content }) => {
  useEffect(() => {
    const lastContent = el.getAttribute('content')
    el.setAttribute('content', content)

    return () => {
      el.setAttribute('content', lastContent)
    }
  }, [ content ])

  return null
}

MetaDescription.propTypes = {
  content: PropTypes.string
}

export default MetaDescription
