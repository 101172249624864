import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Prompt } from 'react-router-dom'

import { EditorField } from '../Form'
import SectionTitle from '../SectionTitle'
import Text from '../Text'

import { UNLOAD_TEXT } from '../../constants/forms'
import ExamplePitchtapeLink from "../ExamplePitchVideo"
import { SHOW_EXAMPLES } from "../../constants/enterpriseConfig"

const HighlightsField = ({ value: _value, onUpdate, ...rest }) => {
  const [value, setValue] = useState(_value)

  useEffect(() => {
    setValue(_value)
  }, [_value])

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault()
      event.returnValue = UNLOAD_TEXT
    }

    if (value !== _value) {
      window.addEventListener('beforeunload', handleBeforeUnload)

      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload)
      }
    }
  }, [value, _value])

  return (
    <section>
      <SectionTitle
        title='6. Key Highlights'
        description={
          <>
            Add any additional information that you&apos;d like to highlight.
            {SHOW_EXAMPLES &&
              <Text offset="half-left">
                <ExamplePitchtapeLink
                  linkText="See example"
                  section="highlights"
                >
                </ExamplePitchtapeLink>
              </Text>
            }
          </>
        }
      />

      <Prompt
        when={value !== _value}
        message={UNLOAD_TEXT}
      />

      <EditorField
        name='highlights'
        value={value}
        onUpdate={onUpdate}
        {...rest}
      />
    </section>
  )
}

HighlightsField.propTypes = {
  value: PropTypes.string,
  onUpdate: PropTypes.func
}

export default HighlightsField
