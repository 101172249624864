import css from './Topics.module.sass'

import React from 'react'
import PropTypes from 'prop-types'

import Ionicon from '../Ionicon'
import Link from '../Link'
import List from '../List'
import Text from '../Text'

import { CREATE_VIDEOS_PATH } from '../../constants/routes'
import { formatSecondsAsTime } from '../../helpers/dates'

const Topics = ({ topics, topic: currentTopic, segment: currentSegment, recordedSegments, onToggleShowTopics }) => {
  return (
    <div className={css.container}>
      {topics.map((topic) => {
        const activeTopic = currentTopic === topic.id

        return (
          <div className={css.topic} key={topic.id}>

            <div className={css.list}>
              <List variant='bulletedTicks'>
                {topic.segments.map(segment => {
                  const done = recordedSegments.find(el => el.title === segment.id)
                  const activeSegment = activeTopic && currentSegment === segment.id
                  const activeSegmentDuration = recordedSegments.find(el => el.title === segment.id)?.duration || ''
                  return (
                    <List.Item
                      key={segment.id}
                      icon={
                        <Ionicon
                          name='videocam'
                          size='24'
                          color={activeSegment ? 'red' : (done ? 'deepBlue' : 'lightGray')}
                        />
                      }
                    >
                      <Link
                        to={CREATE_VIDEOS_PATH}
                        params={{ topic: topic.id, segment: segment.id }}
                        color='deepBlue'
                        onClick={() => onToggleShowTopics && onToggleShowTopics()}
                      >
                        <Text variant='standardLarger' color={activeSegment ? 'red' : null}>
                          {segment.title}
                        </Text>
                        {activeSegmentDuration &&
                          <Text variant="small" color={activeSegment ? 'red' : null}> ({formatSecondsAsTime(activeSegmentDuration)})</Text>
                        }
                      </Link>
                    </List.Item>
                  )
                })}
              </List>
            </div>
          </div>
        )
      })}
    </div>
  )
}

Topics.propTypes = {
  topics: PropTypes.array,
  topic: PropTypes.string,
  segment: PropTypes.string,
  recordedSegments: PropTypes.array,
  onToggleShowTopics: PropTypes.func
}

export default Topics
