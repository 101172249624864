import css from './RatingModal.module.sass'

import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useQuery, useMutation } from 'react-apollo'
import gql from 'graphql-tag'
import omit from 'lodash.omit'

import Button from './Button'
import { Form, FormFooter, FormRow, FormCol, TextArea, RatingField } from './Form'
import Modal from './Modal'
import Text from './Text'
import Ionicon from './Ionicon'
import Loader from './Loader'
import {
  MAP_RATING_QUESTIONS, PRODUCT_NAME,
} from "../constants/enterpriseConfig"

const GET_INVESTOR_COMPANY_RATING = gql`
  query getInvestorCompanyRating ($company: UUID) {
    getInvestorCompanyRating(company: $company) {
      rating1
      rating2
      rating3
      rating4
      ratingText
    }
  }
`

const RATE_COMPANY = gql`
  mutation rateCompany ($input: RateCompanyMutationInput!) {
    rateCompany(input: $input) {
      obj {
        id
        rating1
        rating2
        rating3
        rating4
        ratingText
        updatedAt
      }
      errors {
        field
        messages
      }
    }
  }
`

const RatingModal = ({ company, onClose, onRate }) => {
  const [formData, setFormData] = useState({
    rating3: company.givenRating3 || company.stageOfBusiness,
    rating4: company.givenRating4 || company.teamSize
  })

  const { data: ratingData, loading: fetching } = useQuery(GET_INVESTOR_COMPANY_RATING, {
    variables: { company: company.id },
    fetchPolicy: 'network-only'
  })

  useEffect(() => {
    if (ratingData) {
      setFormData({
        ...omit(ratingData.getInvestorCompanyRating, ['__typename']),
        rating3: company.givenRating3 || company.stageOfBusiness,
        rating4: company.givenRating4 || company.teamSize
      })
    }
  }, [ratingData, company])

  const [rateCompany, { saving }] = useMutation(RATE_COMPANY, {
    variables: {
      input: {
        company: company.id,
        ...formData
      }
    },
    onCompleted: ({ rateCompany }) => {
      if (rateCompany.errors) {
        return console.error(rateCompany.errors)
      }

      onRate(rateCompany.obj.rating1)
      onClose()
    },
  })

  const handleFieldUpdate = useCallback((patch) => {
    setFormData(x => ({ ...x, ...patch }))
  }, [])

  const handleSubmit = useCallback((event) => {
    event.preventDefault()
    rateCompany()
  }, [rateCompany])

  return (
    <Modal
      className='rating'
      variant='welcome'
      title={<>
        <Text tag="h1" variant="h2" color="white">
          Submit Rating on {company.name}
        </Text>

        <Button variant="icon" className={css.btnClose} onClick={onClose}>
          <Ionicon name="close" size="32" color="white" />
        </Button>
      </>}
      onOverlayClick={onClose}
      noButtons
    >
      {fetching
        ? <Loader />
        : (
          <Form onSubmit={handleSubmit}>
            <FormRow>
              <FormCol>
                <Text tag="h3" weight='500' italic offset="single-bottom" >
                  For the first two questions, kindly select a star rating that corresponds to the rating criteria.
                </Text>
                <Text tag="h3" weight='500'>
                  {(Object.prototype.hasOwnProperty.call(MAP_RATING_QUESTIONS, "rating1") ?
                    MAP_RATING_QUESTIONS["rating1"] :
                    `rating1 ${PRODUCT_NAME.toLowerCase()} rating 1`)}
                </Text>
                <div className={css.containerText}>
                  <RatingField
                    name="rating1"
                    value={formData.rating1}
                    focused
                    required
                    onUpdate={handleFieldUpdate}
                  />
                  <Text tag='p' offset='quarter-top'>
                    1 star = Solution has no fit.
                  </Text>
                  <Text tag='p'>
                    2 stars = Solution has minimal fit.
                  </Text>
                  <Text tag='p'>
                    3 stars = Solution has medium fit or requires pivot to align to M&S problem statement.
                  </Text>
                  <Text tag='p'>
                    4 stars = Solution is a good fit yet trial/POC required.
                  </Text>
                  <Text tag='p'>
                    5 stars = Solution is a strong fit and can be implemented without trial.
                  </Text>
                </div>
              </FormCol>
            </FormRow>

            <FormRow>
              <FormCol>

                <FormRow offset="15">
                  <FormCol>
                    <Text tag="h3" weight='500'>
                      {(Object.prototype.hasOwnProperty.call(MAP_RATING_QUESTIONS, "rating2")
                        ? MAP_RATING_QUESTIONS["rating2"] :
                        'Rating 2')}
                    </Text>
                    <div className={css.containerText}>
                      <RatingField
                        name="rating2"
                        value={formData.rating2}
                        clearable
                        onUpdate={handleFieldUpdate}
                        required
                      />
                      <Text tag='p' offset='quarter-top'>
                        1 star = Considerable concern regarding delivery or suitability with M&S culture/policies.
                      </Text>
                      <Text tag='p'>
                        2 stars = Moderate concerns over aspects of the cultural fit.
                      </Text>
                      <Text tag='p'>
                        3 stars = Reasonable cultural fit - Neutral position.
                      </Text>
                      <Text tag='p'>
                        4 stars = Good cultural fit - Confident that business is ethical and willing to evolve with M&S.
                      </Text>
                      <Text tag='p'>
                        5 stars = Strong cultural fit - Business aligns fully with M&S culture/policies.
                      </Text>
                    </div>
                  </FormCol>
                </FormRow>

                <Text tag="h3" weight='500' italic offset="double-top" >
                  For the next two questions, a star rating has been pre-selected based on information
                  provided by this company. You can change the rating if you think a different rating should be selected.
                </Text>

                <FormRow offset='15'>
                  <FormCol>
                    <Text tag="h3" weight='500'>
                      {(Object.prototype.hasOwnProperty.call(MAP_RATING_QUESTIONS, "rating3")
                        ? MAP_RATING_QUESTIONS["rating3"] :
                        'Rating 3')}
                    </Text>
                    <div className={css.containerText}>
                      <RatingField
                        name="rating3"
                        value={formData.rating3}
                        clearable
                        onUpdate={handleFieldUpdate}
                        required
                      />

                      <Text tag='p' offset='quarter-top'>
                        1 star = Ideation (pre-incorporation yet identifiable solution).

                      </Text>
                      <Text tag='p'>
                        2 stars = Early stage (eg MVP/pre-funding/pre-revenue).

                      </Text>
                      <Text tag='p'>
                        3 stars = Startup (eg 1-5 years trading, seeking/secured seed funding/customers, pilot in process).

                      </Text>
                      <Text tag='p'>
                        4 stars = Scaleup (eg 20% YoY growth in either employment or turnover for past 2 years).

                      </Text>
                      <Text tag='p'>
                        5 stars = Mature (eg pre-IPO, looking to exit).
                      </Text>
                    </div>
                  </FormCol>
                </FormRow>


                <FormRow offset='15'>
                  <FormCol>
                    <Text tag="h3" weight='500'>
                      {(Object.prototype.hasOwnProperty.call(
                        MAP_RATING_QUESTIONS, "rating4")
                        ? MAP_RATING_QUESTIONS["rating4"] :
                        'rating4')}
                    </Text>
                    <div className={css.containerText}>
                      <RatingField
                        name="rating4"
                        value={formData.rating4}
                        clearable
                        onUpdate={handleFieldUpdate}
                        required
                      />
                      <Text tag='p' offset='quarter-top'>
                        1 star = 1 to 2 staff.
                      </Text>
                      <Text tag='p'>
                        2 stars = 3 to 5 staff (mixture of full and part-time).
                      </Text>
                      <Text tag='p'>
                        3 stars = 6 to 10 staff (mixture of full and part-time).
                      </Text>
                      <Text tag='p'>
                        4 stars = 10+ staff (mixture of full and part-time).
                      </Text>
                      <Text tag='p'>
                        5 stars = 10+ staff with strong technical capability.
                      </Text>
                    </div>
                  </FormCol>
                </FormRow>
                <Text tag="h3" weight='500' italic offset="double-top" >
                  For the last question, kindly enter any comments you may have about this company.
                </Text>

              </FormCol>
            </FormRow>

            <FormRow offset='30'>
              <FormCol>
                <Text variant="standard" weight='500' offset="quarter-bottom">
                  5. Comments:
                </Text>
                <TextArea
                  name="ratingText"
                  value={formData.ratingText}
                  rows="5"
                  onUpdate={handleFieldUpdate}
                />
              </FormCol>
            </FormRow>

            <FormFooter offset='30'>
              <Button type='submit' variant='outline' onClick={onClose}>Cancel</Button>
              <Button type='submit' variant='primary' disabled={saving}>Submit</Button>
            </FormFooter>
          </Form>
        )
      }
    </Modal>
  )
}

RatingModal.propTypes = {
  company: PropTypes.object,
  defaultOverall: PropTypes.number,
  onClose: PropTypes.func,
  onRate: PropTypes.func
}

export default RatingModal
