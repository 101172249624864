import css from './LandingScreen.module.sass'

import React from 'react'

import Layout from '../components/Layout'
import Text from '../components/Text'
import Button from '../components/Button'
import Image from '../components/Image'

import { SIGNIN_PATH, SIGNUP_PATH } from '../constants/routes'

import { ReactComponent as ComputerIcon } from '../images/icons/computer.svg'
import { ReactComponent as UploadIcon } from '../images/icons/upload.svg'
import { ReactComponent as UserIcon } from '../images/icons/user.svg'
import { ReactComponent as ChatBubbleIcon } from '../images/icons/chat-bubble.svg'
import { ReactComponent as VideoCallIcon } from '../images/icons/video-call.svg'
import { ReactComponent as CheckIcon } from '../images/icons/check.svg'
import { Redirect } from "react-router-dom"
import {
  LANDING_PAGE_ENABLED, VIDEO_CREATOR,
  VIDEO_CREATOR_PLURAL,
  VIDEO_REVIEWER,
  VIDEO_REVIEWER_PLURAL
} from "../constants/enterpriseConfig"

const LandingScreen = () => {
  if ( !LANDING_PAGE_ENABLED ) {
    return <Redirect to={ SIGNIN_PATH }/>
  }
  return (
    <Layout variant="landing">
      <article className={ css.container }>
        <div className={ css.intro }>
          <div className={ css.header }>
            <h1 className={ css.headerH1 }>
              Virtual Pitch Portal
            </h1>
          </div>
          <p>
            An online video pitch portal for { VIDEO_REVIEWER_PLURAL } to learn about our exciting
            startup companies and their business opportunities.
          </p>
          <div className={css.buttons_mobile}>
            <Button variant={'secondary-color-mobile'} to={SIGNUP_PATH}
                    params={{ accountType: 'investor' }} className={css.singupButton}>
              {VIDEO_REVIEWER_PLURAL} sign up
            </Button>
            <Button variant='login-mobile' to={SIGNIN_PATH}>Log in</Button>
          </div>
        </div>
        <div className={css.photoDetails}>
          <figure className={css.photo}>
            <Image fileName='ipdc/ipdc-video-pitch-feed.webp' />
          </figure>
        </div>

        <div className={css.overview}>
          <Text tag='h2' variant='h2' offset='half-bottom'>Welcome to the Virtual Pitch Portal</Text>

          <Text tag='p' variant='larger' offset='double-bottom' lineHeight='1.7em'>
            Our <i>Virtual Pitch Portal</i> was created to help {VIDEO_CREATOR_PLURAL} and {VIDEO_REVIEWER_PLURAL} make
            meaningful connections.
            {VIDEO_CREATOR_PLURAL} can self-record a short pitch video with easy-to-use recording tools and
            startup-friendly video templates. {VIDEO_REVIEWER_PLURAL} can view pitch videos at their convenience and
            connect with {VIDEO_CREATOR_PLURAL}  directly on the platform in our private { VIDEO_REVIEWER }
            video pitch feed.
          </Text>
        </div>

        <div className={css.instructions}>
          <section className={css.instructionsForInvestors}>
            <Text tag='h1' variant='h2' offset='half-bottom' weight='500'>How It Works for {VIDEO_REVIEWER_PLURAL}</Text>

            <ul className={css.instructionsList}>
              <li>
                <span className={css.instructionsStep}>Step 1</span>
                <UserIcon width='64' className={css.instructionsIcon} />
                <Text tag='p' variant='larger' weight='500' className={css.instructionsStepHeading}>
                  Create an { VIDEO_REVIEWER } account
                </Text>
                <Text tag='p' variant='standardLarger'>
                  To start viewing startup pitches, simply create a profile with basic info about yourself, your
                  organization, and your investment criteria.
                </Text>
              </li>
              <li>
                <span className={css.instructionsStep}>Step 2</span>
                <VideoCallIcon width='64' className={css.instructionsIcon} />
                <Text tag='p' variant='larger' weight='500' offset='quarter-bottom' className={css.instructionsStepHeading}>
                  Discover startups
                </Text>
                <Text tag='p' variant='standardLarger'>
                  Log in to your Virtual Pitch Portal account to view startups anonymously and elect to save or connect.
                  If you click connect, the {VIDEO_CREATOR_PLURAL} will be notified and can contact you through the
                  platform to start
                  a conversation.
                </Text>
              </li>
              <li>
                <span className={css.instructionsStep}>Step 3</span>
                <ChatBubbleIcon width='64' className={css.instructionsIcon} />
                <Text tag='p' variant='larger' weight='500' offset='quarter-bottom' className={css.instructionsStepHeading}>
                  Connect with {VIDEO_CREATOR_PLURAL}
                </Text>
                <Text tag='p' variant='standardLarger'>
                  Manage your activity, track your deal flow, and exchange chat messages with your selected startups in
                  one simple dashboard.
                </Text>
              </li>
            </ul>
            <ul className={css.instructionsList}>
              <li>
                <Button variant={'secondary-color'} to={SIGNUP_PATH}
                        params={{ accountType: 'investor' }} className={css.singupButton}>
                  {VIDEO_REVIEWER_PLURAL} sign up
                </Button>
              </li>
            </ul>
          </section>

          <section className={css.instructionsForFounders}>
            <Text tag='h1' variant='h2' offset='half-bottom' weight='500'>
              How It Works for {VIDEO_CREATOR_PLURAL}
            </Text>

            <ul className={css.instructionsList}>
              <li>
                <span className={css.instructionsStep}>Step 1</span>
                <UserIcon width='64' fill='#FFF' className={css.instructionsIcon} />
                <Text tag='p' variant='larger' weight='500' offset='quarter-bottom' className={css.instructionsStepHeading}>
                  Create a { VIDEO_CREATOR } account
                </Text>
                <Text tag='p' variant='standardLarger'>
                  CEOs register your startup by creating a { VIDEO_CREATOR } account.
                </Text>
              </li>
              <li>
                <span className={css.instructionsStep}>Step 2</span>
                <ComputerIcon width='64' className={css.instructionsIcon} />
                <Text tag='p' variant='larger' weight='500' offset='quarter-bottom'
                      className={css.instructionsStepHeading}>
                  Record a pitch video
                </Text>
                <Text tag='p' variant='standardLarger'>
                  Log in to your Virtual Pitch Portal account and create your pitch video with easy-to-use tools.
                </Text>
              </li>
              <li>
                <span className={css.instructionsStep}>Step 3</span>
                <UploadIcon width='64' fill='#000' className={css.instructionsIcon} />
                <Text tag='p' variant='larger' weight='500' offset='quarter-bottom'
                      className={css.instructionsStepHeading}>
                  Add pitch materials
                </Text>
                <Text tag='p' variant='standardLarger'>
                  Supplement your pitch video by adding additional materials to showcase your business opportunity.
                </Text>
              </li>
              <li>
                <span className={css.instructionsStep}>Step 4</span>
                <CheckIcon width='64' fill='#003366' className={css.instructionsIcon} />
                <Text tag='p' variant='larger' weight='500' offset='quarter-bottom'
                      className={css.instructionsStepHeading}>
                  Submit pitch video
                </Text>
                <Text tag='p' variant='standardLarger'>
                  Submit your pitch video to our private video pitch feed so that {VIDEO_REVIEWER_PLURAL} can view your
                  pitch and make a connection with you on the platform.
                </Text>
              </li>
            </ul>
          </section>
        </div>

      </article>
    </Layout>
  )
}

export default LandingScreen
