import css from './SubmitToFeedModal.module.sass'
import React from 'react'

import Modal from '../Modal'
import Text from '../Text'
import PropTypes from "prop-types";
import { formatGraphQLError } from "../../helpers/errors"
import Alert from "../Alert"
import { PRODUCT_NAME } from "../../constants/enterpriseConfig"


const SubmitToFeedModal = (props) => {

  return (
    <Modal
      buttons={props.buttons}
      visible={props.visible}
      loading={props.loading}
    >
      <div className={css.confirmModalContainer}>

        <Text tag='p' variant='h3'>
          Ready to submit your {PRODUCT_NAME.toLowerCase()}?
        </Text>
        <Text tag='p' variant='standardLarger' className={css.confirmNote}>
          Note: Any future changes made to your {PRODUCT_NAME.toLowerCase()} will be updated in real-time.
        </Text>
      </div>
      <div className={css.errorAlert}>
        {props.errors &&
          <Alert variant='error'>{formatGraphQLError(props.errors)}</Alert>
        }
      </div>
    </Modal>
  )
}
SubmitToFeedModal.propTypes = {
  buttons: PropTypes.node,
  visible: PropTypes.bool,
  loading: PropTypes.bool,
  errors: PropTypes.object
}

export default SubmitToFeedModal
