import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Modal from './Modal'
import Button from './Button'
import List from './List'

const ConfirmationModal = ({ children, ..._modalProps }) => {
  const [ visible, setVisible ] = useState(false)
  const [ modalProps, setModalProps ] = useState(null)

  const showModal = (overrides) => {
    setVisible(true)
    setModalProps({ ..._modalProps, ...overrides })
  }

  const hideModal = () => {
    setVisible(false)
    setModalProps(null)
  }

  return (
    <>
      {visible &&
        <Modal
          loading={modalProps.loading}
          buttons={
            <List variant='horizontal' gap='20'>
              <List.Item>
                <Button
                  variant={modalProps.variant === 'delete' ? 'red' : 'primary'}
                  onClick={() => {
                    hideModal()
                    modalProps.onConfirm()
                  }}
                >
                  Yes
                </Button>
              </List.Item>

              <List.Item>
                <Button
                  variant='outline'
                  onClick={() => {
                    hideModal()
                    modalProps.onCancel && modalProps.onCancel()
                  }}
                >
                  No
                </Button>
              </List.Item>
            </List>
          }
        >
          {modalProps.content}
        </Modal>
      }

      {children({ showModal, hideModal })}
    </>
  )
}

ConfirmationModal.propTypes = {
  variant: PropTypes.string,
  content: PropTypes.node,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  children: PropTypes.func
}

export default ConfirmationModal
