import React from 'react'

import Layout from '../components/Layout'
import InvestorProfileFormLinked from '../components/InvestorProfileFormLinked'

const ProfileScreen = () =>
  <Layout variant='dashboard'>
    <InvestorProfileFormLinked />
  </Layout>

export default ProfileScreen
