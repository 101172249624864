import React, { useMemo } from 'react'

import Dropdown from './Dropdown'
import { TEAMSIZE } from '../../constants/otherInfo'

const TeamSizeDropdown = ({ ...dropdownProps }) => {
  const options = useMemo(() => {
    return TEAMSIZE.map(x => ({ value: x.value, label: x.label }))
  }, [])

  return (
    <Dropdown
      options={options}
      renderLabel={value => value.label}
      {...dropdownProps}
    />
  )
}

export default TeamSizeDropdown
