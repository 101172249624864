import css from './TeamList.module.sass'

import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'

import Member from './Member'
import ReorderableTable from '../../ReorderableTable'

const TeamList = forwardRef(({ members, onReorder }, ref) => {
  if (!members.length) {
    return null
  }

  return (
    <ReorderableTable
      className={css.table}
      ref={ref}
      items={members}
      renderItem={
        ({ item, dragged, onDragStart }) =>
          <Member
            key={item.id}
            member={item}
            dragging={dragged}
            onDragStart={onDragStart}
          />
      }
      onReorder={onReorder}
    />
  )
})

TeamList.displayName = 'TeamList'

TeamList.propTypes = {
  members: PropTypes.array,
  onReorder: PropTypes.func
}

export default TeamList
